import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import kebabCase from 'lodash/kebabCase';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { FiBookmark } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../../constants/utils';
import { imageOnError } from '../../../helpers';
import { BadgeDiscount, IconButton } from '../../../styles';
import { CUSTOM_THEME_COLORS, FONT_STYLE, SETTINGS, TRANSITION_DURATION } from '../../../variables';
import ImageZoomModal from '../../ImageZoomModal';
import Quantity from '../../Quantity/Quantity';
import AddShoppingListButton from '../../ShoppingLists/AddShoppingListButton';

const ProductVariantsTable = ({ variants, userIsLogged, setUpdatedProductQuantity }) => {
  const { t } = useTranslation();
  const { role, priceIsVisible } = useSelector((state) => state.user);
  const translations = useSelector((state) => state.translations);
  const [cookie] = useCookies();

  const theme = useTheme();
  const [shoppingListModal, setShoppingListModal] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [zoomImagePath, setZoomImagePath] = useState('');
  const variationFields = variants[0].variationFields.sort((a, b) => a.localeCompare(b));
  const translatedVariationFields = translations
    .filter((a) => variationFields.some((b) => a.id === b))
    .sort((a, b) => a.id.localeCompare(b.id));

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          size='small'
          id='product-variants'
        >
          <TableHead>
            <TableRow>
              <TableCell>{t('tables.image')}</TableCell>
              <TableCell>{t('tables.reference')}</TableCell>
              {/* variation fields begin */}
              {(!!translatedVariationFields.length
                ? translatedVariationFields
                : variationFields
              ).map((variationName, index) => (
                <TableCell key={`variationField-${index}`}>
                  {variationName[cookie.language] || variationName}
                </TableCell>
              ))}
              {/* variation fields end */}
              {userIsLogged && (
                <>
                  <TableCell>{t('tables.quantity')}</TableCell>
                  {role !== 'limitedcustomer' && (
                    <>
                      <TableCell>{t('tables.pvp')}</TableCell>
                      {priceIsVisible && <TableCell>{t('tables.rev')}*</TableCell>}
                    </>
                  )}
                  <TableCell />
                </>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {variants.map((product) => {
              const {
                reference,
                images,
                title,
                pvpPrice,
                price,
                onSale,
                saleAmount,
                salePrice,
                params,
              } = product;

              return (
                <TableRow
                  key={`product-variants-${reference}`}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 'none' },
                  }}
                >
                  <TableCell>
                    <Button
                      title={t('product.seeImage')}
                      onClick={() => {
                        setZoomImagePath(images[0]);
                        setModalIsOpen(true);
                      }}
                      sx={{
                        display: 'flex',
                        minWidth: 'initial',
                        padding: 0,
                        marginX: 'auto',
                        overflow: 'hidden',
                        borderRadius: theme.shape.borderRadius / 10,
                        border: `1px solid ${CUSTOM_THEME_COLORS.gray}`,
                        transition: `border-color ${TRANSITION_DURATION}ms ease`,
                        '&:hover': {
                          borderColor: CUSTOM_THEME_COLORS.primary,
                          cursor: 'zoom-in',
                        },
                      }}
                    >
                      <Box
                        component='img'
                        alt={title}
                        src={images[0]}
                        onError={(e) => {
                          imageOnError(e);
                        }}
                        sx={{
                          maxWidth: '48px',
                          aspectRatio: '1',
                          objectFit: 'contain',
                          border: `${theme.spacing(0.5)} solid white`,
                        }}
                      />
                    </Button>
                  </TableCell>
                  <TableCell>{reference}</TableCell>
                  {/* variation fields begin */}
                  {!!variationFields.length &&
                    variationFields.map((variationName, index) => {
                      const variationValue = params.find(
                        (param) => kebabCase(param.id) === kebabCase(variationName)
                      )?.value;
                      const translatedVariationValues = translations.filter(
                        (a) => variationValue === a.id
                      );
                      return (
                        <TableCell key={`variationFieldValue-${index}`}>
                          {!!translatedVariationValues.length
                            ? translatedVariationValues[0][cookie.language]
                            : '-'}
                        </TableCell>
                      );
                    })}
                  {/* variation fields end */}
                  {userIsLogged && (
                    <>
                      <TableCell>
                        <Quantity
                          product={product}
                          setUpdatedProductQuantity={setUpdatedProductQuantity}
                          variant
                        />
                      </TableCell>
                      {role !== 'limitedcustomer' && (
                        <>
                          <TableCell>
                            <Typography variant='small'>{formatCurrency(pvpPrice)}</Typography>
                          </TableCell>

                          {priceIsVisible && (
                            <TableCell>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  gap: 1,
                                }}
                              >
                                <Typography
                                  variant='small'
                                  sx={{
                                    textDecoration: onSale && 'line-through',
                                    fontWeight: FONT_STYLE.bold,
                                  }}
                                >
                                  {formatCurrency(price)}
                                </Typography>
                                {onSale && (
                                  <>
                                    <Typography
                                      variant='small'
                                      sx={{
                                        fontWeight: FONT_STYLE.bold,
                                        color: CUSTOM_THEME_COLORS.primary,
                                      }}
                                    >
                                      {formatCurrency(salePrice)}
                                    </Typography>
                                    <BadgeDiscount>{`${saleAmount}%`}</BadgeDiscount>
                                  </>
                                )}
                              </Box>
                            </TableCell>
                          )}
                        </>
                      )}
                      <TableCell>
                        {SETTINGS.functionalities.shoppingList && (
                          <AddShoppingListButton
                            productReference={reference}
                            modalIsOpen={shoppingListModal}
                            setModalIsOpen={setShoppingListModal}
                          >
                            <IconButton
                              startIcon={<FiBookmark size={18} />}
                              title={t('myAccount.shoppingLists.addToShoppingList')}
                              onClick={() => setShoppingListModal({ [reference]: true })}
                            />
                          </AddShoppingListButton>
                        )}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ImageZoomModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        imagePath={zoomImagePath}
      />
    </>
  );
};

export default React.memo(ProductVariantsTable);
