import i18n from 'i18next';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { formatCurrency } from '../constants/utils';
import { COMPANY_INFO, COMPANY_NAME } from '../variables';
import { splitString } from './splitString';
import { getOrderAndPayment } from '../api/payment';

// define a generatePDF function that accepts a products argument
const generatePDF = async (order, isLimitedCustomer) => {
  try {
    const {
      _id,
      reference,
      expeditionType,
      deliveryDate,
      date,
      items,
      billingAddress,
      shippingPrice,
      taxes,
      discounts,
      subtotal,
      total,
    } = order;

    // Fetch order and payment data
    const response = await getOrderAndPayment(_id);

    if (!response) {
      console.error(`Order or payment not found for orderId: ${_id}`);
      return;
    }
    const payment = await response.data.payment;
    const isMultibanco = payment.paymentType === 'multibanco';

    const pageBeginning = 15;
    const pageEnding = 195;
    const pageMiddle = 115;

    let billingAddressStreetIsSplitted = false;
    let billingAddressStreet = billingAddress?.street;
    const billingAddressStreetLength = billingAddressStreet.length;

    if (billingAddressStreetLength >= 40) {
      billingAddressStreetIsSplitted = true;
      billingAddressStreet = splitString(billingAddressStreet, 40);
    }

    let companyStreetIsSplitted = false;
    let companyStreet = COMPANY_INFO.address.street;
    const companyAddressStreetLength = companyStreet.length;

    if (companyAddressStreetLength >= 50) {
      companyStreetIsSplitted = true;
      companyStreet = splitString(companyStreet, 50);
    }

    const calculateSavedPrice = (price, discount) => Math.fround(price * (discount / 100));

    // initialize jsPDF
    const doc = new jsPDF();

    // define the columns we want and their titles
    let tableColumn = [];

    !isLimitedCustomer
      ? (tableColumn = [
          i18n.t('tables.reference'),
          i18n.t('tables.designation'),
          i18n.t('tables.quantityShort'),
          i18n.t('tables.priceUnit'),
          i18n.t('tables.discount'),
          i18n.t('tables.subtotal'),
        ])
      : (tableColumn = [
          i18n.t('tables.reference'),
          i18n.t('tables.designation'),
          i18n.t('tables.quantityShort'),
        ]);

    // define an empty array of rows
    const tableRows = [];

    // for each product pass all its data into an array
    items.map(({ reference, title, quantity: quantities, original_customer_price, discount }) => {
      let productData = [];
      const hasDiscount = discount !== 0;
      const quantity = quantities?.unit + quantities?.box;
      const discountCalc = hasDiscount && calculateSavedPrice(original_customer_price, discount);
      const discountValue = hasDiscount
        ? `${formatCurrency(discountCalc)} (${discount}%)`
        : formatCurrency(discount);
      const productSubtotal = hasDiscount
        ? Math.fround(original_customer_price - discountCalc) * quantity
        : original_customer_price * quantity;

      !isLimitedCustomer
        ? (productData = [
            reference,
            title,
            quantity,
            formatCurrency(original_customer_price),
            discountValue,
            formatCurrency(productSubtotal),
          ])
        : (productData = [reference, title, quantity]);

      return tableRows.push(productData);
    });

    const orderDate = new Date(date).toLocaleDateString('pt-PT');
    const orderShippingDate = new Date(deliveryDate).toLocaleDateString('pt-PT');

    // GLOBAL STYLES
    doc.setFontSize(10);
    doc.setFont(undefined, 'bold');
    doc.text(COMPANY_NAME, pageBeginning, 15);
    doc.setFont(undefined, 'normal');
    doc.text(companyStreet, pageBeginning, 21);
    doc.text(`${COMPANY_INFO.address.zipCode}`, pageBeginning, companyStreetIsSplitted ? 31 : 27);
    doc.text(`NIF: ${COMPANY_INFO.nif}`, pageBeginning, companyStreetIsSplitted ? 37 : 33);

    doc.setFont(undefined, 'bold');
    doc.text(billingAddress.name, pageMiddle, 22);
    doc.setFont(undefined, 'normal');
    doc.text(`${billingAddressStreet}`, pageMiddle, 28);
    doc.text(
      `${billingAddress.zip_code} ${billingAddress.city}`,
      pageMiddle,
      billingAddressStreetIsSplitted ? 38 : 34
    );
    doc.text(billingAddress.country, pageMiddle, billingAddressStreetIsSplitted ? 44 : 40);

    doc.setFont(undefined, 'bold');
    doc.text(`${i18n.t('tables.orderNumber')}: ${reference}`, pageBeginning, 56);
    doc.setFont(undefined, 'normal');
    doc.line(pageBeginning, 58, pageEnding, 58);
    doc.setFontSize(9);
    doc.text(`${i18n.t('tables.date')}: ${orderDate}`, pageBeginning, 63);
    doc.text(`${i18n.t('tables.deliveryDate')}: ${orderShippingDate}`, 65, 63);
    doc.text(`${i18n.t('tables.shipping')}: ${expeditionType}`, 125, 63);
    doc.text(
      `${i18n.t('tables.paymentType')}: ${i18n.t(`checkout.${payment.paymentType}`)}`,
      pageBeginning,
      69
    );
    doc.setFontSize(10);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      theme: 'plain',
      startY: 74,
      styles: { fontSize: 8 },
      headStyles: { fillColor: '#f5f5f8' },
    });

    if (isMultibanco) {
      const paymentDataBlockBeginning = doc.lastAutoTable.finalY + 54;
      doc.setFont(undefined, 'bold');
      doc.text(i18n.t('tables.mbdetails'), pageMiddle, paymentDataBlockBeginning);
      doc.setFont(undefined, 'normal');
      doc.text(
        `${i18n.t('tables.mbentity')}: ${payment.entity}`,
        pageMiddle,
        paymentDataBlockBeginning + 8
      );
      doc.text(
        `${i18n.t('tables.mbreference')}: ${payment.reference}`,
        pageMiddle,
        paymentDataBlockBeginning + 14
      );
      doc.text(
        `${i18n.t('tables.mbamount')}: ${formatCurrency(Number(payment.amount))}`,
        pageMiddle,
        paymentDataBlockBeginning + 20
      );
    }

    !isLimitedCustomer &&
      doc.autoTable({
        body: !!taxes.length
          ? [
              [i18n.t('tables.discounts'), !!discounts.length !== 0 ? '' : formatCurrency(0)],
              ...discounts.map(({ title, percentage, value }) => [
                {
                  content: `${i18n.t(title)} ${!!Number(percentage) ? `(${percentage}%)` : ''}`,
                  styles: {
                    cellPadding: { top: 2, right: 0, bottom: 2, left: 8 },
                  },
                },
                { content: formatCurrency(Number(value)) },
              ]),
              [i18n.t('tables.subtotal'), formatCurrency(subtotal)],
              [i18n.t('tables.shipping'), formatCurrency(shippingPrice)],
              ...taxes.map((tax) => [
                `${i18n.t('tables.vat')} (${tax.iva}%)`,
                formatCurrency(tax.total),
              ]),
              [
                { content: i18n.t('tables.total'), styles: { fontStyle: 'bold' } },
                { content: formatCurrency(total), styles: { fontStyle: 'bold' } },
              ],
            ]
          : [
              [i18n.t('tables.subtotal'), formatCurrency(subtotal)],
              [i18n.t('tables.shipping'), formatCurrency(shippingPrice)],
              [
                { content: i18n.t('tables.total'), styles: { fontStyle: 'bold' } },
                { content: formatCurrency(total), styles: { fontStyle: 'bold' } },
              ],
            ],
        theme: 'plain',
        styles: { fillColor: '#f5f5f8' },
        margin: { left: pageMiddle },
        startY: doc.lastAutoTable.finalY + 90,
        tableWidth: pageEnding - pageMiddle,
        cellPadding: { top: 2, right: 4, bottom: 2, left: 4 },
      });

    doc.text(i18n.t('app.documentIsNotAnInvoice'), pageBeginning, 285);

    // Define the name of our PDF file
    doc.save(`${i18n.t('tables.order')}_${date}_${Math.floor(Date.now() / 1000)}.pdf`);
    // doc.output('dataurlnewwindow'); //to check pdf generate
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};

export default generatePDF;
