import { LoadingButton } from '@mui/lab';
import { Box, TextField } from '@mui/material';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setLanguage } from '../../api/configs';
import { login } from '../../api/login';
import { getCart, getShoppingLists, setCustomer } from '../../api/user';
import { setCartState } from '../../state/cartSlice';
import { setShoppingLists } from '../../state/shoppingListsSlice';
import { setSelectedClient, setUser } from '../../state/userSlice';
import PopupNotification from '../PopupNotification/PopupNotification';

const LoginForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
  });
  const [userData, setUserData] = useState({
    email: '',
    password: '',
  });

  const [cookie, setCookie] = useCookies();

  function handleChange(e) {
    const { name, value } = e.target;

    setUserData((values) => ({
      ...values,
      [name]: value,
    }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    if (!userData.email || !userData.password) {
      setIsLoading(false);

      return;
    }

    login(userData)
      .then(({ token, user, expiresIn }) => {
        const expiresInSeconds = parseInt(expiresIn.charAt(0)) * 24 * 60 * 60;
        let firstCustomerAvailable = null;
        const isCustomer = user.user_role === 'customer';
        const isSeller = user.user_role === 'seller';

        //  get user token
        setCookie('userToken', token, {
          path: '/',
          maxAge: expiresInSeconds,
          secure: true,
          sameSite: true,
        });
        dispatch(
          setUser({
            id: user.id,
            isAdmin: user.isAdmin,
            role: user.user_role,
            companyInfo: user.companyInfo,
            personalInfo: user.personalInfo,
            sellerInfo: {
              name: isCustomer ? user.sellerName : user.personalInfo.name,
              email: isCustomer ? user.sellerEmail : user.personalInfo.email,
              phone: isCustomer ? user.sellerPhone : user.personalInfo.phone,
              paymentMethods: user.payment_methods,
              paymentTime: user.payment_deadlines,
              clients: isSeller ? user.customers : [],
            },
            addresses: user.addresses,
            customerDiscount: user?.customerDiscount,
            cumulativeDiscounts: user?.cumulativeDiscounts,
          })
        );

        if (isSeller) {
          firstCustomerAvailable = user?.customers?.[0];
          if (!!firstCustomerAvailable) {
            dispatch(setSelectedClient(firstCustomerAvailable?.id));
            //  set first customer as an active » BE control
            setCustomer({ customerId: firstCustomerAvailable?.id });
          }
        }

        //  set user language for BE usage
        setLanguage(cookie.language).catch(({ response: { status } }) => {
          if (status === 401) navigate('/login');
        });

        //  get cart items
        getCart(isSeller ? firstCustomerAvailable?.cart_id : user?.cart_id)
          .then(
            ({
              _id,
              cart_items,
              taxes,
              discounts,
              total_discount,
              subtotal,
              total,
            }) => {
              dispatch(
                setCartState({
                  id: _id,
                  cart: cart_items,
                  discount: total_discount,
                  discounts: discounts,
                  taxes: taxes,
                  subtotal: subtotal,
                  total: total,
                })
              );

              //  get shipping lists
              return getShoppingLists()
                .then((lists) => {
                  dispatch(setShoppingLists(lists));

                  if (isLoading) setIsLoading(false);
                })
                .catch(({ response: { status } }) => {
                  if (status === 401) navigate('/login');
                });
            }
          )
          .catch(({ response: { status } }) => {
            if (status === 401) navigate('/login');
          });

        return navigate('/');
      })
      .catch(({ response: { status, data } }) => {
        const { msg } = data;
        if (status >= 400) {
          setNotificationProps((prevState) => ({
            ...prevState,
            isOpened: true,
            type: 'error',
            errorMessage: t(msg),
          }));
        }
        setIsLoading(false);
      });
  }

  return (
    <>
      <Box
        component='form'
        onSubmit={handleSubmit}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TextField
          fullWidth
          label={t('forms.email')}
          type='email'
          name='email'
          required
          value={userData.email}
          onChange={handleChange}
        />

        <TextField
          fullWidth
          label={t('forms.password')}
          type='password'
          name='password'
          required
          value={userData.password}
          onChange={handleChange}
        />

        <LoadingButton
          variant='contained'
          type='submit'
          title={t('login.loginButton')}
          sx={{
            marginTop: 3,
          }}
          loading={isLoading}
        >
          {t('login.loginButton')}
        </LoadingButton>
      </Box>

      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </>
  );
};

export default LoginForm;
