import { Box, Card, CardContent, CardMedia, Typography, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import { TbShoppingCartPlus } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../constants/utils';
import { ProductCardContext } from '../contexts/ProductCardContext';
import { imageOnError } from '../helpers';
import { productDetailsInterface } from '../helpers/productDetailsInterface';
import { BadgeDiscount, IconButton } from '../styles';
import {
  CUSTOM_THEME_COLORS,
  FONT_STYLE,
  PAGES_SLUGS,
  PLACEHOLDER_IMAGE,
  SETTINGS,
  TRANSITION_DURATION,
} from '../variables';
import ProductBadge from './ProductBadge';
import ProductStock from './ProductStock';
import { useTranslation } from 'react-i18next';

const ProductCard = ({ product, userIsLogged, showRevPrice, showStockAvailability = true }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { addToCart, setProductVariants, setIsVariationsModalOpen } =
    useContext(ProductCardContext);

  const { slug, title, brand, images, badge, variants, reference, saleAmount } = product;

  const productDetails = productDetailsInterface(product);

  return (
    <Card
      className='product-card'
      sx={{
        minWidth: '130px',
        maxWidth: '300px',
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingY: 2,
        '&:hover': {
          '.productCardTitle': {
            color: CUSTOM_THEME_COLORS.primary,
          },
          '.productCardActions': {
            opacity: 1,
          },
        },
      }}
      component={Link}
      to={`${PAGES_SLUGS.productDetail}/${slug}`}
      title={title}
    >
      <Box
        className='productCardImage'
        sx={{
          position: 'relative',
          transition: `transform ${TRANSITION_DURATION}ms ease`,
        }}
      >
        {badge && <ProductBadge badge={badge} />}
        <CardMedia
          component='img'
          image={images.length !== 0 ? images?.[0] : PLACEHOLDER_IMAGE}
          onError={imageOnError}
          alt={title}
          sx={{
            maxWidth: '100%',
            objectFit: 'contain',
            aspectRatio: '1',
            borderRadius: theme.shape.borderRadius / 10,
          }}
        />
        {!!SETTINGS.functionalities.variantsModal && (
          <Box
            className='productCardActions'
            sx={{
              width: '100%',
              position: 'absolute',
              left: '0',
              bottom: theme.spacing(2),
              display: 'flex',
              justifyContent: 'center',
              opacity: 0,
              transition: `opacity ${TRANSITION_DURATION}ms ease`,
            }}
          >
            <IconButton
              title={t('cart.addToCart')}
              variant='contained'
              sx={{
                minWidth: 'initial',
              }}
              onClick={(e) => {
                e.preventDefault();
                return productDetails.hasVariants
                  ? (setProductVariants(variants), setIsVariationsModalOpen(true))
                  : addToCart(product);
              }}
            >
              <TbShoppingCartPlus size={18} />
            </IconButton>
          </Box>
        )}
      </Box>

      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          padding: `${theme.spacing(1.5)} 0 0 0 !important`,
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: '.75rem',
              marginBottom: 1,
            }}
          >
            {brand}
          </Typography>

          <Typography
            className='productCardTitle'
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              transition: `color ${TRANSITION_DURATION}ms ease`,
              fontWeight: FONT_STYLE.bold,
            }}
          >
            {title}
          </Typography>

          {reference && (
            <Typography
              sx={{
                marginTop: 0.5,
                fontSize: '0.85rem',
                color: CUSTOM_THEME_COLORS.darkGray,
              }}
            >{`Ref: ${reference}`}</Typography>
          )}
        </Box>

        <Box
          sx={{
            paddingTop: 1.5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 1.5,
          }}
        >
          {showStockAvailability && <ProductStock stock={productDetails.stock} />}

          {userIsLogged && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5,
              }}
            >
              {productDetails.hasVariants && (
                <Typography
                  variant='xsmall'
                  sx={{
                    fontWeight: FONT_STYLE.medium,
                    marginBottom: 0.5,
                  }}
                >
                  {t('product.pricesFrom')}
                </Typography>
              )}

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Typography
                  variant='xsmall'
                  fontWeight={FONT_STYLE.light}
                >
                  {t('product.pvp')}
                </Typography>

                <Typography
                  variant='xsmall'
                  fontWeight={FONT_STYLE.light}
                >
                  {formatCurrency(productDetails.pvpPrice)}
                </Typography>
              </Box>

              {showRevPrice && (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Typography
                      variant='xsmall'
                      fontWeight={FONT_STYLE.light}
                    >
                      {t('product.rev')}*
                    </Typography>

                    <Typography
                      sx={{
                        textDecoration: productDetails.onSale && 'line-through',
                        fontWeight: FONT_STYLE.bold,
                      }}
                    >
                      {formatCurrency(productDetails.price)}
                    </Typography>

                    {productDetails.onSale && (
                      <>
                        <Typography
                          sx={{
                            fontWeight: FONT_STYLE.bold,
                            color: CUSTOM_THEME_COLORS.primary,
                          }}
                        >
                          {formatCurrency(productDetails.salePrice)}
                        </Typography>
                        <BadgeDiscount>{`${saleAmount}%`}</BadgeDiscount>
                      </>
                    )}
                  </Box>
                  <Typography
                    sx={{
                      fontSize: '0.8rem',
                      fontWeight: FONT_STYLE.light,
                      width: '100%',
                    }}
                  >
                    {t('product.priceWithoutVat')}
                  </Typography>
                </>
              )}
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
