import {
  Accordion,
  Box,
  Button,
  outlinedInputClasses,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import {
  CUSTOM_THEME_COLORS,
  FONT_STYLE,
  TRANSITION_DURATION,
} from './variables';

export let theme = createTheme();
theme = createTheme({
  shadows: ['none', ...Array(25).fill('none')],

  shape: {
    borderRadius: 0,
  },

  palette: {
    primary: {
      main: '#000',
      dark: CUSTOM_THEME_COLORS.primary,
    },
    secondary: {
      main: '#fff',
      dark: '#fff',
    },
    text: {
      primary: '#000',
      // disabled: '#ff0000',
    },
    error: {
      main: CUSTOM_THEME_COLORS.red,
    },
    danger: {
      main: CUSTOM_THEME_COLORS.red,
    },
    action: {
      // disabled: '#ff0000',
      // activatedOpacity: 1,
      // active: string;
      // hover: string;
      // hoverOpacity: number;
      // selected: string;
      // selectedOpacity: number;
      // disabled: string;
      // disabledOpacity: number;
      // disabledBackground: string;
      // focus: string;
      // focusOpacity: number;
      // activatedOpacity: number;
    },
  },

  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    h1: {
      fontSize: '1.6rem',
      fontWeight: FONT_STYLE.black,
      textTransform: 'uppercase',
    },
    h2: {
      fontSize: '1.3rem',
      fontWeight: FONT_STYLE.black,
      textTransform: 'uppercase',
    },
    h3: {
      fontSize: '1.2rem',
      fontWeight: FONT_STYLE.bold,
    },
    h4: {
      fontSize: '1rem',
      fontWeight: FONT_STYLE.bold,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: FONT_STYLE.medium,
    },
    overline: {
      lineHeight: 1,
      textTransform: 'none',
    },
    small: {
      fontSize: '0.9rem',
      lineHeight: 1.25,
    },
    xsmall: {
      fontSize: '0.85rem',
      lineHeight: 1.25,
    },
    link: {
      color: 'black',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: ({ breakpoints }) => ({
        html: {
          fontSize: '14px',
          [breakpoints.down(breakpoints.values.md)]: {
            fontSize: '13px',
          },
        },
      }),
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
          },
        },
      },
    },
    MuiGrid2: {
      defaultProps: {
        spacing: { xs: 1, md: 2 },
      },
    },
    MuiTypography: {
      styleOverrides: {
        caption: {
          color: theme.palette.text.primary,
        },
      },
      defaultProps: {
        variantMapping: {
          small: 'p',
          xsmall: 'p',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.85rem',
          fontWeight: FONT_STYLE.regular,
          textTransform: 'none',
          padding: `${theme.spacing(1.25)} ${theme.spacing(2.5)}`,
        },
        containedPrimary: {
          backgroundColor: CUSTOM_THEME_COLORS.primary,
          '&:hover': {
            backgroundColor: CUSTOM_THEME_COLORS.lightPrimary,
          },
        },
        containedSecondary: {
          border: `1px solid ${CUSTOM_THEME_COLORS.primary}`,
          color: CUSTOM_THEME_COLORS.primary,
          '&:hover': {
            backgroundColor: CUSTOM_THEME_COLORS.primary,
            color: 'white',
          },
        },
        sizeSmall: {
          padding: `${theme.spacing(0.75)} ${theme.spacing(1.5)}`,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: CUSTOM_THEME_COLORS.primary,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        standard: {
          backgroundColor: CUSTOM_THEME_COLORS.primary,
          color: '#fff',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: CUSTOM_THEME_COLORS.darkGray,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginTop: theme.spacing(3),
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: CUSTOM_THEME_COLORS.lightPrimary,
          '&.Mui-checked': {
            color: CUSTOM_THEME_COLORS.primary,
          },
        },
        track: {
          backgroundColor: CUSTOM_THEME_COLORS.primary,
          '.Mui-checked.Mui-checked + &': {
            backgroundColor: CUSTOM_THEME_COLORS.primary,
          },
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            '.MuiStepLabel-label, .MuiStepIcon-root': {
              color: CUSTOM_THEME_COLORS.primary,
            },
          },
          '.Mui-active': {
            '&.MuiStepLabel-label, &.MuiStepIcon-root': {
              fontWeight: FONT_STYLE.bold,
              color: CUSTOM_THEME_COLORS.primary,
            },
          },
          '.MuiStepLabel-root': {
            '.MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
              marginTop: theme.spacing(1.5),
            },
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '.MuiTableCell-head': {
            fontWeight: FONT_STYLE.medium,
            backgroundColor: CUSTOM_THEME_COLORS.gray,
            textAlign: 'center',
            whiteSpace: 'nowrap',
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
          },
          '.MuiTableCell-body': {
            textAlign: 'center',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: `1px solid ${CUSTOM_THEME_COLORS.gray}`,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: 0,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '.MuiFormLabel-root, .MuiOutlinedInput-root': {
            fontSize: '0.95rem',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
              display: 'none',
            },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          // display: 'flex',
          // flexDirection: 'column',
          // flexWrap: 'nowrap',
        },
        option: {
          whiteSpace: 'initial',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: 0,
          borderRadius: '0 !important',
          borderBottom: '1px solid black',
          backgroundColor: 'transparent',
          '&::before': {
            display: 'none',
          },
          '&.Mui-expanded': {
            margin: 0,
          },
          '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: '48px',
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          // display: 'none',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1300,
      xl: 1536,
    },
  },
});

export const MainContainer = styled(Box, { label: 'MainContainer' })(
  ({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    // overflow: 'hidden',
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  })
);

export const BadgeDiscount = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  backgroundColor: CUSTOM_THEME_COLORS.primary,
  borderRadius: theme.shape.borderRadius,
  padding: `${theme.spacing(0.2)} ${theme.spacing(1)}`,
  fontSize: '0.85rem',
  lineHeight: 1.5,
  color: 'white',
}));

export const BadgeDiscountOutlined = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  border: `1px solid ${CUSTOM_THEME_COLORS.primary}`,
  borderRadius: theme.shape.borderRadius,
  padding: `${theme.spacing(0.2)} ${theme.spacing(1.25)}`,
  fontSize: '0.9rem',
  color: CUSTOM_THEME_COLORS.primary,
}));

export const StyledTextButton = styled(Button)(() => ({
  textTransform: 'none',
}));

export const IconButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  minWidth: 'initial',
  '& .MuiButton-startIcon': {
    margin: 0,
  },
}));

export const FooterLink = styled(Typography)(() => ({
  display: 'block',
}));

export const StyledTableSelect = styled(Select)(() => ({
  fontSize: '0.9rem',
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: 0,
  },
}));

//  PRODUCT LIST
export const ProductsFilters = styled(Accordion)(({ theme }) => ({
  [`& .MuiAccordionSummary-root.Mui-expanded`]: {
    minHeight: '48px',
  },
  [`& .MuiAccordionSummary-content.Mui-expanded`]: {
    margin: 0,
  },
  [`& .MuiAccordionDetails-root`]: {
    paddingTop: theme.spacing(2),
    [`.MuiFormGroup-root`]: {
      gap: theme.spacing(0.5),
    },
  },
}));

//  MAIN MENU
export const CategoryButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  fontSize: '0.9rem',
  color: 'white',
  fontWeight: FONT_STYLE.medium,
  textTransform: 'uppercase',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  '&.active::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: '3px',
    backgroundColor: 'white',
  },
  [theme.breakpoints.down(theme.breakpoints.values.md)]: {
    fontSize: '0.85rem',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
}));

export const CategoriesButton = styled(Box)(({ active, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2.5),
  fontSize: '0.9rem',
  color: 'black',
  fontWeight: FONT_STYLE.bold,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  padding: `${theme.spacing(1.25)} ${theme.spacing(2.5)}`,
  backgroundColor: active === 1 ? CUSTOM_THEME_COLORS.gray : 'white',
  transition: `backgroundColor ${TRANSITION_DURATION}ms ease`,
  [`&:hover`]: {
    backgroundColor: CUSTOM_THEME_COLORS.gray,
  },
}));

export const SubCategoriesButton = styled(Box)(() => ({
  display: 'flex',
  textDecoration: 'none',
  color: 'black',
  fontSize: '0.85rem',
  [`&:hover`]: {
    color: CUSTOM_THEME_COLORS.primary,
  },
}));

export const LocaleSelector = styled(TextField)(({ theme }) => ({
  marginTop: 0,
  textTransform: 'uppercase',
  '& .MuiSelect-select': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: CUSTOM_THEME_COLORS.gray,
  },
}));

//  MY ACCOUNT
export const MyAccountTitle = styled(Typography)(({ theme }) => ({
  fontWeight: FONT_STYLE.bold,
  marginBottom: theme.spacing(1),
}));

export const MyAccountSubtitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
}));
