import { Box, Container, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getBrands } from '../api';
import { getProducts } from '../api/products';
import ProductsCarousel from '../components/Carousel/ProductsCarousel';
import Loading from '../components/Loading';
import { imageOnError, slugify } from '../helpers';
import { MainContainer } from '../styles';
import { useTranslation } from 'react-i18next';
import { NOT_FOUND_ROUTE } from '../variables';

const BrandPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { brandSlug } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [brand, setBrand] = useState([]);
  const [brandProducts, setBrandProducts] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    getBrands()
      .then((brands) => {
        const filteredBrand = brands.find(
          ({ name }) => slugify(name) === brandSlug
        );
        setBrand(filteredBrand);

        if (!filteredBrand) return;

        const productFilters = {
          brands: [slugify(filteredBrand.name)],
          limit: 20,
        };

        return getProducts({
          searchParams: productFilters,
        })
          .then(({ displayedProducts }) => {
            setBrandProducts(displayedProducts);
            setIsLoading(false);
          })
          .catch(({ response: { status } }) => {
            if (status === 401) navigate('/login');
            if (status === 400 || status > 401) navigate(`/${NOT_FOUND_ROUTE}`, { replace: true });
          });
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  }, [brandSlug, navigate]);

  if (isLoading) {
    return <Loading />;
  }

  const { name, description, image } = brand;

  return (
    <MainContainer>
      <Container>
        <Grid container>
          {image && !!description && (
            <Grid
              container
              marginTop={{ md: 4 }}
              xs={12}
            >
              <Grid
                xs={12}
                md={!!description ? 4 : 12}
                sx={{ textAlign: !!description ? 'left' : 'center' }}
              >
                {image ? (
                  <Box
                    component='img'
                    src={image}
                    onError={imageOnError}
                    sx={{ maxWidth: { xs: '175px', md: '225px' } }}
                    alt={name}
                  />
                ) : (
                  <Typography variant='h1'>{name}</Typography>
                )}
              </Grid>
              {!!description && (
                <Grid
                  xs={12}
                  md={7}
                  mdOffset={1}
                  marginTop={{ xs: 3, md: 0 }}
                >
                  <Typography
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </Grid>
              )}
            </Grid>
          )}
          {brandProducts && (
            <>
              {image && !!description && (
                <Divider sx={{ width: '100%', marginY: { xs: 4, md: 6 } }} />
              )}
              <Grid xs={12}>
                <Typography
                  variant='h2'
                  sx={{
                    fontSize: '1.75em',
                  }}
                  marginBottom={2}
                >
                  {t('brandPage.productsCarouselTitle')}
                </Typography>
              </Grid>
              <Grid xs={12}>
                <ProductsCarousel products={brandProducts} />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default BrandPage;
