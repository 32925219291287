import { Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getProduct } from '../api/products';
import { addProductToUserHistory } from '../api/user';
import Loading from '../components/Loading';
import ProductDetails from '../components/ProductPage/ProductDetails/ProductDetails';
import ProductImages from '../components/ProductPage/ProductImages';
import ProductRelated from '../components/ProductPage/ProductRelated';
import ProductTabs from '../components/ProductPage/ProductTabs/ProductTabs';
import { MainContainer } from '../styles';
import { CUSTOM_THEME_COLORS, FONT_STYLE, NOT_FOUND_ROUTE } from '../variables';

const ProductPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productSlug } = useParams();
  const { t } = useTranslation();

  const theme = useTheme();
  const MOBILE_DEVICES = useMediaQuery(theme.breakpoints.down('md'));

  const user = useSelector((state) => state.user);
  const { role, selectedClient, id } = user;
  const customerId = selectedClient?.id || id;

  const [cookies] = useCookies(['userToken']);
  const userIsLogged = !!cookies.userToken;
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      getProduct(productSlug)
        .then((newProduct) => {
          setProduct(newProduct);

          if (id && role !== 'seller') {
            addProductToUserHistory(newProduct.reference, customerId).catch(
              ({ response: { status } }) => {
                if (status === 401) navigate('/login');
              }
            );
          }

          setIsLoading(false);
        })
        .catch(({ response: { status } }) => {
          if (status === 401) navigate('/login');
          if (status === 400 || status > 401) navigate(`/${NOT_FOUND_ROUTE}`, { replace: true });
        });
    }, 250);

    return () => {
      setProduct(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, productSlug, selectedClient?.id]);

  if (isLoading) {
    return <Loading />;
  }

  if (!product) {
    return null;
  }

  const images = product?.images;
  const { related, params, datasheet, video } = product;

  const availableParams = params.filter((param) => param.value && param.value !== 'undefined');

  return (
    <MainContainer>
      <Container>
        <Grid container>
          <Grid xs={12}>
            <Box marginBottom={1}>
              <Typography
                component={Link}
                to={-1}
                title={t('checkout.goBackButton')}
                variant='link'
                sx={{
                  fontSize: '0.9rem',
                  fontWeight: FONT_STYLE.medium,
                  color: CUSTOM_THEME_COLORS.primary,
                }}
              >{`< ${t('checkout.goBackButton')}`}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: MOBILE_DEVICES && 'column',
              }}
              gap={{ xs: 2, md: 6 }}
            >
              <Box
                sx={{
                  maxWidth: '100%',
                  margin: { xs: '0 auto', md: '0' },
                }}
              >
                <ProductImages images={images} />
              </Box>

              <ProductDetails
                product={product}
                userIsLogged={userIsLogged}
                customerId={customerId}
              />
            </Box>

            <ProductTabs
              video={video}
              params={availableParams}
              datasheet={datasheet}
            />

            {related && <ProductRelated related={related} />}
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default ProductPage;
