import { LoadingButton } from '@mui/lab';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { recoverPassword } from '../../api/login';
import { FONT_STYLE } from '../../variables';
import PopupNotification from '../PopupNotification/PopupNotification';

const PasswordRecovery = () => {
  const { t } = useTranslation();
  const [cookie] = useCookies();

  const [isLoading, setIsLoading] = useState(false);
  const [recoveryEmail, setRecoveryEmail] = useState('');
  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    successMessage: t('passwordRecovery.successMessage'),
  });

  function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    if (!recoveryEmail) return;

    recoverPassword(recoveryEmail, cookie.language)
      .then(() => {
        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
        }));
        setIsLoading(false);
      })
      .catch(
        ({
          response: {
            data: { msg },
          },
        }) => {
          setNotificationProps((prevState) => ({
            ...prevState,
            isOpened: true,
            type: 'error',
            errorMessage: t(msg.message),
          }));
          setIsLoading(false);
        }
      );
  }

  return (
    <>
      <Box
        component='form'
        onSubmit={handleSubmit}
        sx={{
          width: { xs: '80vw', md: '30vw' },
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            marginBottom: 1,
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              fontWeight: FONT_STYLE.bold,
              marginBottom: 1,
            }}
          >
            {t('passwordRecovery.title')}
          </Typography>
          <Typography
            variant='small'
            sx={{ textAlign: 'center' }}
          >
            {t('passwordRecovery.description')}
          </Typography>
        </Box>

        <TextField
          fullWidth
          label={t('forms.email')}
          type='email'
          required
          value={recoveryEmail}
          onChange={(e) => setRecoveryEmail(e.target.value)}
        />

        <Box
          sx={{
            display: 'inline-flex',
            justifyContent: { sm: 'flex-end' },
            alignItems: 'flex-end',
            flexWrap: 'wrap',
            gap: 2,
          }}
        >
          <Button
            component={Link}
            to='/login'
            variant='contained'
            color='secondary'
            title={t('checkout.goBackButton')}
          >
            {t('checkout.goBackButton')}
          </Button>

          <LoadingButton
            variant='contained'
            type='submit'
            title={t('passwordRecovery.submitButton')}
            sx={{
              marginTop: 3,
            }}
            loading={isLoading}
          >
            {t('passwordRecovery.submitButton')}
          </LoadingButton>
        </Box>
      </Box>

      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </>
  );
};

export default PasswordRecovery;
