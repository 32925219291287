import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import loginImage from '../assets/login-image.jpg';
import CompanyLogo from '../components/CompanyLogo';
import { COMPANY_NAME } from '../variables';

const LoginPage = () => {
  return (
    <Box
      sx={{
        display: 'grid',
        alignItems: 'center',
        gridAutoFlow: 'column',
        gridAutoColumns: '1fr',
      }}
    >
      <Box
        sx={{
          height: '100vh',
          overflow: 'hidden',
          display: { xs: 'none', md: 'flex' },
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          component='img'
          src={loginImage}
          alt={COMPANY_NAME}
          sx={{
            width: '100%',
            aspectRatio: '3/4',
          }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%',
          height: '100vh',
          overflowY: 'auto',
          paddingTop: 7,
          paddingBottom: 7,
          '@media (min-width:900px) and (min-height:800px)': {
            justifyContent: 'center',
          },
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <CompanyLogo width={{ xs: '90px' }} />
        </Box>
        <Outlet />
      </Box>
    </Box>
  );
};

export default LoginPage;
