import capitalize from 'lodash/capitalize';
import { PLACEHOLDER_IMAGE } from '../variables';
// import { generateRandomPrice } from '../helpers';

function mapProduct(product) {
  // const randomPrice = generateRandomPrice();

  //  update interface of 'related'
  const hasRelated = Boolean(
    product?.related !== undefined && product?.related.length !== 0
  );

  //  update interface of 'variants'
  const hasVariants = Boolean(
    product?.variants && product?.variants.length !== 0
  );

  return {
    ...product,
    id: product?._id || product?.id,
    title: capitalize(product?.title),
    slug: product?.slug,
    brand: product?.brand,
    description: product?.description,
    reference: product?.reference,
    images: product?.images?.length ? product?.images : [PLACEHOLDER_IMAGE],
    discount: product?.discount,
    discountPrice: product?.discountPrice,
    saleAmount: product?.saleAmount || 0,
    //  unit
    sellsUnit: true,
    onSale: product?.onSale || false,
    pvpPrice: product?.pvpPrice || 0,
    price: product?.price || 0,
    salePrice: product?.salePrice || 0,
    stock: product?.stock || 0,
    //  box
    sellsBox: false,
    itemsPerBox: product?.itemsPerBox || 0,
    onSaleBox: product?.onSaleBox || false,
    pvpBoxPrice: product?.pvpBoxPrice || 0,
    boxPrice: product?.boxPrice || 0,
    boxSalePrice: product?.boxSalePrice || 0,
    stockPerBox: product?.stockPerBox || 0,
    quantity: {
      unit: product?.quantity?.unit || 0,
      box: product?.quantity?.box || 0,
    },
    video: product?.video,
    datasheet: product?.datasheet,
    params: product?.params,
    date: product?.erp_update_date || product?.date,
    badge: {
      new: product?.badge?.new || false,
      campaign: product?.badge?.campaign || false,
    },
    isVariant: product?.isVariant || false,
    related: hasRelated
      ? product?.related.map((related) => mapProduct(related))
      : [],
    variationFields: product?.variationFields,
    variants: hasVariants
      ? product?.variants?.map((variant) =>
          mapProduct({
            ...variant,
            id: product?._id || product?.id,
            title: capitalize(product?.title),
            slug: product?.slug,
            variationFields: product?.variationFields,
          })
        )
      : [],
  };
}

function mapCategory(collection, translations) {
  const translateCategoryTitle = (category) => {
    const categoryTitle = translations.find(
      (translation) => translation.id === category.url
    );
    return categoryTitle ? { ...categoryTitle } : category.title;
  };

  return {
    id: collection._id,
    title: collection.title,
    url: collection.url,
    categories: collection.categories.map((category) => ({
      id: category._id,
      title: translateCategoryTitle(category),
      url: `/${collection.url}/${category.url}`,
      subcategories: category.subcategories.map((subcategory) => ({
        id: subcategory._id,
        title: translateCategoryTitle(subcategory),
        url: `/${collection.url}/${category.url}/${subcategory.url}`,
      })),
    })),
  };
}

function mapFilters(filters) {
  return Object.entries(filters).map(([key, value]) => {
    const { title, values } = value;
    return {
      id: key,
      title: title,
      options: values,
    };
  });
}

export { mapCategory, mapFilters, mapProduct };
