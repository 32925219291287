import axios from "axios"
import { getHeaders } from "./login"
import { API_URL } from "../variables"
import { clearOrderData } from "../state/orderSlice";
import  orderSlice  from "../state/orderSlice";

export const creditCard = async (orderId, amount) => {
    try {
      const response = await axios.post(`${API_URL}/payment/pay`, {
        orderId,
        amount,
      });
      return response;
    } catch (error) {
      console.error('Payment error occurred', error);
      throw new Error('Payment error occurred');
    }
  };

  export const mbway = async (orderId, amount, mobileNumber, email, description) => {
    try {
      const response = await axios.post(`${API_URL}/payment/mbway`, {
        orderId,
        amount,
        mobileNumber,
        email,
        description,
      }, getHeaders());
      return response;
    } catch (error) {
      console.error('Payment error occurred', error);
      throw new Error('Payment error occurred');
    }
  };


export const multibanco = async (requestObj) => {
    try {
      const response = await axios.post(`${API_URL}/payment/multibanco`, {
       orderId: requestObj.orderId,
       amount: requestObj.amount,
       expiryDays: requestObj.expiryDays
      }, getHeaders());
      return response;
    } catch (error) {
      console.error('Payment error occurred', error);
      throw new Error('Payment error occurred');
    }
  };

  export const paypal = async (paymentData) => {
    try {
      const response = await axios.post(`${API_URL}/payment/paypal`, {
        orderId: paymentData.orderId,
        items: paymentData.items,
        currency: paymentData.currency,
        total: paymentData.total,
        description: paymentData.description
      }, getHeaders());
      return response;
    } catch (error) {
      console.error('Payment error occurred', error);
      throw new Error('Payment error occurred');
    }
  };

export const cashOndelivery = async (paymentData) => {
  try{
    const response = await axios.post(`${API_URL}/payment/cashondelivery`,
      {
        orderId: paymentData.orderId,
        amount: paymentData.amount
      },
      getHeaders()
    )
    return response
  }catch(error){
    console.error('Payment error occurred', error);
    throw new Error('Payment error occurred');
  }
}


export const getPayment = async (payment_type, paymentData) => {
  try{
    let response;
    switch(payment_type){
      case 'paypal':
      response = axios.get(
          `${API_URL}/payment/paypal/callback?paymentId=${paymentData.paymentId}&token=${paymentData.token}&PayerID=${paymentData.payerId}&orderId=${paymentData.orderId}`,
          getHeaders()
        );
        break;
      case 'mbway':
        response = axios.get(`${API_URL}/payment/mbway/callback?key=${paymentData.key}&orderId=${paymentData.orderId}&amount=${paymentData.amount}&requestId=${paymentData.requestId}&payment_datetime=${paymentData.payment_datetime}&orderId=${paymentData.orderId}`, getHeaders());
      break;
      case 'multibanco':
        response = axios.get(`${API_URL}/payment/mb/callback?orderId=${paymentData.orderId}&amount=${paymentData.amount}&requestId=${paymentData.requestId}&entity=${paymentData.entity}&reference=${paymentData.reference}&payment_datetime=${paymentData.payment_datetime}`, getHeaders());
      break;
      case 'credit_card':
        response = axios.get(`${API_URL}/payment/credit-card/callback?id=${paymentData.id}&amount=${paymentData.amount}&requestId=${paymentData.requestId}&sk=${paymentData.sk}&orderId=${paymentData.orderId}`, getHeaders());
      break;
      default:
      return null;
    }

    if((await response).data.payment_status === 'paid'){
      const clearOrder = orderSlice.reducer(clearOrderData())
      return clearOrder
    }

  return await response;
  }catch(error){
    console.error('Payment error occurred', error);
  }
}

export const paypalCallbackHandler = async (paymentId, token, payerId, orderId) => {
  try {
    if (paymentId && token && payerId) {
      const callbackObj = {
        paymentId,
        token,
        payerId,
        orderId
      };
      return await getPayment('paypal', callbackObj);
    }
  } catch (error) {
    console.error(`Error handling paypal callback: ${error}`);
  }
};

export const ifthenpayCallbackHandler = async (paymentType,callbackParams) => {
  try{
    let callbackObj = {}
    if(paymentType === 'multibanco'){
      callbackObj = {
        orderId: callbackParams.orderId,
        amount: callbackParams.amount,
        requestId: callbackParams.requestId,
        entity: callbackParams.entity,
        reference: callbackParams.reference,
        payment_datetime: callbackParams.payment_datetime
      }
    }
      if(paymentType === 'credit_card'){
        callbackObj = {
          id: callbackParams.id,
          amount: callbackParams.amount,
          requestId: callbackParams.requestId,
          sk: callbackParams.sk,
          brand: callbackParams.brand,
          pan: callbackParams.pan,
          orderId: callbackParams.orderId
        }
      }
        if(paymentType === 'mbway'){
          callbackObj = {
            orderId: callbackParams.orderId,
            amount: callbackParams.amount,
            requestId: callbackParams.requestId,
            payment_datetime: callbackParams.payment_datetime
          }
        }
        return await getPayment(paymentType,callbackObj)
      }
    catch(error){
      console.error(`Error handling paypal callback: ${error}`);
    }
  }

  export const getOrderAndPayment = async (orderId) => {
    try{
      return await axios.get(`${API_URL}/payment/${orderId}`, getHeaders())
    }catch(error){
      console.error(`Error getting Order and payment: ${error}`)
    }
  }
