import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const translationsSlice = createSlice({
  name: 'translations',

  initialState: initialState,

  reducers: {
    setTranslations: (state, action) => (state = action.payload),
  },
});

export const { setTranslations } = translationsSlice.actions;

export default translationsSlice.reducer;
