import axios from 'axios';
import { API_URL } from '../variables';
import { getHeaders } from './login';

//  GET

function getCountries() {
  return axios
    .get(`${API_URL}/configs/countries`, getHeaders())
    .then(({ data: { countries } }) => countries);
}

//  POST

function setLanguage(language) {
  return axios
    .post(`${API_URL}/translations/set-lang`, { lang: language }, getHeaders())
    .then(({ data }) => data);
}

export { getCountries, setLanguage };
