import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { getCountries } from '../../api/configs';
import { registerUser } from '../../api/login';
import { PAGES_SLUGS } from '../../variables';
import PopupNotification from '../PopupNotification/PopupNotification';
import RegisterSuccess from './RegisterSuccess';

const UserRegisterForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    successMessage: t('newRegister.successMessage2'),
  });
  const [formData, setFormData] = useState({});
  const [availableCountries, setAvailableCountries] = useState([]);
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(false);

  useEffect(() => {
    getCountries()
      .then((data) => setAvailableCountries(data))
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    registerUser(formData)
      .then(() => {
        setIsLoading(false);
        setShowConfirmation(true);
        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
        }));
      })
      .catch(({ response: { status, data } }) => {
        const errorMessage = data?.msg || data?.errors?.[0]?.msg;
        if (status === 401) {
          navigate('/login');
        } else if (status === 400 || status > 401) {
          setIsLoading(false);
          setNotificationProps((prevState) => ({
            ...prevState,
            isOpened: true,
            type: 'error',
            errorMessage: t(errorMessage),
          }));
        }
      });
  };

  if (showConfirmation) {
    return <RegisterSuccess />;
  }

  return (
    <Box
      component='form'
      onSubmit={handleSubmit}
      sx={{
        width: { xs: '80vw', md: '30vw' },
        marginTop: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{ textAlign: 'center', fontWeight: 700, marginBottom: 1 }}
      >
        {t('newRegister.title')}
      </Typography>

      <Box
        sx={{
          display: { xs: 'grid', sm: 'flex' },
          gap: { xs: 0, sm: 2 },
          width: '100%',
        }}
      >
        <TextField
          fullWidth
          label={t('forms.name')}
          type='text'
          name='name'
          required
          value={formData.name || ''}
          onChange={handleChange}
          error={formData.name === ''}
          helperText={formData.name === '' && t('forms.mandatoryField')}
        />
        <TextField
          fullWidth
          label={t('forms.contact')}
          type='text'
          name='phone'
          required
          value={formData.phone || ''}
          onChange={handleChange}
          error={formData.phone === ''}
          helperText={formData.phone === '' && t('forms.mandatoryField')}
        />
      </Box>

      <Box
        sx={{
          display: { xs: 'grid', sm: 'flex' },
          gap: { xs: 0, sm: 2 },
          width: '100%',
        }}
      >
        <TextField
          fullWidth
          label={t('forms.company')}
          type='text'
          name='company'
          required
          value={formData.company || ''}
          onChange={handleChange}
          error={formData.company === ''}
          helperText={formData.company === '' && t('forms.mandatoryField')}
        />
        <TextField
          fullWidth
          label={t('forms.vat')}
          type='text'
          name='taxNumber'
          required
          value={formData.taxNumber || ''}
          onChange={handleChange}
          error={formData.taxNumber === ''}
          helperText={formData.taxNumber === '' && t('forms.mandatoryField')}
        />
      </Box>

      <TextField
        fullWidth
        label={t('forms.email')}
        type='email'
        name='email'
        required
        value={formData.email || ''}
        onChange={handleChange}
        error={formData.email === ''}
        helperText={formData.email === '' && t('forms.mandatoryField')}
      />

      <Box
        sx={{
          display: { xs: 'block', sm: 'flex' },
          gap: 2,
          width: '100%',
        }}
      >
        <TextField
          fullWidth
          label={t('forms.address')}
          type='text'
          name='street'
          required
          value={formData.street || ''}
          onChange={handleChange}
          error={formData.street === ''}
          helperText={formData.street === '' && t('forms.mandatoryField')}
        />
        <TextField
          select
          fullWidth
          label={t('forms.country')}
          name='country'
          required
          value={formData.country || ''}
          onChange={handleChange}
          error={formData.country === ''}
          helperText={formData.country === '' && t('forms.mandatoryField')}
        >
          {availableCountries.map((country) => (
            <MenuItem
              key={`country-${country}`}
              value={country}
              title={country}
            >
              {country}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box
        sx={{
          display: { xs: 'block', sm: 'flex' },
          gap: 2,
          width: '100%',
        }}
      >
        <TextField
          fullWidth
          label={t('forms.postalCode')}
          type='text'
          name='zipCode'
          required
          value={formData.zipCode || ''}
          onChange={handleChange}
          error={formData.zipCode === ''}
          helperText={formData.zipCode === '' && t('forms.mandatoryField')}
        />
        <TextField
          fullWidth
          label={t('forms.city')}
          type='text'
          name='city'
          required
          value={formData.city || ''}
          onChange={handleChange}
          error={formData.city === ''}
          helperText={formData.city === '' && t('forms.mandatoryField')}
        />
      </Box>

      <Box
        sx={{
          width: '100%',
          display: { xs: 'block', sm: 'flex' },
          flexWrap: 'wrap',
        }}
      >
        <Box
          sx={{
            display: { xs: 'block', sm: 'flex' },
            gap: 2,
            width: '100%',
          }}
        >
          <TextField
            fullWidth
            label={t('forms.password')}
            type='password'
            name='password'
            required
            value={formData.password || ''}
            onChange={handleChange}
            error={formData.password === ''}
            helperText={formData.password === '' && t('forms.mandatoryField')}
          />

          <TextField
            fullWidth
            label={t('forms.confirmPassword')}
            type='password'
            name='confirmPassword'
            required
            value={formData.confirmPassword || ''}
            onChange={handleChange}
            error={
              formData.confirmPassword === '' ||
              formData.confirmPassword !== formData.password
            }
            helperText={
              formData.confirmPassword === ''
                ? t('forms.mandatoryField')
                : formData.confirmPassword !== formData.password
                ? t('forms.passwordsNotMatch')
                : null
            }
          />
        </Box>
        <Typography
          sx={{
            width: '100%',
            fontSize: '0.8rem',
            marginTop: 1,
          }}
        >
          {t('forms.passwordRules')}
        </Typography>
      </Box>

      <Box
        sx={{
          width: 'inherit',
          marginTop: { xs: 2, md: 1 },
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              onChange={() =>
                setTermsAndConditionsAccepted(!termsAndConditionsAccepted)
              }
            />
          }
          label={
            <Typography
              variant='small'
              sx={{
                '& a': {
                  textDecoration: 'underline',
                },
              }}
              dangerouslySetInnerHTML={{
                __html: `${t('forms.termsConsent')} <a href='/${
                  PAGES_SLUGS.institutional.termsAndConditions
                }'
                target='_blank' title='${t('pages.termsAndConditions')}'>${t(
                  'pages.termsAndConditions'
                )}
              </a>`,
              }}
            />
          }
        />
      </Box>

      <Box
        sx={{
          display: 'inline-flex',
          justifyContent: { sm: 'flex-end' },
          alignItems: 'flex-end',
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        <Button
          component={Link}
          to='/login'
          variant='contained'
          color='secondary'
          title={t('checkout.goBackButton')}
        >
          {t('checkout.goBackButton')}
        </Button>
        <LoadingButton
          variant='contained'
          type='submit'
          title={t('newRegister.submitButton')}
          sx={{
            marginTop: 3,
          }}
          loading={isLoading}
          disabled={
            formData.confirmPassword !== formData.password ||
            !termsAndConditionsAccepted
              ? true
              : false
          }
        >
          {t('newRegister.submitButton')}
        </LoadingButton>
      </Box>

      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </Box>
  );
};

export default UserRegisterForm;
