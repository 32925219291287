import { Box, Typography } from '@mui/material';
import { StyledTextButton } from '../styles';
import { useTranslation } from 'react-i18next';

const NotFoundMessage = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        margin: '20vh auto',
        textAlign: 'center',
      }}
    >
      <Typography
        variant='h1'
        marginBottom={2}
        sx={{
          fontSize: '4em',
        }}
      >
        404
      </Typography>
      <Typography
        variant='h5'
        marginBottom={4}
      >
        {t('app.pageNotFound')}
      </Typography>

      <StyledTextButton
        variant='contained'
        title={t('app.continueShoppingButton')}
        href='/'
      >
        {t('app.continueShoppingButton')}
      </StyledTextButton>
    </Box>
  );
};

export default NotFoundMessage;
