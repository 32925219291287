import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ProductsCarousel from '../Carousel/ProductsCarousel';

const ProductRelated = ({ related }) => {
  const { t } = useTranslation();
  return (
    <Box marginTop={{ xs: 4, md: 8 }}>
      <Typography
        variant='h2'
        marginBottom={{ xs: 2, md: 4 }}
      >
        {t('product.relatedProducts')}
      </Typography>
      <ProductsCarousel products={related} />
    </Box>
  );
};

export default ProductRelated;
