import React from 'react';
import ProductLabel from './ProductLabel';

const ProductStock = ({ stock }) => {
  const outOfStock = stock <= 0;

  if (outOfStock) {
    return <ProductLabel type='stock' />;
  }
};

export default ProductStock;
