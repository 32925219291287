import { Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CartProgressIndicator = ({ activeStep }) => {
  const { t } = useTranslation();
  const steps = [
    'cart.steps.resume',
    'cart.steps.checkout',
    'cart.steps.confirmation',
  ];
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      sx={{ marginTop: { md: 4 }, marginBottom: { xs: 5, md: 6 } }}
    >
      {steps.map((step) => (
        <Step key={step}>
          <StepLabel>{t(step)}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CartProgressIndicator;
