import React, { useEffect } from 'react';
import { Typography, CircularProgress } from '@mui/material';
import { usePayment } from '../../../contexts/PaymentContext';
import { useTranslation } from 'react-i18next';

const CashOnDelivery = () => {
  const {
    orderResponse,
    setOrderResponse,
    loading,
    setLoading,
    error,
    setError,
    processPayment,
  } = usePayment();
  const { t } = useTranslation();

  useEffect(() => {
    const handlePayment = () => {
      if (orderResponse) {
        setLoading(true);
        setError(null);
        try {
          const response = processPayment();
          if (response) {
            setOrderResponse({
              ...orderResponse,
              payment_status: 'paid',
            });
          }
        } catch (err) {
          setError(t('orderConfirmation.paymentError'));
        } finally {
          setLoading(false);
        }
      }
    };
    handlePayment();
  }, [
    orderResponse,
    setOrderResponse,
    setLoading,
    setError,
    t,
    processPayment,
  ]);
  if (loading) return <CircularProgress size={80} />;
  if (error) return <Typography color='error'>{error}</Typography>;
};

export default CashOnDelivery;
