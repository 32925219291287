import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';
import { useOutletContext } from 'react-router-dom';

const AccountFilters = (props) => {
  const { t } = useTranslation();
  const [XSMALL_DEVICES] = useOutletContext();

  const statusArray = [];

  const uniqueDates = new Set();

  // type can be orders or receipts
  const presentationalStatus = props.type.map((type) => {
    const inArray = statusArray.find((_status) => _status === type.status);

    if (inArray) {
      return null;
    }
    statusArray.push(type.status);

    return (
      <MenuItem
        key={type.reference}
        value={type.status}
      >
        {type.status}
      </MenuItem>
    );
  });

  props.type.forEach((type) =>
    uniqueDates.add(new Date(type.date).getFullYear())
  );

  const dates = Array.from(uniqueDates);

  const sortedDates = dates.sort().reverse();

  const presentationalDates = sortedDates.map((date) => {
    return (
      <MenuItem
        key={date}
        value={date}
      >
        {date}
      </MenuItem>
    );
  });

  const isValidStatus = statusArray.includes(props.selectedStatus);

  const isValidYear = dates.includes(props.selectedYear);

  function onStatusChange(e) {
    props.setSelectedStatus(e.target.value);
  }

  function onYearChange(e) {
    props.setSelectedYear(e.target.value);
  }

  return (
    <Box
      component='form'
      sx={{
        marginBottom: 1,
        display: 'flex',
        flexDirection: XSMALL_DEVICES && 'column',
        gap: 1,
      }}
    >
      <FormControl sx={{ width: XSMALL_DEVICES ? '100%' : '20%' }}>
        <InputLabel>{t('tables.filters.status')}</InputLabel>

        <Select
          label={t('tables.filters.status')}
          fullWidth
          value={!isValidStatus ? 'all' : props.selectedStatus}
          onChange={onStatusChange}
          sx={{
            backgroundColor: '#fff',
          }}
        >
          <MenuItem value='all'>{t('tables.filters.all')}</MenuItem>

          {presentationalStatus}
        </Select>
      </FormControl>

      <FormControl sx={{ width: XSMALL_DEVICES ? '100%' : '20%' }}>
        <InputLabel sx={{ marginBottom: 1 }}>
          {t('tables.filters.year')}
        </InputLabel>

        <Select
          label={t('tables.filters.year')}
          value={!isValidYear ? 'all' : props.selectedYear}
          onChange={onYearChange}
          sx={{
            backgroundColor: '#fff',
          }}
        >
          <MenuItem value='all'>{t('tables.filters.all')}</MenuItem>

          {presentationalDates}
        </Select>
      </FormControl>

      <TextField
        label={t('tables.filters.search')}
        value={props.search}
        onChange={(e) => props.setSearch(e.target.value.trim())}
        InputProps={{
          endAdornment: <FiSearch />,
        }}
        sx={{
          width: XSMALL_DEVICES ? '100%' : '20%',
          marginTop: 0,
          marginLeft: 'auto',
        }}
      />
    </Box>
  );
};

export default AccountFilters;
