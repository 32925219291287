import { Box, Dialog, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { VscClose } from 'react-icons/vsc';
import { IconButton } from '../styles';
import { FONT_STYLE } from '../variables';

export const ModalTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: FONT_STYLE.bold,
  padding: 0,
  marginBottom: theme.spacing(1),
}));

export const ModalContent = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const ModalActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  marginTop: theme.spacing(3),
}));

export const ModalClose = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(0.5),
  right: theme.spacing(0.5),
  color: 'black',
  borderRadius: '100%',
  [theme.breakpoints.up(theme.breakpoints.values.md)]: {
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
  },
}));

const Modal = (props) => {
  const { t } = useTranslation();
  const {
    children,
    isOpened = false,
    setIsOpened,
    hideCloseButton = false,
    muiComponentNativeProps,
  } = props;
  return (
    <Dialog
      open={isOpened}
      onClose={() => setIsOpened(false)}
      PaperProps={{
        sx: {
          maxWidth: '100%',
          padding: 4,
        },
      }}
      {...muiComponentNativeProps}
    >
      {!hideCloseButton && (
        <ModalClose
          title={t('app.closeButton')}
          onClick={() => setIsOpened(false)}
          startIcon={<VscClose size={24} />}
        />
      )}
      {children}
    </Dialog>
  );
};

export default React.memo(Modal);
