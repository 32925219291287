/*
|--------------------------------
| get product details with all validations
|--------------------------------
*/
export const productDetailsInterface = (product) => {
  let { saleAmount, variants, pvpPrice, price, onSale, salePrice, stock } =
    product;

  const hasVariants = !!variants.length;
  let variantsSortedAscByPrice = [];
  let variantsStock = undefined;

  if (hasVariants) {
    variantsSortedAscByPrice = [...variants].sort((a, b) =>
      a.onSale ? a.salePrice - b.salePrice : a.price - b.price
    )[0];

    const variantsWithoutStockArray = variants.filter(
      (variant) => variant.stock === 0
    );

    variantsStock =
      variantsWithoutStockArray.length === variants.length ? 0 : 1;
  }

  return {
    hasVariants: hasVariants,
    pvpPrice: !hasVariants ? pvpPrice : variantsSortedAscByPrice.pvpPrice,
    price: !hasVariants ? price : variantsSortedAscByPrice.price,
    onSale: !hasVariants ? onSale : variantsSortedAscByPrice.onSale,
    salePrice: !hasVariants ? salePrice : variantsSortedAscByPrice.salePrice,
    saleAmount: saleAmount,
    stock: !hasVariants ? stock : variantsStock,
  };
};
