import axios from 'axios';
import { API_URL } from '../variables';
import { getHeaders } from './login';

//  GET

async function checkDeliveryOptions() {
  return axios
    .get(`${API_URL}/orders/delivery-methods`, getHeaders())
    .then(({ data }) => data);
}

// POST

async function updateCartProducts(products, customerId) {
  return axios
    .post(`${API_URL}/cart/update`, { products, customerId }, getHeaders())
    .then(({ data: { cart } }) => cart);
}

async function removeProductFromCart(reference, customerId) {
  return axios
    .post(`${API_URL}/cart/remove`, { reference, customerId }, getHeaders())
    .then(({ data: { cart } }) => cart);
}

async function checkDeliveryCost(country, zipCode, customerId) {
  return axios
    .post(`${API_URL}/orders/delivery-cost`, {country, zipCode, customerId}, getHeaders())
    .then(({ data }) => data);
}

async function applyDiscount(reference, discount) {
  return axios
    .post(`${API_URL}/cart/remove`, { reference, discount }, getHeaders())
    .then(({ data: { cart } }) => cart);
}

async function confirmOrder(orderDetails) {
  return axios
    .post(`${API_URL}/orders/create`, orderDetails, getHeaders())
    .then(({ data: { order } }) => order);
}

export {
  checkDeliveryOptions,
  updateCartProducts,
  applyDiscount,
  removeProductFromCart,
  checkDeliveryCost,
  confirmOrder,
};
