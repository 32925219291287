import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FONT_STYLE, SETTINGS } from '../../../variables';
import Quantity from '../../Quantity/Quantity';
import AddShoppingListButton from '../../ShoppingLists/AddShoppingListButton';
import ProductVariantsDetails from '../ProductVariants/ProductVariantsDetails';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StyledTextButton } from '../../../styles';

const ProductCheckout = ({
  product,
  hasVariants,
  addToCart,
  productsForCart,
  setProductsForCart,
}) => {
  const { t } = useTranslation();
  const { role } = useSelector((state) => state.user);

  const addToCartDisabled =
    (hasVariants && productsForCart.totalUnits === 0) ||
    (!hasVariants && (product.sellsUnit ? product.stock === 0 : product.stockPerBox === 0));

  const [updatedProductQuantity, setUpdatedProductQuantity] = useState({
    // SIMPLE PRODUCT
    ...product,
    quantity: {
      ...product.quantity,
      [SETTINGS.defaults.quantityType]: 1,
    },
  });
  const [shoppingListModal, setShoppingListModal] = useState({});

  useEffect(() => {
    if (hasVariants) return;

    //  IF 'simple product' » ADD/UPDATE 'simple product' inside array
    setProductsForCart((prevState) => ({
      ...prevState,
      products: [updatedProductQuantity],
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedProductQuantity]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: hasVariants ? 'space-between' : 'flex-start',
          alignItems: hasVariants ? 'flex-start' : { xs: 'flex-start', sm: 'flex-end' },
          flexWrap: 'wrap',
          columnGap: { xs: 3, md: 5 },
          rowGap: 2.5,
          width: '100%',
          marginTop: 2,
        }}
      >
        {hasVariants ? (
          <ProductVariantsDetails
            productsForCart={productsForCart}
            setProductsForCart={setProductsForCart}
          />
        ) : (
          <Box>
            <Typography
              sx={{
                marginBottom: 1,
                fontWeight: FONT_STYLE.medium,
              }}
            >
              {t('product.quantity')}
            </Typography>
            <Quantity
              product={updatedProductQuantity}
              setUpdatedProductQuantity={setUpdatedProductQuantity}
            />
          </Box>
        )}

        <Box
          sx={{
            display: 'grid',
            gap: 2,
          }}
        >
          {!!hasVariants && role !== 'limitedcustomer' && (
            <Typography
              variant='xsmall'
              sx={{
                width: '100%',
                textAlign: { sm: 'right' },
                order: { xs: 99, sm: 0 },
              }}
            >
              {t('product.priceWithoutVat')}
            </Typography>
          )}

          <Box
            sx={{
              display: 'flex',
              gap: 2,
            }}
          >
            {SETTINGS.functionalities.shoppingList && !hasVariants && (
              <AddShoppingListButton
                productReference={product.reference}
                modalIsOpen={shoppingListModal}
                setModalIsOpen={setShoppingListModal}
              >
                <StyledTextButton
                  variant='contained'
                  color='secondary'
                  title={t('myAccount.shoppingLists.addToShoppingList')}
                  onClick={() => setShoppingListModal({ [product.reference]: true })}
                >
                  {t('myAccount.shoppingLists.addToShoppingList')}
                </StyledTextButton>
              </AddShoppingListButton>
            )}

            <Button
              variant='contained'
              title={t('cart.addToCart')}
              onClick={() => addToCart()}
              disabled={!!addToCartDisabled}
            >
              {t('cart.addToCart')}
            </Button>
          </Box>
        </Box>

        {!!!hasVariants && role !== 'limitedcustomer' && (
          <Typography
            variant='xsmall'
            width='100%'
          >
            {t('product.priceWithoutVat')}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default ProductCheckout;
