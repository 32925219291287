import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateAddress } from '../../../api/user';
import { compareTwoObjects, objectIsEmpty } from '../../../helpers';
import { slugify } from '../../../helpers/slugify';
import { setAddresses } from '../../../state/userSlice';
import { MyAccountTitle } from '../../../styles';
import PopupNotification from '../../PopupNotification/PopupNotification';

const AddressesForm = ({
  openNewAddressModal,
  addresses,
  availableCountries,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addIdToAddresses = (address) =>
    address.map((address) => {
      const { city, country, zipCode, street } = address;
      const id = `${street}-${city}-${zipCode}-${country}`;
      const idSlugifyed = slugify(id);
      return { ...address, oldStreet: street, id: idSlugifyed };
    });

  const [userAddresses, setUserAddresses] = useState(
    addIdToAddresses(addresses)
  );
  const [shownAddress, setShownAddress] = useState(userAddresses?.[0]);
  const [selectedAddressDefault, setSelectedAddressDefault] = useState(
    userAddresses?.[0]
  );
  const [addressChanged, setAddressChanged] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    successMessage: t('myAccount.addresses.successMessage'),
  });

  useEffect(() => {
    setShownAddress(userAddresses[0]);
  }, [userAddresses]);

  useEffect(() => {
    setUserAddresses(addIdToAddresses(addresses));
  }, [addresses, dispatch]);

  //  check IF exists differences between 'default address selected' AND 'changed addres'
  useEffect(() => {
    if (!!shownAddress === false) return;

    const addressDifferences = compareTwoObjects(
      shownAddress,
      selectedAddressDefault
    );
    const addressHasDifferences = !objectIsEmpty(addressDifferences);

    if (addressHasDifferences) {
      if (!addressChanged) setAddressChanged(true);
    } else {
      if (addressChanged) setAddressChanged(false);
    }
  }, [shownAddress, selectedAddressDefault, addressChanged]);

  function handleSelectedAddress(e) {
    const { value } = e.target;

    const newAddress = userAddresses.find(({ id }) => id === value);

    setSelectedAddressDefault(newAddress);
    setShownAddress(newAddress);
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setShownAddress((values) => ({ ...values, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    updateAddress(shownAddress)
      .then((addresses) => {
        dispatch(setAddresses(addresses));
        setIsLoading(false);
        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
        }));
      })
      .catch(
        ({
          response: {
            data: { errors },
            status,
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status > 401) {
            const errorMessage = errors.reduce(
              (prevValue, { msg }) => prevValue + `${msg}. `,
              ''
            );

            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: t(errorMessage),
            }));

            setIsLoading(false);
          }
        }
      );
  }

  return (
    <Box
      component='form'
      onSubmit={handleSubmit}
      sx={{
        width: { xs: '100%', lg: '50%' },
      }}
    >
      {!!shownAddress && (
        <>
          <FormControl fullWidth>
            <InputLabel>{t('myAccount.addresses.selectedAddress')}</InputLabel>
            <Select
              label={t('myAccount.addresses.selectedAddress')}
              name='selectedAddress'
              value={shownAddress?.id}
              onChange={handleSelectedAddress}
            >
              {userAddresses.map((address) => {
                const { street, id } = address;
                return (
                  <MenuItem
                    key={id}
                    value={id}
                  >
                    {street}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Box
            sx={{
              marginTop: 4,
              marginBottom: 3,
            }}
          >
            <MyAccountTitle>
              {t('myAccount.addresses.editSelectedAddress')}
            </MyAccountTitle>

            <TextField
              fullWidth
              multiline
              required
              type='text'
              name='street'
              label={t('forms.address')}
              value={shownAddress.street}
              onChange={handleChange}
              error={!shownAddress.street}
              helperText={!shownAddress.street && t('forms.mandatoryField')}
            />

            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: { xs: 0, md: 2 },
              }}
            >
              <TextField
                type='text'
                name='zipCode'
                label={t('forms.postalCode')}
                required
                value={shownAddress.zipCode}
                onChange={handleChange}
                error={!shownAddress.zipCode}
                helperText={!shownAddress.zipCode && t('forms.mandatoryField')}
                sx={{ flex: 1 }}
              />

              <TextField
                type='text'
                name='city'
                label={t('forms.city')}
                required
                value={shownAddress.city}
                onChange={handleChange}
                error={!shownAddress.city}
                helperText={!shownAddress.city && t('forms.mandatoryField')}
                sx={{ flex: 1 }}
              />
            </Box>

            <Box
              sx={{
                width: { xs: '100%', md: `calc(50% - ${theme.spacing(1)})` },
              }}
            >
              <TextField
                select
                name='country'
                label={t('forms.country')}
                required
                fullWidth
                value={shownAddress.country || ''}
                onChange={handleChange}
                error={!shownAddress.country}
                helperText={!shownAddress.country && t('forms.mandatoryField')}
                sx={{ flex: 1 }}
              >
                {availableCountries.map((country) => (
                  <MenuItem
                    key={`country-${country}`}
                    value={country}
                    title={country}
                  >
                    {country}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
        </>
      )}

      <Box
        sx={{
          display: 'flex',
          gap: 2,
        }}
      >
        <Button
          onClick={() => openNewAddressModal(true)}
          variant='contained'
          color='secondary'
          title={t('myAccount.addresses.addAddress')}
          endIcon={<FiPlus size={14} />}
        >
          {t('myAccount.addresses.addAddress')}
        </Button>

        {!!shownAddress && (
          <LoadingButton
            variant='contained'
            type='submit'
            title={t('forms.saveChangesButton')}
            disabled={
              !shownAddress?.street ||
              !shownAddress?.zipCode ||
              !shownAddress?.city ||
              !shownAddress?.country ||
              !addressChanged
                ? true
                : false
            }
            loading={isLoading}
          >
            {t('forms.saveChangesButton')}
          </LoadingButton>
        )}
      </Box>

      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </Box>
  );
};

export default AddressesForm;
