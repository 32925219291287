import React from 'react';
import { Container, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Grid from '@mui/material/Unstable_Grid2';
import { CUSTOM_THEME_COLORS } from '../../variables';
import { useTranslation } from 'react-i18next';

const NotificationBar = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        backgroundColor: CUSTOM_THEME_COLORS.lightPrimary,
      }}
    >
      <Container>
        <Grid container>
          <Grid xs={12}>
            <Box
              sx={{
                paddingY: 0.5,
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <Typography
                variant='small'
                sx={{
                  fontSize: { xs: '0.7rem', md: '0.85rem' },
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                {t('notificationsBar.option1')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default NotificationBar;
