import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CUSTOM_THEME_COLORS, FONT_STYLE } from '../variables';

const ProductBadge = ({ badge }) => {
  const { t } = useTranslation();
  const badgeLabel =
    badge === 1
      ? t('product.newBadge')
      : badge === 2
      ? t('product.promotionBadge')
      : null;

  return (
    <Typography
      sx={{
        position: 'absolute',
        top: '0',
        left: '0',
        fontSize: '0.65rem',
        color: 'white',
        letterSpacing: '0.25px',
        fontWeight: FONT_STYLE.medium,
        paddingX: 1,
        backgroundColor:
          badge === 1
            ? CUSTOM_THEME_COLORS.green
            : badge === 2
            ? CUSTOM_THEME_COLORS.red
            : null,
        textTransform: 'uppercase',
      }}
    >
      {badgeLabel}
    </Typography>
  );
};

export default ProductBadge;
