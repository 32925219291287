import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getProductUserHistory } from '../../api/user';
import { MyAccountTitle } from '../../styles';
import ProductsCarousel from '../Carousel/ProductsCarousel';
import Loading from '../Loading';

const ProductsHistory = () => {
  const { t } = useTranslation();
  const userState = useSelector((state) => state.user);
  const { role, id, selectedClient } = userState;
  const customerId = selectedClient?.id || id;

  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getProductUserHistory(customerId).then((productHistory) => {
      setProducts(productHistory);
      setIsLoading(false);
    });
  }, [customerId]);

  const breakpoints = {
    0: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
    400: {
      slidesPerView: 2,
      slidesPerGroup: 2,
    },
    600: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
  };

  return (
    <Box
      sx={{
        flex: 3,
      }}
    >
      <Box marginBottom={4}>
        <MyAccountTitle>{t('myAccount.productHistory.title')}</MyAccountTitle>
        <Typography variant='small'>
          {t('myAccount.productHistory.text', {
            person:
              role === 'seller' && !!selectedClient?.companyInfo?.name
                ? `${selectedClient.companyInfo.name}`
                : '',
          })}
        </Typography>
      </Box>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          {!!products.length ? (
            <ProductsCarousel
              products={products}
              breakpoints={breakpoints}
            />
          ) : (
            <Typography sx={{ marginY: 2 }}>
              {t('myAccount.productHistory.noDataAvailable')}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default ProductsHistory;
