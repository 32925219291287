import { CssBaseline } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getCategories, getTranslations } from './api/index.js';
import { mapCategory } from './api/mapApiInterface.js';
import Cookies from './components/Cookies/Cookies';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { arraysEqual } from './helpers/compare.js';
import './i18n.js';
import { setCategories } from './state/categoriesSlice.js';
import { setTranslations } from './state/translationsSlice.js';
import { PAGES_SLUGS } from './variables';

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const loginPages = location.pathname.includes(PAGES_SLUGS.login);

  const { id, selectedClient } = useSelector((state) => state.user);
  const storedTranslations = useSelector((state) => state.translations);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);

  useEffect(() => {
    getTranslations()
      .then((translations) => {
        const arraysAreEqual = arraysEqual(translations, storedTranslations);

        //  if arrays aren't equal
        if (arraysAreEqual === false) {
          dispatch(setTranslations(translations));
        }
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      //  if does not exist translations » return
      if (storedTranslations.length === 0) return;

      getCategories()
        .then((receivedCollections) => {
          const categoriesTranslations = storedTranslations
            //  get all 'category' translations
            .filter((translation) => translation.id.includes('category.'))
            //  remove 'category.' string from 'id'
            .map((translation) => ({
              ...translation,
              id: translation.id.slice(translation.id.indexOf('.') + 1),
            }));

          const collectionsTranslated = receivedCollections.map((collection) =>
            mapCategory(collection, categoriesTranslations)
          );

          dispatch(setCategories(collectionsTranslated));
        })
        .catch(({ response: { status } }) => {
          if (status === 401) navigate('/login');
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, selectedClient, storedTranslations]
  );

  return (
    <CssBaseline>
        <div className='app'>
          {!loginPages && <Header />}
          <Outlet />
          {!loginPages && <Footer />}
          <Cookies />
        </div>
    </CssBaseline>
  );
};

export default App;
