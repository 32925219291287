import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiTrash2 } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteProductFromShoppingList } from '../../api/user';
import { formatCurrency } from '../../constants/utils';
import { productDetailsInterface } from '../../helpers/productDetailsInterface';
import { setShoppingLists } from '../../state/shoppingListsSlice';
import { BadgeDiscount, IconButton } from '../../styles';
import { CUSTOM_THEME_COLORS, FONT_STYLE } from '../../variables';
import SummaryProductInfo from '../Cart/FirstStepSummary/SummaryTable/SummaryProductInfo';
import PopupNotification from '../PopupNotification/PopupNotification';

const ShoppingListProducts = ({ index, shoppingLists }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { role, priceIsVisible } = useSelector((state) => state.user);
  const isLimitedCustomer = role === 'limitedcustomer';

  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    type: '',
    successMessage: t(
      'myAccount.shoppingLists.productRemovedFromShoppingListMessage'
    ),
    errorMessage: '',
  });

  const { _id, products } = shoppingLists?.[index];

  const handleDeleteProductFromShoppingList = (productReference) => {
    deleteProductFromShoppingList(_id, productReference)
      .then((lists) => {
        dispatch(setShoppingLists(lists));

        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
        }));
      })
      .catch(
        ({
          response: {
            data: { msg },
            status,
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: t(msg),
            }));
          }
        }
      );
  };

  return (
    <Box
      sx={{
        marginTop: 4,
      }}
    >
      {shoppingLists?.[index]?.products.length !== 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ minWidth: { xs: '275px', md: 'initial' } }}>
                  {t('tables.details')}
                </TableCell>
                {products?.variants?.length !== 0 && <TableCell />}
                {!isLimitedCustomer && (
                  <>
                    <TableCell
                      sx={{ minWidth: { xs: '150px', md: 'initial' } }}
                    >
                      {t('tables.pvp')}
                    </TableCell>
                    {priceIsVisible && (
                      <TableCell
                        sx={{ minWidth: { xs: '150px', md: 'initial' } }}
                      >
                        {t('tables.rev')}*
                      </TableCell>
                    )}
                  </>
                )}
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {Array.isArray(products) &&
                products.map((product) => {
                  const {
                    slug,
                    reference,
                    images,
                    title,
                    brand,
                    saleAmount,
                    stock
                  } = product;
                  const productDetails = productDetailsInterface(product);

                  return (
                    <TableRow
                      key={`shoppingList-product-${reference}`}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>
                        <SummaryProductInfo
                          slug={slug}
                          images={images}
                          title={title}
                          reference={reference}
                          brand={brand}
                          stock={stock}
                          showStockAvailability
                        />
                      </TableCell>

                      {!isLimitedCustomer && (
                        <>
                          <TableCell>
                            {product?.variants?.length !== 0 && (
                              <Typography variant='xsmall'>
                                {t('product.pricesFrom')}
                              </Typography>
                            )}
                          </TableCell>

                          <TableCell>
                            <Typography variant='small'>
                              {formatCurrency(productDetails.pvpPrice)}
                            </Typography>
                          </TableCell>

                          {priceIsVisible && (
                            <TableCell>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  gap: 1,
                                }}
                              >
                                <Typography
                                  variant='small'
                                  sx={{
                                    textDecoration:
                                      productDetails.onSale && 'line-through',
                                    fontWeight: FONT_STYLE.bold,
                                  }}
                                >
                                  {formatCurrency(productDetails.price)}
                                </Typography>

                                {productDetails.onSale && (
                                  <>
                                    <Typography
                                      variant='small'
                                      sx={{
                                        fontWeight: FONT_STYLE.bold,
                                        color: CUSTOM_THEME_COLORS.primary,
                                      }}
                                    >
                                      {formatCurrency(productDetails.salePrice)}
                                    </Typography>
                                    <BadgeDiscount>{`${saleAmount}%`}</BadgeDiscount>
                                  </>
                                )}
                              </Box>
                            </TableCell>
                          )}
                        </>
                      )}

                      <TableCell>
                        <IconButton
                          onClick={() =>
                            handleDeleteProductFromShoppingList(reference)
                          }
                          title={t('tables.actions.remove')}
                          sx={{
                            minWidth: 'initial',
                          }}
                        >
                          <FiTrash2 size={16} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>
          {t('myAccount.shoppingLists.noProductsAssociated')}
        </Typography>
      )}

      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </Box>
  );
};

export default ShoppingListProducts;
