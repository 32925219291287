import { Box, Divider, Icon, styled, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FiGrid, FiList } from 'react-icons/fi';
import { ProductsListContext } from '../../contexts/ProductsListContext';
import { CUSTOM_THEME_COLORS, TRANSITION_DURATION } from '../../variables';
import ProductFilterPopover from '../ProductFilterPopover';
import ProductsSortBy from './ProductsSortBy';

const SetLayoutButton = styled(Icon)(({ active }) => ({
  backgroundColor: active === 'true' && CUSTOM_THEME_COLORS.gray,
  borderRadius: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  height: '32px',
  cursor: 'pointer',
  transition: `background-color ${TRANSITION_DURATION}ms ease`,
  '&:hover': {
    backgroundColor: CUSTOM_THEME_COLORS.gray,
  },
}));

const ProductsListToolbar = () => {
  const { t } = useTranslation();
  const { pagination, filters, layout, setLayout } = useContext(ProductsListContext);

  const theme = useTheme();
  const MOBILE_DEVICES = useMediaQuery(theme.breakpoints.down('md'));

  const SORT_OPTIONS = [
    {
      id: 'name:asc',
      text: t('productList.sortOptions.nameAsc'),
    },
    {
      id: 'name:desc',
      text: t('productList.sortOptions.nameDec'),
    },
    {
      id: 'price:asc',
      text: t('productList.sortOptions.priceAsc'),
    },
    {
      id: 'price:desc',
      text: t('productList.sortOptions.priceDesc'),
    },
    // {
    //   id: 'date:desc',
    //   text: 'Mais Recente',
    // },
    // {
    //   id: 'date:asc',
    //   text: 'Mais Antigo',
    // },
  ];

  return (
    <>
      {!MOBILE_DEVICES ? (
        <>
          <ProductsSortBy options={SORT_OPTIONS} />

          <Divider
            orientation='vertical'
            flexItem
            sx={{
              borderColor: CUSTOM_THEME_COLORS.gray,
              marginLeft: 1,
              marginRight: 2,
            }}
          />

          <Box
            sx={{
              display: 'flex',
              gap: 1,
            }}
          >
            <SetLayoutButton
              title={t('productList.gridView')}
              active={layout === 'grid' ? 'true' : 'false'}
              onClick={() => setLayout('grid')}
            >
              <FiGrid size={16} />
            </SetLayoutButton>

            <SetLayoutButton
              title={t('productList.listView')}
              active={layout === 'list' ? 'true' : 'false'}
              onClick={() => setLayout('list')}
            >
              <FiList size={16} />
            </SetLayoutButton>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            width: { xs: '100%', md: 'initial' },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 3,
            marginTop: { xs: 2, md: 0 },
          }}
        >
          {!!filters.list.length && (
            <ProductFilterPopover
              availableFilters={filters.list}
              activeFilters={filters.active}
              setPage={pagination.onChange}
            />
          )}
          <ProductsSortBy options={SORT_OPTIONS} />
        </Box>
      )}
    </>
  );
};

export default ProductsListToolbar;
