import React from 'react';
import { Alert, Snackbar } from '@mui/material';

const PopupNotification = ({
  props,
  setNotificationProps,
  notificationProps: {
    isOpened = false,
    type = '',
    successMessage = 'Success message',
    warningMessage = 'Warning message',
    errorMessage = 'Error message',
  },
  autoHideDuration = 7500,
}) => {
  const handleClose = (reason) => {
    if (reason === 'clickaway') return;
    setNotificationProps((prevState) => ({ ...prevState, isOpened: false }));
  };

  return (
    <Snackbar
      {...props}
      autoHideDuration={autoHideDuration}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isOpened}
      onClose={handleClose}
      sx={{
        '& .MuiAlert-message': {
          display: 'flex',
          alignItems: 'center',
        },
      }}
    >
      {
        {
          success: (
            <Alert
              severity='success'
              onClose={handleClose}
            >
              {successMessage}
            </Alert>
          ),
          warning: (
            <Alert severity='warning' onClose={handleClose}>
              {warningMessage}
            </Alert>
          ),
          error: (
            <Alert
              severity='error'
              onClose={handleClose}
            >
              {errorMessage}
            </Alert>
          ),
        }[type]
      }
    </Snackbar>
  );
};

export default PopupNotification;
