import { LoadingButton } from '@mui/lab';
import { Box,Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checkDeliveryCost, confirmOrder } from '../../../api/checkout';
import { StyledTextButton } from '../../../styles';
import { COMPANY_INFO, DELIVERY_METHODS } from '../../../variables';
import PopupNotification from '../../PopupNotification/PopupNotification';
import DetailsForm from './DetailsForm/DetailsForm';
import OrderSummaryCard from './OrderSummaryCard';
import { setPendingOrder } from '../../../state/orderSlice';

const OrderDetails = ({
  setActiveStep,
  cart,
  checkCartProducts,
  handleConfirmation,
  setHandleConfirmation,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    id: cartId,
    cart: cartItems,
    subtotal,
    total: totalCart,
    discount: totalDiscount,
    taxes,
  } = cart;

  const [isLoading, setIsLoading] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
  });

  const user = useSelector((state) => state.user);
  const { id, selectedClient, sellerInfo, companyInfo, role } = user;
  const customerId = selectedClient?.id || id;
  const isLimitedCustomer = role === 'limitedcustomer';

  const [shipping, setShipping] = useState(DELIVERY_METHODS.ADDRESS);
  const [paymentMethod, setPaymentMethod] = useState(sellerInfo.paymentMethods[0]);

  const [deliveryCost, setDeliveryCost] = useState(0);
  const [deliveryTaxes, setDeliveryTaxes] = useState(undefined);
  const [orderTaxes, setOrderTaxes] = useState([]);
  const [observations, setObservations] = useState('');
  const [store, setStore] = useState({
    street: COMPANY_INFO.address.street,
    zipCode: COMPANY_INFO.address.zipCode,
    city: COMPANY_INFO.address.city,
    country: COMPANY_INFO.address.city,
  });

  //	get user addresses
  const clientAddresses = selectedClient.addresses || user.addresses;
  const [address, setAddress] = useState('');

  const [orderDetails, setOrderDetails] = useState({
    payment_type: paymentMethod,
    paymentTime: selectedClient.payment_deadlines || sellerInfo.paymentTime,
    shipping,
    deliveryAtAddress: address,
    deliveryAtStore: store,
    notes: observations,
    subtotal: subtotal,
    total: 0,
    total_discount: totalDiscount,
    shipping_price: deliveryCost,
    cart: cartItems,
    cart_id: cartId,
    payment_status: 'pending',
  });

  useEffect(() => {
    const cartTotal = Math.fround(
      totalCart + deliveryCost + (!!deliveryTaxes ? deliveryTaxes.total : 0)
    );
    setOrderDetails((values) => ({
      ...values,
      total: cartTotal,
    }));

    if (!!deliveryTaxes) {
      //  add OR update existing taxes
      const newTaxes = [...taxes, deliveryTaxes].reduce((acc, tax) => {
        const existingTax = acc.findIndex((elm) => elm.iva === tax.iva);
        existingTax !== -1
          ? (acc[existingTax] = {
              ...acc[existingTax],
              total: acc[existingTax].total + tax.total,
            })
          : tax.iva !== 0 && acc.push(tax);
        return acc;
      }, []);
      setOrderTaxes(newTaxes);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCart, deliveryCost, deliveryTaxes]);

  useEffect(() => {
    //  IF shipping method is 'STORE'
    if (shipping === DELIVERY_METHODS.STORE) {
      setDeliveryCost(0);
      setOrderDetails((values) => ({
        ...values,
        shipping_price: 0,
      }));
      return;
    }

    if (!!address) {
      checkDeliveryCost(address.country, address.zipCode, customerId)
        .then(({ deliveryCost, taxes }) => {
          setDeliveryCost(deliveryCost);
          setDeliveryTaxes(taxes);
          setOrderDetails((values) => ({
            ...values,
            shipping_price: deliveryCost,
          }));
        })
        .catch(({ response: { status, data } }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: t(data.msg),
            }));
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, clientAddresses, shipping, navigate, customerId]);

  function onPaymentChange(e) {
    setPaymentMethod(e.target.value);
    setOrderDetails((values) => ({ ...values, payment_type: e.target.value }));
  }

  function onShippingChange(e) {
    setShipping(e.target.value);
    if (e.target.value === DELIVERY_METHODS.BILLING_ADDRESS) {
      const companyAddress = {
        street: companyInfo?.street,
        zipCode: companyInfo?.zipCode,
        city: companyInfo?.city,
        country: companyInfo?.country,
      };

      setAddress(companyAddress);
      setOrderDetails((values) => ({
        ...values,
        shipping: e.target.value,
        deliveryAtAddress: companyAddress,
      }));
    } else {
      setAddress(clientAddresses[0]);
      setOrderDetails((values) => ({
        ...values,
        shipping: e.target.value,
        deliveryAtAddress: clientAddresses[0],
      }));
    }
  }

  function onAddressChange(e, a) {
    setAddress(clientAddresses[a.props.index]);
    setOrderDetails((values) => ({
      ...values,
      deliveryAtAddress: clientAddresses[a.props.index],
    }));
  }

  function onStoreChange(e) {
    setStore(e.target.value);
    setOrderDetails((values) => ({
      ...values,
      deliveryAtStore: e.target.value,
    }));
  }

  function onObservationsChange(e) {
    setObservations(e.target.value);
    setOrderDetails((values) => ({
      ...values,
      notes: e.target.value,
    }));
  }

  const handleConfirmOrder = () => {
    if (!!!address) return;

    setIsLoading(true);
    confirmOrder(orderDetails)
        .then((order) => {
          if (order) {
            setIsLoading(false);
            setActiveStep(2);
            dispatch(setPendingOrder(order));
          }
        })
        .catch(({ response: { data, status } }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setIsLoading(false);
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: data?.msg?.message || t(data?.msg),
            }));
          }
        });
    };

  useEffect(() => {
    if (!handleConfirmation) return;

    handleConfirmOrder();

    setHandleConfirmation(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleConfirmation]);

  return (
    <>
      <Box>
        <Typography
          variant='h2'
          sx={{
            textAlign: 'center',
            marginBottom: { xs: 3, md: 4 },
          }}
        >
          {t('cart.steps.checkout')}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'initial' },
            alignItems: { sm: 'center', md: 'flex-start' },
            gap: { xs: 4, md: 6 },
          }}
        >
          <DetailsForm
            onObservationsChange={onObservationsChange}
            shipping={shipping}
            onShippingChange={onShippingChange}
            onAddressChange={onAddressChange}
            address={address}
            onStoreChange={onStoreChange}
            store={store}
            paymentMethods={sellerInfo.paymentMethods}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            onPaymentChange={onPaymentChange}
            paymentTime={orderDetails.paymentTime}
            role={role}
            userCountry={companyInfo.country}
            clientAddresses={clientAddresses}
          />

          <OrderSummaryCard
            cart={cart}
            taxes={orderTaxes}
            total={orderDetails.total}
            deliveryCost={deliveryCost}
            isLimitedCustomer={isLimitedCustomer}
          />
        </Box>

        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'center', md: 'flex-end' },
            gap: 2,
          }}
        >
          <StyledTextButton
            variant='contained'
            color='secondary'
            onClick={() => setActiveStep(0)}
            title={t('checkout.goBackButton')}
          >
            {t('checkout.goBackButton')}
          </StyledTextButton>

          <LoadingButton
            variant='contained'
            type='submit'
            title={t('checkout.finalizeOrder')}
            disabled={
              (shipping === DELIVERY_METHODS.ADDRESS ||
                shipping === DELIVERY_METHODS.BILLING_ADDRESS) &&
              !!!address
                ? true
                : false
            }
            loading={isLoading}
            onClick={() => checkCartProducts('checkout')}
          >
            {t('checkout.finalizeOrder')}
          </LoadingButton>
        </Box>

        <PopupNotification
          notificationProps={notificationProps}
          setNotificationProps={setNotificationProps}
        />
      </Box>
    </>
  );
};

export default OrderDetails;
