const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const API_URL = `${REACT_APP_API_URL}/store`;
const PLACEHOLDER_IMAGE = `${
  process.env.NODE_ENV === 'production' ? process.env.REACT_APP_WEBSITE_URL : process.env.PUBLIC_URL
}/default_image.png`;
const NOT_FOUND_ROUTE = 'pagina-nao-encontrada';

//GOOGLE CONFIG
const RECAPTCHA_SITEKEY = process.env.REACT_APP_GOOGLE_SITEKEY;
const RECAPTCHA_PRIVATE = process.env.REACT_APP_RECAPTCHA_PRIVATE;

//  CUSTOM COLORS
const CUSTOM_THEME_COLORS = {
  primary: '#376fa4',
  lightPrimary: '#6489AC',
  gray: '#DDDDDD',
  lightGray: '#f7f7f7',
  darkGray: '#383838',
  red: '#9A031E',
  green: '#AFBE8F',
  lightGreen: '#4FD290',
  yellow: '#FFC01D',
  orange: '#ffa726',
};

//  FONT STYLES
const FONT_STYLE = {
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
  black: 900,
};

//  STYLES DEFAULTS
const TRANSITION_DURATION = 200;

// info
const COMPANY_NAME = 'Geonatlife';
const COMPANY_INFO = {
  name: 'Geonatlife',
  address: {
    street: 'Parque Empresarial Vialonga - Granja, 25 Casal do Bagulho - Granja de Alpriate',
    zipCode: '2625-607 Vialonga',
    city: 'Portugal',
  },
  phone: {
    label: '(+351) 914 936 495',
    value: '+351914936495',
  },
  mobile: {
    label: '',
    value: '',
  },
  fax: {
    label: '',
    value: '',
  },
  email: 'info@geonatlife.com',
  social: {
    linkedin: '',
    facebook: 'https://www.facebook.com/geonatlife',
    instagram: 'https://www.instagram.com/geonatlife/',
  },
  nif: '510534880',
  mapsLink: 'https://maps.app.goo.gl/AQyDKviyeB3VF5JA6',
};

const SETTINGS = {
  functionalities: {
    myAccount: true,
    shoppingList: true,
    hidePrices: true,
    variantsModal: false,
  },
  defaults: {
    language: 'pt',
    quantityType: 'unit',
    productList: {
      sortBy: 'name:asc',
      itemsPerPage: 24,
    },
  },
};

const PRODUCT_INFO_TABS = ['product.technicalDetails'];
// const PRODUCT_INFO_TABS = ['product.technicalDetails', 'product.video', 'product.downloads'];

// breakpoints
const SMALL_DEVICES_BREAKPOINT = '600px';
const MEDIUM_DEVICES_BREAKPOINT = '900px';
const LARGE_DEVICES_BREAKPOINT = '1200px';
const XLARGE_DEVICES_BREAKPOINT = '1536px';

//  PAGES SLUGS
const PAGES_SLUGS = {
  search: '/pesquisa',
  allProducts: '/produtos',
  budgetRequest: '/pedido-orcamento',
  news: '/novidades',
  promotions: '/promocoes',
  brands: '/marcas',
  contacts: '/contactos',
  myProducts: '/meus-produtos',
  cart: '/carrinho',
  account: '/conta',
  login: '/login',
  productDetail: '/produto',
  recoverPassword: 'recuperar-palavra-passe',
  createUser: 'registo-utilizador',
  //  begin collections pages
  collections: {
    airolube: '/airolube',
    less: '/less',
    riskless: '/riskless',
    panzer: '/panzer',
    sponser: '/sponser',
    idp: '/idp',
    tagMetals: '/tag-metals',
    royal: '/royal',
    esigrips: '/esigrips',
    kidsRideShotgun: '/kids-ride-shotgun',
  },
  //  end collections pages
  institutional: {
    aboutUs: 'quem-somos',
    termsAndConditions: 'termos-e-condicoes',
    privacyPolicy: 'politica-de-privacidade',
    refundPolicy: 'politica-reembolso',
    shippingPolicy: 'politica-envio',
    alternativeDisputeResolution: 'resolucao-alternativa-de-litigios',
    faqs: 'faqs',
  },
  myAccount: {
    info: `dados-pessoais`,
    accessData: 'dados-acesso',
    receipts: 'conta-corrente',
    shoppingList: 'lista-compras',
    orders: 'encomendas',
    productsHistory: 'visualizacoes',
    users: 'utilizadores',
    addresses: 'moradas',
  },
};

const ACCOUNT_LINKS = {
  customer: [
    {
      title: 'myAccount.personalData.title',
      url: PAGES_SLUGS.myAccount.info,
      userMenu: true,
    },
    {
      title: 'myAccount.accessData.title',
      url: PAGES_SLUGS.myAccount.accessData,
      userMenu: false,
    },
    {
      title: 'myAccount.addresses.title',
      url: PAGES_SLUGS.myAccount.addresses,
      userMenu: true,
    },
    // {
    //   title: 'myAccount.users.title',
    //   url: PAGES_SLUGS.myAccount.users,
    //   userMenu: true,
    // },
    {
      title: 'myAccount.orders.title',
      url: PAGES_SLUGS.myAccount.orders,
      userMenu: true,
    },
    {
      title: 'myAccount.receipts.title',
      url: PAGES_SLUGS.myAccount.receipts,
      userMenu: true,
    },
    {
      title: 'myAccount.shoppingLists.title',
      url: PAGES_SLUGS.myAccount.shoppingList,
      userMenu: false,
    },
    {
      title: 'myAccount.productHistory.title',
      url: PAGES_SLUGS.myAccount.productsHistory,
      userMenu: false,
    },
  ],
  seller: [
    {
      title: 'myAccount.personalData.title',
      url: PAGES_SLUGS.myAccount.info,
      userMenu: true,
    },
    {
      title: 'myAccount.accessData.title',
      url: PAGES_SLUGS.myAccount.accessData,
      userMenu: false,
    },
    {
      title: 'myAccount.orders.title',
      url: PAGES_SLUGS.myAccount.orders,
      userMenu: true,
    },
    {
      title: 'myAccount.receipts.title',
      url: PAGES_SLUGS.myAccount.receipts,
      userMenu: true,
    },
    {
      title: 'myAccount.shoppingLists.title',
      url: PAGES_SLUGS.myAccount.shoppingList,
      userMenu: true,
    },
    {
      title: 'myAccount.productHistory.title',
      url: PAGES_SLUGS.myAccount.productsHistory,
      userMenu: true,
    },
  ],
  limitedcustomer: [
    {
      title: 'myAccount.personalData.title',
      url: PAGES_SLUGS.myAccount.info,
      userMenu: true,
    },
    {
      title: 'myAccount.accessData.title',
      url: PAGES_SLUGS.myAccount.accessData,
      userMenu: true,
    },
    {
      title: 'myAccount.orders.title',
      url: PAGES_SLUGS.myAccount.orders,
      userMenu: true,
    },
    {
      title: 'myAccount.shoppingLists.title',
      url: PAGES_SLUGS.myAccount.shoppingList,
      userMenu: true,
    },
  ],
};

// navbar
const NAVBAR_LINKS = [
  {
    title: 'pages.allBrands',
    url: PAGES_SLUGS.collections.airolube,
    highlighted: false,
    categoryButton: true,
    menuMobile: false,
  },
  {
    title: 'pages.aboutUs',
    url: PAGES_SLUGS.institutional.aboutUs,
    highlighted: false,
    categoryButton: false,
    menuMobile: true,
  },
  {
    title: 'pages.contacts',
    url: PAGES_SLUGS.contacts,
    highlighted: false,
    categoryButton: false,
    menuMobile: true,
  },
];

// FOOTER MENUS
const FOOTER_MENUS = {
  menu1: [
    {
      title: 'pages.aboutUs',
      url: PAGES_SLUGS.institutional.aboutUs,
      internalLink: true,
      target: '',
    },
    {
      title: 'pages.brands',
      url: PAGES_SLUGS.brands,
      internalLink: true,
      target: '',
    },
    {
      title: 'pages.contacts',
      url: PAGES_SLUGS.contacts,
      internalLink: true,
      target: '',
    },
  ],
  menu2: [
    {
      title: 'pages.termsAndConditions',
      url: PAGES_SLUGS.institutional.termsAndConditions,
      internalLink: true,
      target: '',
    },
    {
      title: 'pages.privacyPolicy',
      url: PAGES_SLUGS.institutional.privacyPolicy,
      internalLink: true,
      target: '',
    },
    {
      title: 'pages.refundPolicy',
      url: PAGES_SLUGS.institutional.refundPolicy,
      internalLink: true,
      target: '',
    },
    {
      title: 'pages.shippingPolicy',
      url: PAGES_SLUGS.institutional.shippingPolicy,
      internalLink: true,
      target: '',
    },
    {
      title: 'pages.alternativeDisputeResolution',
      url: PAGES_SLUGS.institutional.alternativeDisputeResolution,
      internalLink: true,
      target: '',
    },
    {
      title: 'pages.complaintBook',
      url: 'https://www.livroreclamacoes.pt/Inicio/',
      internalLink: false,
      target: '_blank',
    },
  ],
};

const DELIVERY_METHODS = {
  STORE: 'deliveryAtStore',
  ADDRESS: 'deliveryAtAddress',
  // BILLING_ADDRESS: 'billingAddress',
};

const PAYMENT_METHODS = {
  CREDIT_CARD: 'creditCard',
  MULTIBANCO: 'multibanco',
  //MBWAY: 'mbWay',
  PAYPAL: 'paypal',
  CASH_ON_DELIVERY: 'cashOnDelivery',
};

export {
  ACCOUNT_LINKS,
  API_URL,
  COMPANY_INFO,
  COMPANY_NAME,
  CUSTOM_THEME_COLORS,
  DELIVERY_METHODS,
  FONT_STYLE,
  FOOTER_MENUS,
  LARGE_DEVICES_BREAKPOINT,
  MEDIUM_DEVICES_BREAKPOINT,
  NAVBAR_LINKS,
  NOT_FOUND_ROUTE,
  PAGES_SLUGS,
  PAYMENT_METHODS,
  PLACEHOLDER_IMAGE,
  PRODUCT_INFO_TABS,
  SETTINGS,
  SMALL_DEVICES_BREAKPOINT,
  TRANSITION_DURATION,
  XLARGE_DEVICES_BREAKPOINT,
  RECAPTCHA_SITEKEY,
  RECAPTCHA_PRIVATE,
};
