import React, {useEffect, useState} from 'react';
import {Typography} from '@mui/material';
import {usePayment} from '../../../contexts/PaymentContext';
import {useTranslation} from 'react-i18next';

const MBWayPayment = ({paymentData}) => {
    const {setLoading, error, setError, phoneNumber, setPaymentData, processPayment} =
        usePayment();
    const [paymentProcessed, setPaymentProcessed] = useState(false);
    const {t} = useTranslation();
    console.log('PaymentData ', paymentData);
    useEffect(() => {
        const handlePayment = async () => {
            try {
                setLoading(true);
                setError(null);
                const response = await processPayment(phoneNumber);
                if (response) {
                    setError(false);
                    setPaymentData(response);
                }
                setError(null);
                setPaymentProcessed(true);
            } catch (err) {
                setError(` ${t('beMessages.paymentError')} ${err}`);
            } finally {
                setLoading(false);
            }
        };
        if (!paymentProcessed && !paymentData) handlePayment();
    }, [
        paymentData,
        paymentProcessed,
        phoneNumber,
        processPayment,
        setError,
        setLoading,
        setPaymentData,
        t,
    ]);

    if (error) return <Typography color='error'>{error}</Typography>;

    if (paymentData) {
        return (
            <div className="container">
                <div className="order-details">
                    <h2>Detalhes da ordem:</h2>
                    <p className="amount">Valor: {paymentData.data.newPayment.amount} EUR</p>
                    <p className="instructions">
                        <b>Receberá uma notificação na aplicação MB WAY para que confirme o pagamento utilizando o seu
                            PIN MB
                            WAY.</b>
                        <br></br>
                        Após completar esta confirmação, o pagamento está concluído.
                    </p>

                </div>
            </div>
        );
    } else {
        return (
            <Typography variant="h6">
                {t('beMessages.paymentError')}
            </Typography>)

    }
};

export default MBWayPayment;
