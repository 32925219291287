import { Box, Link, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons';
import { FiChevronRight } from 'react-icons/fi';
import store_location from '../assets/company-location.png';
import { COMPANY_INFO, FONT_STYLE } from '../variables';

const StoreLocations = () => {
  const { t } = useTranslation();
  const {
    name,
    address: { street, zipCode, city },
    mobile,
    phone,
    fax,
    email,
    mapsLink,
  } = COMPANY_INFO;

  return (
    <Grid container>
      <Grid
        xs={12}
        md={5}
      >
        <Typography sx={{ marginTop: { xs: 2, md: 3 } }}>
          {street} <br /> {zipCode}
          <br />
          {city}
        </Typography>

        {mobile.value && (
          <Box sx={{ marginTop: 1.5 }}>
            <Typography
              component={'a'}
              href={`tel:${mobile.value}`}
              title={mobile.label}
            >
              <strong>Tel. </strong>
              {mobile.label}
            </Typography>
          </Box>
        )}

        {phone.value && (
          <Box sx={{ marginTop: 1.5 }}>
            <Typography
              component={'a'}
              href={`tel:${phone.value}`}
              title={t('app.general')}
            >
              <strong>{t('app.general')}: </strong>
              {phone.label}
            </Typography>
            <Typography variant='small'>{t('app.nationalPhoneNetwork')}</Typography>
          </Box>
        )}

        <Box sx={{ marginTop: 1.5 }}>
          <Typography
            component={'a'}
            href={`tel:+34686048186`}
            title={t('app.spain')}
          >
            <strong>{t('app.spain')}: </strong>
            (+34) 686 04 81 86
          </Typography>
          <Typography variant='small'>{t('app.nationalPhoneNetwork')}</Typography>
        </Box>

        {fax.value && (
          <Typography marginTop={1.5}>
            <strong>Fax: </strong>
            {fax.label}
          </Typography>
        )}

        {email && (
          <Typography
            component={'a'}
            href={`mailto:${email}`}
            title={email}
            sx={{
              display: 'block',
              marginTop: 1.5,
            }}
          >
            <strong>E-mail: </strong>
            {email}
          </Typography>
        )}
        <Typography
          component={'a'}
          href={mapsLink}
          target='_blank'
          rel='noreferrer'
          title={t('contacts.getDirections')}
          sx={{
            display: 'block',
            fontWeight: FONT_STYLE.bold,
            marginTop: 3,
          }}
        >
          {t('contacts.getDirections')}
          <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
            <FiChevronRight />
          </IconContext.Provider>
        </Typography>
      </Grid>
      <Grid
        md={6}
        mdOffset={1}
        sx={{
          display: { xs: 'none', md: 'initial' },
        }}
      >
        <Link
          href={mapsLink}
          target='_blank'
          rel='nofollow'
          sx={{ flex: 1 }}
        >
          <img
            src={store_location}
            width='100%'
            alt={t('contacts.companyLocalization', { company: name })}
          />
        </Link>
      </Grid>
    </Grid>
  );
};

export default StoreLocations;
