import { Autocomplete, Box, Container, Divider, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCart, getShoppingLists, setCustomer } from '../../api/user';
import { setCartState } from '../../state/cartSlice';
import { setShoppingLists } from '../../state/shoppingListsSlice';
import { setSelectedClient } from '../../state/userSlice';
import { CUSTOM_THEME_COLORS, FONT_STYLE } from '../../variables';
import PopupNotification from '../PopupNotification/PopupNotification';

const ClientSelectorSelect = styled(Autocomplete)(() => ({
  [`.MuiInputBase-root`]: {
    fontSize: '0.9em',
  },
  [`.MuiAutocomplete-option`]: {
    fontSize: '0.9em',
  },
}));

const ClientSelector = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    selectedClient,
    sellerInfo: { clients },
  } = useSelector((state) => state.user);
  const { cart_id, id: selectedClientId } = selectedClient;
  const [clientId, setClientId] = useState(selectedClientId);

  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    errorMessage: t('clientSelector.errorChangingClient'),
  });

  useEffect(() => {
    selectedClientId !== clientId &&
      setCustomer({ customerId: selectedClientId })
        .then(() => {
          setClientId(selectedClientId);

          //  get cart items
          return getCart(cart_id)
            .then(({ _id, cart_items, total_discount, discounts, taxes, subtotal, total }) => {
              dispatch(
                setCartState({
                  id: _id,
                  cart: cart_items,
                  discount: total_discount,
                  discounts: discounts,
                  taxes: taxes,
                  subtotal: subtotal,
                  total: total,
                })
              );

              //  get shipping lists
              return getShoppingLists()
                .then((lists) => {
                  dispatch(setShoppingLists(lists));

                  setNotificationProps((prevState) => ({
                    ...prevState,
                    isOpened: true,
                    type: 'success',
                    successMessage: t('clientSelector.clientChangedSuccess'),
                  }));
                })
                .catch(({ response: { status } }) => {
                  if (status === 401) navigate('/login');
                });
            })
            .catch(({ response: { status } }) => {
              if (status === 401) navigate('/login');
            });
        })
        .catch(({ response: { status } }) => {
          if (status === 422)
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
            }));
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, cart_id, dispatch]);

  return (
    <>
      <Box
        paddingY={1.5}
        sx={{
          backgroundColor: CUSTOM_THEME_COLORS.lightGray,
        }}
      >
        <Container>
          <Box
            sx={{
              display: 'flex',
              gap: { xs: 0.5, md: 2 },
              alignItems: 'center',
              justifyContent: 'end',
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                color: CUSTOM_THEME_COLORS.darkGray,
              }}
            >
              <Typography variant='small'>{t('app.yourProfile')}:</Typography>
              <Typography
                variant='small'
                sx={{ fontWeight: FONT_STYLE.medium }}
              >
                {t('app.seller')}
              </Typography>
            </Box>

            {!!clients.length && (
              <>
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{ borderColor: CUSTOM_THEME_COLORS.darkGray }}
                />

                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    color: CUSTOM_THEME_COLORS.darkGray,
                  }}
                >
                  <Typography variant='small'>{t('app.yourClient')}:</Typography>

                  <ClientSelectorSelect
                    disablePortal
                    id='client-selection'
                    options={clients}
                    disableClearable={true}
                    value={selectedClient}
                    onChange={(event, newValue) => dispatch(setSelectedClient(newValue?.id))}
                    getOptionLabel={(option) =>
                      option?.companyInfo?.name || option?.personalInfo?.email
                    }
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={params?.id}
                        sx={{
                          marginTop: 0,
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <Box
                        {...props}
                        component='li'
                        key={`client-option-${option.id}`}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <Typography fontSize='0.95rem'>
                          {option?.companyInfo?.name || option?.personalInfo?.email}
                        </Typography>
                        {option?.user_role === 'LimitedCustomer' && (
                          <Typography variant='xsmall'>({option?.personalInfo?.email})</Typography>
                        )}
                      </Box>
                    )}
                    size='small'
                    sx={{
                      width: 250,
                      '& + .MuiAutocomplete-popper .MuiAutocomplete-listbox': {
                        paddingTop: 0,
                        paddingBottom: 0,
                        '[aria-selected=true]': {
                          pointerEvents: 'none',
                        },
                      },
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
        </Container>
      </Box>
      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </>
  );
};

export default ClientSelector;
