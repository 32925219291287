import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import React from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FONT_STYLE } from '../../../variables';

const ProductDetailsTable = ({ params }) => {
  const { t } = useTranslation();
  const [cookie] = useCookies();
  const translations = useSelector((state) => state.translations);

  //  translate 'title & value' params
  params = params.map((param) =>
    translations.reduce((acc, translation) => {
      translation.id === param.id && (acc.title = translation[cookie.language]);
      translation.id === param.value &&
        (acc.value = translation[cookie.language]);
      return { ...param, ...acc };
    }, {})
  );
  return (
    <TableContainer
      component={Paper}
      sx={{
        borderTopLeftRadius: 0,
      }}
    >
      <Table>
        <TableBody>
          {params.length !== 0 ? (
            params.map(({ title, value }, index) => (
              <TableRow
                key={`technical-detail-${index}`}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell
                  sx={{
                    width: '50%',
                    paddingX: 4,
                    textAlign: 'left !important',
                  }}
                >
                  {title}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: FONT_STYLE.bold,
                    paddingX: 4,
                    textAlign: 'left !important',
                  }}
                >
                  {value}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                sx={{
                  padding: 4,
                  textAlign: 'left !important',
                }}
              >
                {t('product.noTechnicalDetailsAvailable')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductDetailsTable;
