export * from './slugify';
export * from './hooks/index';
export * from './defaultImage';
export * from './splitString';
export * from './objectIsEmpty';
export * from './compare';

export const generateRandomStringAndNumbers = (length) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const generateRandomPrice = () => {
  const precision = 100; // 2 decimals
  const randomPrice =
    Math.floor(
      Math.random() * (75 * precision - 1 * precision) + 1 * precision
    ) /
    (1 * precision);
  return randomPrice;
};

export const postalCodePatternIsValid = (postalCode) => {
  const postalCodePatternFormat = /[0-9]{4}-[0-9]{3}$/;
  const postalCodePatternIsValid = postalCodePatternFormat.test(postalCode);
  return postalCodePatternIsValid;
};

export const getCookie = (name) => {
  let value = `; ${document.cookie}`;
  let parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};
