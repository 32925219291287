import React, { useEffect, useState } from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import { usePayment } from '../../../contexts/PaymentContext';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CreditCardPayment = ({ paymentData }) => {
  const { processPayment, error, setError, loading, setLoading } = usePayment();
  const { t } = useTranslation();
  const [paymentProcessed, setPaymentProcessed] = useState(false);

  useEffect(() => {
    const handlePayment = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await processPayment();
        if (response) {
          setLoading(false);
        }
        setPaymentProcessed(true);
      } catch (err) {
        setError(` ${t('beMessages.paymentError')} ${err}`);
      }
    };
    if (!paymentProcessed && !paymentData) {
      handlePayment();
    }
  }, [paymentData, paymentProcessed, processPayment, setError, setLoading, t]);

  if (loading) return <CircularProgress size={80} sx={{ color: 'red' }} />;
  if (error) return <Typography color='error'>{error}</Typography>;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
    >
      {error && <Typography color='error'>{error}</Typography>}

      {paymentData && (
        <>
          <Typography>{t('orderConfirmation.paymentAdvice')}</Typography>
          <Box
            sx={{
              backgroundColor: '#ffffff',
              borderRadius: 8,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              padding: '20px',
              margin: '20px 0 20px 0',
            }}
          >
            <Typography variant='h3'>
              {t('orderConfirmation.paymentDetails')}
            </Typography>
            <Typography>{t('orderConfirmation.waitingPayment')}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Typography sx={{ marginRight: 0.5 }}>
              {t('orderConfirmation.creditCardPaymentInfoLink')}
            </Typography>
            <Link to={paymentData.data.PaymentUrl}>
              <Typography
                sx={{
                  color: 'blue',
                }}
              >
                {t('orderConfirmation.paymentLinkRedirect')}
              </Typography>
            </Link>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CreditCardPayment;
