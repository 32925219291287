import {
  // IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
// import { BiPrinter } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../constants/utils';
// import generatePDF from '../../../helpers/generatePDF';

const ReceiptsTable = ({ receipts }) => {
  const { t } = useTranslation();
  return (
    <TableContainer
      component={Paper}
      sx={{ marginY: 2 }}
    >
      <Table
        size='small'
        id='receipts-table'
      >
        <TableHead>
          <TableRow>
            <TableCell>{t('tables.number')}</TableCell>
            <TableCell>{t('tables.date')}</TableCell>
            <TableCell>{t('tables.status')}</TableCell>
            <TableCell>{t('tables.dueDate')}</TableCell>
            <TableCell>{t('tables.total')}</TableCell>
            <TableCell>{t('tables.unpaid')}</TableCell>
            {/* <TableCell> </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {receipts.map(
            ({ reference, date, dueDate, status, value, balance }) => {
              const docDate = new Date(date).toLocaleDateString('pt-PT');
              const docDueDate = new Date(dueDate).toLocaleDateString('pt-PT');
              return (
                <TableRow
                  key={`receiptsTable-${reference}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{reference}</TableCell>
                  <TableCell>{docDate}</TableCell>
                  <TableCell>{status}</TableCell>
                  <TableCell>{docDueDate}</TableCell>
                  <TableCell>{formatCurrency(value)}</TableCell>
                  <TableCell>{formatCurrency(balance)}</TableCell>
                  {/* <TableCell>
                  <IconButton
                    size='small'
                    title='Exportar em PDF'
                    onClick={() => generatePDF(reference, formatCurrency(total), date, items)}
                  >
                    <BiPrinter />
                  </IconButton>
                </TableCell> */}
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReceiptsTable;
