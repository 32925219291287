import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { VscChevronRight } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { CUSTOM_THEME_COLORS, FONT_STYLE, PAGES_SLUGS } from '../../../variables';
import Modal, { ModalActions, ModalContent, ModalTitle } from '../../Modal';
import SummaryProductInfo from '../FirstStepSummary/SummaryTable/SummaryProductInfo';

const CheckCartProducts = ({ updatedProducts, setActiveStep, modalIsOpen, setModalIsOpen }) => {
  const { t } = useTranslation();

  const ProductsList = ({ products, hideQuantity }) => {
    return products.map(
      ({ title, brand, reference, quantity, images, itemsPerBox, stock }, index) => (
        <Box
          key={`updatedProducts-${reference}-${index}`}
          sx={{
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 2,
          }}
        >
          <SummaryProductInfo
            images={images}
            title={title}
            reference={reference}
            brand={brand}
            itemsPerBox={itemsPerBox}
            showStockAvailability
            stock={stock}
          />
          {!hideQuantity && (
            <Box
              sx={{
                display: 'grid',
                justifyItems: 'center',
                gap: 1,
              }}
            >
              <Typography sx={{ fontSize: '0.95rem', fontWeight: FONT_STYLE.bold }}>
                {t('tables.quantity')}
              </Typography>
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                  fontWeight: FONT_STYLE.bold,
                }}
              >
                <Box
                  component='span'
                  color={CUSTOM_THEME_COLORS.red}
                >
                  {quantity.unit}
                </Box>
                <VscChevronRight size={18} />
                <Box
                  component='span'
                  color={CUSTOM_THEME_COLORS.green}
                >
                  {stock}
                </Box>
              </Typography>
            </Box>
          )}
        </Box>
      )
    );
  };

  return (
    <Modal
      isOpened={modalIsOpen}
      setIsOpened={setModalIsOpen}
      hideCloseButton
      muiComponentNativeProps={{
        PaperProps: {
          sx: {
            width: '100%',
            padding: 4,
          },
        },
      }}
    >
      <ModalTitle>{t('checkout.cartUpdated.title')}</ModalTitle>
      <ModalContent
        sx={{
          display: 'grid',
          gap: 2,
          marginBottom: 0,
        }}
      >
        {!!updatedProducts.stock.length && (
          <Box>
            <Typography>
              {t('checkout.cartUpdated.stockUpdated', { count: updatedProducts.stock.length })}
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gap: 1,
                marginTop: 2,
              }}
            >
              <ProductsList products={updatedProducts.stock} />
            </Box>
          </Box>
        )}
        {(!!updatedProducts.availability.length || !!updatedProducts.outOfStock.length) && (
          <Box>
            <Typography>
              {t('checkout.cartUpdated.productRemoved', {
                count: updatedProducts.availability.length + updatedProducts.outOfStock.length,
              })}
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gap: 1,
                marginTop: 2,
              }}
            >
              <ProductsList
                products={[...updatedProducts.outOfStock, ...updatedProducts.availability]}
                hideQuantity
              />
            </Box>
          </Box>
        )}
      </ModalContent>
      <ModalActions>
        <Button
          title={t('checkout.cartUpdated.backToCart')}
          variant='contained'
          component={Link}
          to={PAGES_SLUGS.cart}
          onClick={() => {
            setModalIsOpen(false);
            setActiveStep(0);
          }}
        >
          {t('checkout.cartUpdated.backToCart')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default CheckCartProducts;
