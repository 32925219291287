import axios from 'axios';
import { Cookies } from 'react-cookie';
import { API_URL } from '../variables';

const cookie = new Cookies();

function getHeaders() {
  return {
    headers: {
      authorization: cookie.get('userToken'),
    },
  };
}

// POST

async function login(userData) {
  return axios.post(`${API_URL}/login`, userData).then(({ data }) => ({
    ...data,
    user: { ...data.user, user_role: data.user.user_role.toLowerCase() },
  }));
}

async function registerUser(userData) {
  return axios.post(`${API_URL}/register`, userData).then(({ data }) => data);
}

async function recoverPassword(recoveryEmail, lang) {
  return axios
    .post(`${API_URL}/forgot-password`, { email: recoveryEmail, lang: lang })
    .then(({ data }) => data);
}

async function logout() {
  return axios.post(`${API_URL}/logout`, {}, getHeaders()).then(({ data }) => data);
}

async function changeLogin(userData) {
  const { email, oldPassword, newPassword: password, confirmPassword: passwordConfirm } = userData;

  return axios
    .post(
      `${API_URL}/user/update/login`,
      { email, oldPassword, password, passwordConfirm },
      getHeaders()
    )
    .then(({ data }) => data);
}

export { getHeaders, login, registerUser, recoverPassword, logout, changeLogin };
