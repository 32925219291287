import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPage } from '../../api';
import Faqs from '../../routes/Faqs';
import InstitucionalPage from '../../routes/InstitucionalPage';
import TermsPage from '../../routes/TermsPage';
import { NOT_FOUND_ROUTE, PAGES_SLUGS } from '../../variables';
import Loading from '../Loading';

const InstitucionalPages = () => {
  const [cookie] = useCookies();
  const location = useLocation();
  const slugParam = location.pathname.slice(1);
  const navigate = useNavigate();
  const [page, setPage] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const slugs = {
    aboutUs: {
      backofficeSlug: 'quemsomos',
      finalSlug: PAGES_SLUGS.institutional.aboutUs,
    },
    termsAndConditions: {
      backofficeSlug: 'termos',
      finalSlug: PAGES_SLUGS.institutional.termsAndConditions,
    },
    privacyPolicy: {
      backofficeSlug: 'politica',
      finalSlug: PAGES_SLUGS.institutional.privacyPolicy,
    },
    refundPolicy: {
      backofficeSlug: 'reembolso',
      finalSlug: PAGES_SLUGS.institutional.refundPolicy,
    },
    shippingPolicy: {
      backofficeSlug: 'envio',
      finalSlug: PAGES_SLUGS.institutional.shippingPolicy,
    },
    alternativeDisputeResolution: {
      backofficeSlug: 'litigios',
      finalSlug: PAGES_SLUGS.institutional.alternativeDisputeResolution,
    },
    faqs: {
      backofficeSlug: '',
      finalSlug: PAGES_SLUGS.institutional.faqs,
    },
  };

  //  GET 'backofficeSlug' through 'finalSlug' key
  const slugPageBo = Object.values(slugs).find(
    (slug) => slug.finalSlug === slugParam
  ).backofficeSlug;

  useEffect(() => {
    //  IF 'backofficeSlug' key is empty » page does not exist in BO
    if (!!!slugPageBo) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);

    //  GET BO existing page
    getPage(slugPageBo)
      .then((pageContent) => {
        setPage(pageContent);
        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        setIsLoading(false);
        // If page wasn't created in backoffce redirect as it makes no sense to
        // show a page without information to display
        if (status === 422) navigate(`/${NOT_FOUND_ROUTE}`);
      });
  }, [slugPageBo, navigate]);

  if (isLoading) {
    return <Loading />;
  } else {
    switch (slugParam) {
      case slugs.aboutUs.finalSlug:
        return (
          <InstitucionalPage
            props={page}
            language={cookie.language}
          />
        );
      case slugs.termsAndConditions.finalSlug:
      case slugs.privacyPolicy.finalSlug:
      case slugs.shippingPolicy.finalSlug:
      case slugs.refundPolicy.finalSlug:
      case slugs.alternativeDisputeResolution.finalSlug:
        return (
          <TermsPage
            props={page}
            language={cookie.language}
          />
        );
      case slugs.faqs.finalSlug:
        return <Faqs />;
      default:
        break;
    }
  }
};

export default InstitucionalPages;
