import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getCookie } from './helpers';
import en from './translations/en.json';
import es from './translations/es.json';
import pt from './translations/pt.json';
import { SETTINGS } from './variables';

//  get 'language' cookie
const languageCookie = getCookie('language');

let initLocale = undefined;
// language automatic selection
if (!!languageCookie) {
  initLocale = languageCookie;
} else {
  if (navigator.language === 'en-US') {
    initLocale = 'en';
  } else if (navigator.language === 'es') {
    initLocale = 'es';
  } else {
    initLocale = SETTINGS.defaults.language;
  }
  //  create new cookie
  const expiresInSeconds = parseInt(60) * 24 * 60 * 60; //  expires in 60 days
  document.cookie = `language=${initLocale}; path=/; max-age=${expiresInSeconds}; Secure; sameSite=strict`;
}

i18n.use(initReactI18next).init({
  resources: {
    pt: { translation: { ...pt } },
    en: { translation: { ...en } },
    es: { translation: { ...es } },
  }, // Where we're gonna put translations' files
  lng: initLocale, // Set the initial language of the App
  supportedLngs: ['pt', 'en', 'es'],
  fallbackLng: false,
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  debug: false,
  // react i18next special options (optional)
  // override if needed - omit if ok with defaults
  /*
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    }
    */
});
