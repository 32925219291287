import {
  Box,
  Button,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiCheck, FiEdit2, FiPercent, FiTrash2 } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  removeProductFromCart,
  updateCartProducts,
} from '../../../../api/checkout';
import { formatCurrency } from '../../../../constants/utils';
import {
  setCart,
  setCartState,
  setCartSubtotal,
  setCartTaxes,
  setCartTotal,
  setDiscount,
} from '../../../../state/cartSlice';
import { BadgeDiscount, IconButton } from '../../../../styles';
import { CUSTOM_THEME_COLORS, FONT_STYLE } from '../../../../variables';
import Quantity from '../../../Quantity/Quantity';
import DiscountSelectorModal from '../DiscountSelectorModal';
import SummaryProductInfo from './SummaryProductInfo';

const SummaryTable = ({
  cartItems,
  cartTotalDiscount,
  cartSubtotal,
  selectedClient,
  role,
  setActiveStep,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showDiscountOptions = role === 'seller';
  const isLimitedCustomer = role === 'limitedcustomer';
  const showStockAvailability = true;

  const [isApplyDiscountOpen, setIsApplyDiscountOpen] = useState(false);
  const [iconButton, setIconButton] = useState(null);
  const [editDiscountToProduct, setEditDiscountToProduct] = useState();
  const [updatedProductQuantity, setUpdatedProductQuantity] =
    useState(undefined);

  useEffect(() => {
    if (!updatedProductQuantity) return;

    //  update product quantities of product changed
    const updatedProductsQuantities = cartItems.map((product) => ({
      ...product,
      quantity:
        product.reference === updatedProductQuantity.reference
          ? updatedProductQuantity.quantity
          : product.quantity,
    }));

    // updateCartProducts(updatedProductsQuantities, selectedClient.id)
    updateCartProducts(updatedProductsQuantities, selectedClient)
      .then(
        ({ cart_items, discounts, total_discount, taxes, subtotal, total }) => {
          dispatch(setCartState({ discounts: discounts }));
          dispatch(setCart(cart_items));
          dispatch(setDiscount(total_discount));
          dispatch(setCartTaxes(taxes));
          dispatch(setCartSubtotal(subtotal));
          dispatch(setCartTotal(total));
        }
      )
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedProductQuantity, dispatch]);

  const updateCartItemDiscount = (product, discount, priceWithDiscount) => {
    const productWithDiscount = [
      {
        ...product,
        discountPrice: discount !== 0 ? priceWithDiscount : 0,
        discount: discount,
      },
    ];

    // POST REQUEST » it will trigger a cart object change
    updateCartProducts(productWithDiscount, selectedClient)
      .then(
        ({ cart_items, discounts, total_discount, taxes, subtotal, total }) => {
          dispatch(setCartState({ discounts: discounts }));
          dispatch(setCart(cart_items));
          dispatch(setDiscount(total_discount));
          dispatch(setCartTaxes(taxes));
          dispatch(setCartSubtotal(subtotal));
          dispatch(setCartTotal(total));
        }
      )
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  };

  const deleteProductFromCart = (reference) => {
    removeProductFromCart(reference, selectedClient)
      .then(
        ({ cart_items, discounts, total_discount, taxes, subtotal, total }) => {
          dispatch(setCartState({ discounts: discounts }));
          dispatch(setCart(cart_items));
          dispatch(setDiscount(total_discount));
          dispatch(setCartTaxes(taxes));
          dispatch(setCartSubtotal(subtotal));
          dispatch(setCartTotal(total));
        }
      )
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  };

  const deleteAllProductsFromCart = () => {
    const productsQuantitiestoZero = cartItems.map((product) => ({
      ...product,
      quantity: {
        unit: 0,
        box: 0,
      },
    }));
    updateCartProducts(productsQuantitiestoZero, selectedClient)
      .then(
        ({ cart_items, discounts, total_discount, taxes, subtotal, total }) => {
          dispatch(setCartState({ discounts: discounts }));
          dispatch(setCart(cart_items));
          dispatch(setDiscount(total_discount));
          dispatch(setCartTaxes(taxes));
          dispatch(setCartSubtotal(subtotal));
          dispatch(setCartTotal(total));
        }
      )
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ marginY: 1 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: { xs: '275px', md: 'initial' } }}>
                {t('tables.details')}
              </TableCell>
              <TableCell sx={{ minWidth: { xs: '140px', md: 'initial' } }}>
                {t('tables.quantity')}
              </TableCell>
              {!isLimitedCustomer && (
                <>
                  <TableCell
                    sx={{
                      minWidth: { xs: '150px', md: 'initial' },
                    }}
                  >
                    {t('tables.price')}*
                  </TableCell>
                  {showDiscountOptions && (
                    <TableCell
                      sx={{ minWidth: { xs: '240px', md: 'initial' } }}
                    >
                      {t('tables.sellerDiscount')}
                    </TableCell>
                  )}
                  <TableCell
                    sx={{
                      minWidth: { xs: '100px', md: 'initial' },
                    }}
                  >
                    {t('tables.subtotal')}*
                  </TableCell>
                </>
              )}
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {Array.isArray(cartItems) &&
              cartItems.map((product, index) => {
                const {
                  slug,
                  title,
                  brand,
                  reference,
                  price,
                  salePrice,
                  onSale,
                  onSaleBox,
                  boxPrice,
                  boxSalePrice,
                  saleAmount,
                  quantity,
                  images,
                  discount,
                  itemsPerBox,
                  stockPerBox,
                  stock,
                } = product;
                const pricePerUnit = quantity.unit //  unit price
                  ? onSale
                    ? salePrice
                    : price
                  : onSaleBox //  box price
                  ? boxSalePrice
                  : boxPrice;
                const productOnSale = onSale || onSaleBox;
                const productPrice = quantity.unit ? price : boxPrice;
                const productSalePrice = quantity.unit
                  ? salePrice
                  : boxSalePrice;
                const hasDiscount = discount > 0;
                const quantityAmount = quantity.unit + quantity.box;
                const subtotal = pricePerUnit * quantityAmount;
                const subtotalWithDiscount = parseFloat(
                  Math.fround(
                    pricePerUnit * quantityAmount -
                      (pricePerUnit * quantityAmount * discount) / 100
                  ).toFixed(2)
                );
                const productStock = itemsPerBox ? stockPerBox : stock;

                return (
                  <TableRow
                    key={`cart-item-${reference}-${index}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {/* designation */}
                    <TableCell>
                      <SummaryProductInfo
                        slug={slug}
                        images={images}
                        title={title}
                        reference={reference}
                        brand={brand}
                        itemsPerBox={itemsPerBox}
                        showStockAvailability={showStockAvailability}
                        stock={productStock}
                      />
                    </TableCell>

                    {/* quantity */}
                    <TableCell>
                      <Quantity
                        product={product}
                        setUpdatedProductQuantity={setUpdatedProductQuantity}
                      />
                    </TableCell>
                    {!isLimitedCustomer && (
                      <>
                        {/* your price */}
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              gap: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                textDecoration: productOnSale && 'line-through',
                              }}
                            >
                              {formatCurrency(productPrice)}
                            </Typography>

                            {productOnSale && (
                              <>
                                <Typography
                                  sx={{
                                    fontWeight: FONT_STYLE.bold,
                                    color: CUSTOM_THEME_COLORS.primary,
                                  }}
                                >
                                  {formatCurrency(productSalePrice)}
                                </Typography>
                                <BadgeDiscount>{`${saleAmount}%`}</BadgeDiscount>
                              </>
                            )}
                          </Box>
                        </TableCell>

                        {/* seller discount */}
                        {showDiscountOptions && (
                          <TableCell>
                            <IconButton
                              title={
                                hasDiscount
                                  ? t('cart.editSellerDiscount')
                                  : t('cart.applySellerDiscount')
                              }
                              sx={{
                                gap: 1,
                                color: hasDiscount && CUSTOM_THEME_COLORS.red,
                                fontWeight: hasDiscount && 600,
                              }}
                              onMouseEnter={() => {
                                return hasDiscount && setIconButton(index);
                              }}
                              onMouseLeave={() => {
                                return hasDiscount && setIconButton(null);
                              }}
                              onClick={() => {
                                setEditDiscountToProduct(product);
                                setIsApplyDiscountOpen(true);
                              }}
                            >
                              <Icon
                                sx={{
                                  backgroundColor: CUSTOM_THEME_COLORS.gray,
                                  borderRadius: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                {hasDiscount ? (
                                  iconButton === index ? (
                                    <FiEdit2 size={12} />
                                  ) : (
                                    <FiCheck size={12} />
                                  )
                                ) : (
                                  <FiPercent size={12} />
                                )}
                              </Icon>
                              {hasDiscount
                                ? t('cart.sellerDiscountAmout', {
                                    amount: discount,
                                  })
                                : t('cart.applySellerDiscount')}
                            </IconButton>
                          </TableCell>
                        )}

                        {/* subtotal */}
                        <TableCell>
                          <Box
                            sx={{
                              flex: 1,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                textDecoration: hasDiscount && 'line-through',
                              }}
                            >
                              {formatCurrency(subtotal)}
                            </Typography>

                            {hasDiscount && (
                              <Typography
                                sx={{
                                  color: CUSTOM_THEME_COLORS.red,
                                  fontWeight: FONT_STYLE.bold,
                                }}
                              >
                                {formatCurrency(subtotalWithDiscount)}
                              </Typography>
                            )}
                          </Box>
                        </TableCell>
                      </>
                    )}

                    <TableCell>
                      <IconButton
                        onClick={() => deleteProductFromCart(reference)}
                        title={t('tables.actions.remove')}
                        sx={{
                          minWidth: 'initial',
                        }}
                      >
                        <FiTrash2 size={16} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          marginTop: 2,
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          // flexDirection: 'row',
        }}
      >
        <Box>
          <Button
            variant='contained'
            color='secondary'
            size='small'
            title={t('cart.clearCartButton')}
            sx={{
              borderColor: CUSTOM_THEME_COLORS.red,
              color: CUSTOM_THEME_COLORS.red,
              '&:hover': {
                backgroundColor: CUSTOM_THEME_COLORS.red,
              },
            }}
            onClick={() => deleteAllProductsFromCart()}
          >
            {t('cart.clearCartButton')}
          </Button>
        </Box>
        <Box
          sx={{
            display: 'grid',
            gap: 3,
          }}
        >
          {!isLimitedCustomer && (
            <>
              <Typography
                variant='xsmall'
                sx={{
                  textAlign: 'right',
                }}
              >
                {t('product.priceWithoutVat')}
              </Typography>

              <Box
                sx={{
                  display: 'grid',
                  gap: 1,
                }}
              >
                {!!cartTotalDiscount && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: { xs: 'column', sm: 'row' },
                      justifyContent: 'space-between',
                      gap: { xs: 0.5, sm: 2 },
                    }}
                  >
                    <Typography
                      sx={{
                        color: CUSTOM_THEME_COLORS.red,
                        fontWeight: 600,
                      }}
                    >
                      {t('cart.orderDiscounts')}
                    </Typography>
                    <Typography
                      sx={{
                        color: CUSTOM_THEME_COLORS.red,
                        fontWeight: 600,
                      }}
                    >
                      {formatCurrency(cartTotalDiscount)}
                    </Typography>
                  </Box>
                )}

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'space-between',
                    gap: { sm: 2 },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: FONT_STYLE.medium,
                    }}
                  >
                    {t('cart.orderTotal')}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '1.6rem',
                      fontWeight: FONT_STYLE.bold,
                      color: CUSTOM_THEME_COLORS.primary,
                    }}
                  >
                    {formatCurrency(cartSubtotal)}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'center', sm: 'flex-end' },
              gap: 2,
            }}
          >
            <Button
              variant='contained'
              color='secondary'
              href='/'
              title={t('cart.backToStoreButton')}
            >
              {t('cart.backToStoreButton')}
            </Button>

            <Button
              variant='contained'
              onClick={() => setActiveStep(1)}
              title={t('cart.goToCheckoutButton')}
            >
              {t('cart.goToCheckoutButton')}
            </Button>
          </Box>
        </Box>
      </Box>

      {isApplyDiscountOpen && (
        <DiscountSelectorModal
          isApplyDiscountOpen={isApplyDiscountOpen}
          setIsApplyDiscountOpen={setIsApplyDiscountOpen}
          updateCartItemDiscount={updateCartItemDiscount}
          product={editDiscountToProduct}
        />
      )}
    </>
  );
};

export default React.memo(SummaryTable);
