import { Box, Button, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  setCart,
  setCartState,
  setCartSubtotal,
  setCartTaxes,
  setCartTotal,
  setDiscount,
} from '../../src/state/cartSlice';
import { updateCartProducts } from '../api/checkout';
import { ifthenpayCallbackHandler, paypalCallbackHandler } from '../api/payment';
import { getCartUpdated } from '../api/user';
import CartProgressIndicator from '../components/Cart/CartProgressIndicator';
import CartSummary from '../components/Cart/FirstStepSummary/CartSummary';
import OrderConfirmation from '../components/Cart/OrderConfirmation';
import CheckCartProducts from '../components/Cart/SecondStepOrderDetails/CheckCartProducts';
import OrderDetails from '../components/Cart/SecondStepOrderDetails/OrderDetails';
import Loading from '../components/Loading';
import { PaymentProvider } from '../contexts/PaymentContext';
import { setQueryParams } from '../state/orderSlice';
import { MainContainer } from '../styles';

const CartPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, selectedClient, role } = useSelector((state) => state.user);
  const customerId = selectedClient?.id || id;
  const cart = useSelector((state) => state.cart);
  const { id: cartId, cart: cartItems, discount, subtotal } = cart;
  const orderState = useSelector((state) => state.order);
  const { _id: orderId, payment_status: orderStatus, payment_type: _paymentType } = orderState;

  //  check stock and availability of products added to cart » this is needed because of instability of products data
  const [updatedProducts, setUpdatedProducts] = useState({
    stock: [],
    outOfStock: [],
    availability: [],
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [handleConfirmation, setHandleConfirmation] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  function handleActiveStep(step) {
    const element = document.querySelector('.app');
    element.scrollTo({ top: 0, behavior: 'smooth' });
    setActiveStep(step);
  }
  const [searchParams] = useSearchParams();
  //  Paypal callback query params
  const paymentId = searchParams.get('paymentId');
  const token = searchParams.get('token');
  const payerId = searchParams.get('PayerID');

  //  Ifthenpay callback query params
  const amount = searchParams.get('amount');
  const requestId = searchParams.get('requestId');
  const paymentDatetime = searchParams.get('payment_datetime');
  //  Multibanco
  const mbEntity = searchParams.get('entity');
  const mbReference = searchParams.get('reference');
  const mbOrderId = searchParams.get('orderId');
  //  Cartão de crédito
  const callbackId = searchParams.get('id');
  const sk = searchParams.get('sk');
  const brand = searchParams.get('brand');
  const pan = searchParams.get('pan');

  useEffect(() => {
    if (paymentId && token && payerId && orderId) {
      setIsLoading(true);
      const fetchData = async () => {
        const paypalCall = await paypalCallbackHandler(paymentId, token, payerId, orderId);
        const { data } = paypalCall;
        if (data?.data?.payment_status === 'paid') {
          setIsLoading(false);
          setActiveStep(2);
        }
      };
      if (orderStatus === 'paid') {
        setIsLoading(false);
        setActiveStep(2);
      } else {
        dispatch(setQueryParams({ paymentId, token, payerId }));
        fetchData();
      }
    } else if (sk && brand && pan && amount) {
      setActiveStep(2);
      const callbackObj = {
        id: callbackId,
        amount: amount,
        requestId: requestId,
        sk: sk,
        brand: brand,
        pan: pan,
        orderId: orderId,
      };
      ifthenpayCallbackHandler('credit_card', callbackObj);
      dispatch(setQueryParams({ requestId, sk, brand, pan, amount }));
    } else if (mbEntity && mbReference) {
      setActiveStep(2);
      const callbackObj = {
        orderId: mbOrderId,
        amount: amount,
        requestId: requestId,
        entity: mbEntity,
        reference: mbReference,
        payment_datetime: paymentDatetime,
      };
      ifthenpayCallbackHandler('multibanco', callbackObj);
      dispatch(
        setQueryParams({
          requestId,
          mbEntity,
          mbReference,
          paymentDatetime,
          amount,
        })
      );
    } else if (mbOrderId && requestId && !mbReference && !mbEntity) {
      setActiveStep(2);
      const callbackObj = {
        orderId: mbOrderId,
        amount: amount,
        requestId: requestId,
        paymentDatetime: paymentDatetime,
      };
      ifthenpayCallbackHandler('mbway', callbackObj);
      dispatch(setQueryParams({ amount, requestId, paymentDatetime }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paymentId,
    token,
    payerId,
    navigate,
    dispatch,
    orderId,
    requestId,
    sk,
    brand,
    pan,
    amount,
    callbackId,
    mbEntity,
    mbReference,
    paymentDatetime,
    mbOrderId,
  ]);

  const checkCartProducts = (stepName) => {
    getCartUpdated(cartId)
      .then((data) => {
        const updatedStockProducts = data.filter((a) =>
          cartItems.some(
            (b) =>
              b.reference === a.reference &&
              b.stock !== a.stock &&
              b.quantity.unit > a.stock &&
              a.stock !== 0
          )
        );
        const outOfStockProducts = data.filter((a) =>
          cartItems.some((b) => b.reference === a.reference && a.stock === 0)
        );
        const unavailableProducts = cartItems.filter(
          (a) => !data.some((b) => b.reference === a.reference)
        );

        if (
          updatedStockProducts.length ||
          outOfStockProducts.length ||
          unavailableProducts.length
        ) {
          setUpdatedProducts((prevState) => ({
            ...prevState,
            stock: !!updatedStockProducts.length ? updatedStockProducts : [],
            outOfStock: !!outOfStockProducts.length ? outOfStockProducts : [],
            availability: !!unavailableProducts.length ? unavailableProducts : [],
          }));

          setModalIsOpen(true);

          const newCartArray = cartItems
            //  remove unavailable or out-of-stock products
            /*.filter(
              (a) =>
                ![...outOfStockProducts, ...unavailableProducts].some(
                  (b) => b.reference === a.reference
                )
            )*/
            .map((a) => {
              const productToRemove = [...outOfStockProducts, ...unavailableProducts].find(
                (b) => b.reference === a.reference
              );
              return !!productToRemove ? { ...a, quantity: { ...a.quantity, unit: 0 } } : a;
            })
            //  update quantity AND stock of remaining products
            .map((a) => {
              const productToUpdateQuantity = updatedStockProducts.find(
                (b) => b.reference === a.reference
              );
              return !!productToUpdateQuantity
                ? {
                    ...a,
                    quantity: {
                      ...a.quantity,
                      unit: productToUpdateQuantity.stock,
                    },
                    stock: productToUpdateQuantity.stock,
                  }
                : a;
            });

          updateCartProducts(newCartArray, customerId)
            .then(({ cart_items, discounts, total_discount, taxes, subtotal, total }) => {
              dispatch(setCart(cart_items));
              dispatch(setCartState({ discounts: discounts }));
              dispatch(setDiscount(total_discount));
              dispatch(setCartTaxes(taxes));
              dispatch(setCartSubtotal(subtotal));
              dispatch(setCartTotal(total));
            })
            .catch(({ response: { status } }) => {
              if (status === 401) navigate('/login');
            });

          return;
        }

        //  handle order confirmation
        if (stepName === 'checkout') setHandleConfirmation(true);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  };

  return (
    <PaymentProvider>
      <MainContainer>
        <Container>
          <Grid container>
            <Grid xs={12}>
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  {cartItems.length === 0 && activeStep !== 2 ? (
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography>{t('cart.yourCartIsEmpty')}</Typography>
                      <Button
                        title={t('app.backToHomepageButton')}
                        variant='contained'
                        color='secondary'
                        component={Link}
                        to='/'
                        sx={{ marginTop: 2 }}
                      >
                        {t('app.backToHomepageButton')}
                      </Button>
                    </Box>
                  ) : (
                    <>
                      <CartProgressIndicator activeStep={activeStep} />

                      {activeStep === 0 && (
                        <CartSummary
                          setActiveStep={handleActiveStep}
                          cartItems={cartItems}
                          cartSubtotal={subtotal}
                          selectedClient={customerId}
                          cartTotalDiscount={discount}
                          role={role}
                          checkCartProducts={checkCartProducts}
                        />
                      )}

                      {activeStep === 1 && (
                        <OrderDetails
                          setActiveStep={handleActiveStep}
                          cart={cart}
                          checkCartProducts={checkCartProducts}
                          handleConfirmation={handleConfirmation}
                          setHandleConfirmation={setHandleConfirmation}
                        />
                      )}

                      {activeStep === 2 && <OrderConfirmation role={role} />}

                      {modalIsOpen && (
                        <CheckCartProducts
                          setActiveStep={setActiveStep}
                          updatedProducts={updatedProducts}
                          modalIsOpen={modalIsOpen}
                          setModalIsOpen={setModalIsOpen}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </MainContainer>
    </PaymentProvider>
  );
};

export default CartPage;
