import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import BrandsCard from '../BrandsCard';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';

const Brands = ({ brands }) => {
  const { t } = useTranslation();
  return (
    <Container sx={{ paddingY: { xs: 2, md: 4 } }}>
      <Typography
        variant='h2'
        sx={{
          fontSize: '1.5rem',
        }}
      >
        {t('homepage.brands.title')}
      </Typography>
      <Typography marginTop={1}>{t('homepage.brands.description')}</Typography>
      <Box
        sx={{
          marginY: { xs: 2, md: 4 },
        }}
      >
        <Grid container>
          {brands.map((brand) => (
            <Grid
              key={`brand-${brand._id}`}
              xs={6}
              sm={3}
              md={2.333333}
            >
              <BrandsCard brand={brand} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Brands;
