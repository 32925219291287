import React from 'react';
import { Link } from 'react-router-dom';
import { imageOnError, slugify } from '../helpers';
import { Box } from '@mui/material';
import { TRANSITION_DURATION } from '../variables';

const BrandsCard = ({ brand }) => {
  brand.detailUrl = slugify(brand.name);

  return (
    <Box
      component={Link}
      to={brand?.url ? brand?.url : `/marcas/${brand?.detailUrl}`}
      title={brand?.name}
      sx={{
        display: 'flex',
        transition: `opacity ${TRANSITION_DURATION}ms ease`,
        willChange: 'opacity',
        '&:hover': {
          opacity: 0.75,
        },
      }}
      padding={{ xs: 1, md: 2 }}
    >
      <Box
        component='img'
        src={brand?.image}
        onError={imageOnError}
        alt={brand?.name}
        sx={{
          objectFit: 'contain',
          aspectRatio: '1',
          maxWidth: '100%',
        }}
      ></Box>
    </Box>
  );
};

export default BrandsCard;
