import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SummaryTable from './SummaryTable/SummaryTable';
import { useEffect } from 'react';

const CartSummary = ({
  setActiveStep,
  cartItems,
  cartTotalDiscount,
  cartSubtotal,
  selectedClient,
  role,
  checkCartProducts,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    checkCartProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography
        variant='h2'
        sx={{
          textAlign: 'center',
          marginBottom: { xs: 3, md: 4 },
        }}
      >
        {t('cart.steps.resume')}
      </Typography>

      <SummaryTable
        cartItems={cartItems}
        cartTotalDiscount={cartTotalDiscount}
        cartSubtotal={cartSubtotal}
        selectedClient={selectedClient}
        role={role}
        setActiveStep={setActiveStep}
      />
    </>
  );
};

export default CartSummary;
