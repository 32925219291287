import React, { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { setCartState } from '../../state/cartSlice';
import { getPreviousOrders } from '../../api/user';
import { usePayment } from '../../contexts/PaymentContext';
import { setPendingOrder } from '../../state/orderSlice';

const OrderConfirmation = ({ role }) => {
  const { setOrderResponse, PaymentGateway, setIsLimitedCustomer, loading } = usePayment();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const customerId = user?.selectedClient?.id || user?.id;

  useEffect(() => {
    //  reset cart state
    setIsLimitedCustomer(role === 'limitedcustomer');
    dispatch(
      setCartState({
        cart: [],
        taxes: [],
        subtotal: 0,
        total: 0,
        discounts: [],
        discount: 0,
        shipping: '',
        deliveryAtAddress: '',
        deliveryAtStore: '',
        shippingNotes: '',
      })
    );
  }, [dispatch, setIsLimitedCustomer, role]);

  useEffect(() => {
    getPreviousOrders(customerId)
      .then((previousOrders) => {
        if (Array.isArray(previousOrders)) {
          previousOrders.sort((a, b) => {
            return a._id.localeCompare(b._id);
          });
          const lastOrder = previousOrders[previousOrders.length - 1];
          setOrderResponse(lastOrder);
          dispatch(setPendingOrder(lastOrder));
        } else {
          console.error('Previous orders is not an array:', previousOrders);
        }
      })
      .catch((error) => {
        console.error('Error fetching previous orders:', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setOrderResponse]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        height: '50vh',
        marginTop: { xs: 5, md: 0 },
      }}
    >
      {loading ? <CircularProgress /> : <PaymentGateway />}
    </Box>
  );
};

export default OrderConfirmation;
