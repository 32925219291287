import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateCartProducts } from '../../../api/checkout';
import {
  approveOrder,
  duplicateOrder,
  getPreviousOrders,
} from '../../../api/user';
import { setCartState } from '../../../state/cartSlice';
import { MyAccountTitle } from '../../../styles';
import Loading from '../../Loading';
import PopupNotification from '../../PopupNotification/PopupNotification';
import AccountFilters from '../AccountFilters';
import OrdersTable from './OrdersTable';
import { useTranslation } from 'react-i18next';

const AccountPurchases = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, selectedClient, isAdmin, role } = useSelector(
    (state) => state.user
  );
  const customerId = selectedClient?.id || id;
  const isLimitedCustomer = role === 'limitedcustomer';

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
  });

  const [orders, setOrders] = useState([]);
  const [entities, setEntities] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [selectedYear, setSelectedYear] = useState('all');
  const [search, setSearch] = useState('');

  useEffect(() => {
    setIsLoading(true);

    getPreviousOrders(customerId)
      .then((orders) => {
        //  customize orders array
        orders = orders
          .map((order) => ({
            ...order,
            status: t(`app.${order.status}`),
            forApproval: order.status === 'forApproval',
          }))
          .reverse();
        setOrders(orders);
        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, customerId, language]);

  useEffect(() => {
    setEntities(orders);

    //  reset filters
    setSelectedStatus('all');
    setSelectedYear('all');
  }, [orders]);

  const filteredByStatus = entities.filter(({ status }) => {
    if (selectedStatus === 'all') return true;

    return selectedStatus === status;
  });

  const filteredByYearAndStatus = filteredByStatus.filter(({ date }) => {
    if (selectedYear === 'all') return true;

    return selectedYear === new Date(date).getFullYear();
  });

  const filteredByEverything = filteredByYearAndStatus.filter(({ reference }) =>
    reference?.includes(search)
  );

  const approvePendingOrder = (orderId) => {
    setIsLoading(true);

    approveOrder(orderId)
      .then(({ msg, orders }) => {
        setOrders(orders.reverse());

        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
          successMessage: msg,
        }));

        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  };

  const duplicateOrderFromHistory = (orderId) => {
    setIsLoadingAction(true);

    duplicateOrder(customerId, orderId)
      .then((products) => {
        updateCartProducts(products, customerId)
          .then(
            ({
              cart_items,
              discounts,
              total_discount,
              taxes,
              subtotal,
              total,
            }) => {
              dispatch(
                setCartState({
                  cart: cart_items,
                  discounts: discounts,
                  discount: total_discount,
                  taxes: taxes,
                  subtotal: subtotal,
                  total: total,
                })
              );

              setNotificationProps((prevState) => ({
                ...prevState,
                isOpened: true,
                type: 'success',
                successMessage: t('cart.productAddedToCartMessage'),
              }));

              setIsLoadingAction(false);
            }
          )
          .catch(({ response: { status } }) => {
            if (status === 401) navigate('/login');
          });
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  };

  return (
    <>
      <Box
        sx={{
          flex: 3,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box marginBottom={4}>
          <MyAccountTitle>{t('myAccount.orders.title')}</MyAccountTitle>
          <Typography variant='small'>{t('myAccount.orders.text')}</Typography>
        </Box>

        {isLoading ? (
          <Loading />
        ) : (
          <>
            {filteredByEverything.length !== 0 && (
              <>
                <AccountFilters
                  type={entities}
                  selectedStatus={selectedStatus}
                  selectedYear={selectedYear}
                  setSelectedStatus={setSelectedStatus}
                  setSelectedYear={setSelectedYear}
                  search={search}
                  setSearch={setSearch}
                />
                <OrdersTable
                  shownOrders={filteredByEverything}
                  approvePendingOrder={approvePendingOrder}
                  duplicateOrderFromHistory={duplicateOrderFromHistory}
                  isAdmin={isAdmin}
                  isLoadingAction={isLoadingAction}
                  isLimitedCustomer={isLimitedCustomer}
                />
              </>
            )}

            {filteredByEverything.length === 0 && (
              <Typography>{t('myAccount.orders.noDataAvailable')}</Typography>
            )}
          </>
        )}
      </Box>
      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </>
  );
};

export default AccountPurchases;
