import React, { useEffect, useState } from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import { usePayment } from '../../../contexts/PaymentContext';
import { useTranslation } from 'react-i18next';

const MultibancoPayment = ({ paymentData }) => {
  const { error, setError, loading, setLoading, setPaymentData, processPayment } = usePayment();
  const [paymentProcessed, setPaymentProcessed] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const handlePayment = async () => {
      try {
        setLoading(true);
        const response = await processPayment();
        if (response) {
          setError(false);
          setPaymentData(response);
        }
        setError(null);
        setPaymentProcessed(true);
      } catch (err) {
        setError(` ${t('beMessages.paymentError')} ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    if (!paymentProcessed && !setPaymentData && !paymentData) {
      handlePayment();
    }
  }, [
    paymentProcessed,
    paymentData,
    loading,
    processPayment,
    setError,
    setLoading,
    setPaymentData,
    t,
  ]);

  if (loading) return <CircularProgress size={80} />;
  if (error) return <Typography color='error'>{error}</Typography>;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
    >
      {error && <Typography color='error'>{error}</Typography>}

      {paymentData && (
        <>
          <Typography>{t('orderConfirmation.paymentAdvice')}</Typography>
          <Box
            sx={{
              backgroundColor: '#ffffff',
              borderRadius: 8,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              padding: '20px',
              margin: '20px 0 20px 0',
            }}
          >
            <Typography variant='h3'>
              {t('orderConfirmation.paymentDetails')}
            </Typography>
            <Typography>
              {t('orderConfirmation.multibanco.entity')}:{' '}
              {paymentData.data.entity}
            </Typography>
            <Typography>
              {t('orderConfirmation.multibanco.reference')}:{' '}
              {paymentData.data.reference}
            </Typography>
            <Typography>
              {t('orderConfirmation.multibanco.amount')}:{' '}
              {paymentData.data.amount}
            </Typography>
            <Typography>
              {t('orderConfirmation.multibanco.payment_deadline')}:{' '}
              {paymentData.data.expiry_date}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default MultibancoPayment;
