import React, { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addProductsToCart } from '../api/products';
import PopupNotification from '../components/PopupNotification/PopupNotification';
import ProductVariantsModal from '../components/ProductVariantsModal';
import {
  setCartState,
  setCartSubtotal,
  setCartTaxes,
  setCartTotal,
  setDiscount,
  updateCart,
} from '../state/cartSlice';
import { SETTINGS } from '../variables';

export const ProductCardContext = createContext();

export const ProductCardProvider = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { id, selectedClient, customerDiscount } = user;
  const customerId = selectedClient?.id || id;

  const [isVariationsModalOpen, setIsVariationsModalOpen] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    type: '',
    successMessage: '',
    errorMessage: '',
  });
  const [productVariants, setProductVariants] = useState([]);
  const [productsForCart, setProductsForCart] = useState({
    products: [],
    totalUnits: 0,
    total: 0,
  });

  const addToCart = (product) => {
    const productsToCart = product
      ? [
          {
            // SIMPLE PRODUCT
            ...product,
            quantity: {
              ...product.quantity,
              [SETTINGS.defaults.quantityType]: 1,
            },
          },
        ]
      : productsForCart.products; // VARIANTS PRODUCT

    addProductsToCart(productsToCart, customerId)
      .then(
        ({ cart_items, discounts, total_discount, taxes, subtotal, total }) => {
          dispatch(
            setCartState({
              discounts: discounts,
            })
          );
          dispatch(updateCart(cart_items));
          dispatch(setDiscount(total_discount));
          dispatch(setCartTaxes(taxes));
          dispatch(setCartSubtotal(subtotal));
          dispatch(setCartTotal(total));

          setNotificationProps((prevState) => ({
            ...prevState,
            isOpened: true,
            type: 'success',
            successMessage: t('cart.addToCart'),
          }));
        }
      )
      .catch(
        ({
          response: {
            status,
            data: { msg },
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: t(msg),
            }));
          }
        }
      );
  };

  return (
    <ProductCardContext.Provider
      value={{
        setIsVariationsModalOpen,
        productVariants,
        setProductVariants,
        customerDiscount,
        addToCart,
      }}
    >
      {children}
      <ProductVariantsModal
        isVariationsModalOpen={isVariationsModalOpen}
        setIsVariationsModalOpen={setIsVariationsModalOpen}
        productsForCart={productsForCart}
        setProductsForCart={setProductsForCart}
      />
      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </ProductCardContext.Provider>
  );
};
