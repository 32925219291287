import { Box, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProductsCarousel from '../Carousel/ProductsCarousel';

const NewProducts = ({ products }) => {
  const { t } = useTranslation();
  return (
    <Container sx={{ paddingY: { xs: 2, md: 4 } }}>
      <Typography
        variant='h2'
        sx={{
          fontSize: '1.5rem',
        }}
      >
        {t('homepage.news.title')}
      </Typography>
      <Typography marginTop={1}>{t('homepage.news.description')}</Typography>
      <Box sx={{ marginTop: 4 }}>
        <ProductsCarousel products={products} />
      </Box>
    </Container>
  );
};

export default NewProducts;
