import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CreditCardBadge } from '../../../../assets/checkout/credit-card.svg';
//import { ReactComponent as MbwayBadge } from '../../../../assets/checkout/mbway.svg';
import { ReactComponent as MultibancoBadge } from '../../../../assets/checkout/multibanco.svg';
import { ReactComponent as PaypalBadge } from '../../../../assets/checkout/paypal.svg';
import { DELIVERY_METHODS, FONT_STYLE } from '../../../../variables';
import DeliveryAtAddress from './DeliveryAtAddress';
import DeliveryAtStore from './DeliveryAtStore';

const DetailsForm = ({
  shipping,
  onShippingChange,
  address,
  onStoreChange,
  store,
  onObservationsChange,
  paymentMethods,
  paymentMethod,
  onPaymentChange,
  paymentTime,
  onAddressChange,
  role,
  userCountry,
  clientAddresses,
}) => {
  const { t } = useTranslation();
  const isLimitedCustomer = role === 'limitedcustomer';
  const storeDeliveryOptions = false;
  const filteredPaymentMethods =
    userCountry === 'Portugal'
      ? paymentMethods
      : paymentMethods.filter((method) => method !== 'multibanco' && method !== 'mbway');
  const paymentMethodIcons = {
    paypal: <PaypalBadge />,
    credit_card: <CreditCardBadge />,
    multibanco: <MultibancoBadge />,
    //mbway: <MbwayBadge />,
  };

  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '80%', md: 'auto' },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <FormControl>
        {!!!isLimitedCustomer && (
          <Box>
            <Box
              sx={{
                marginBottom: 1.5,
              }}
            >
              <Typography
                variant='h3'
                sx={{
                  fontWeight: FONT_STYLE.medium,
                }}
              >
                {t('checkout.payment')}
              </Typography>
              <Typography
                variant='small'
                sx={{
                  marginTop: 1,
                }}
              >
                {t('checkout.paymentText')}
              </Typography>
            </Box>

            <RadioGroup
              value={paymentMethod}
              onChange={onPaymentChange}
              name='payment-options'
              sx={{
                gap: 1,
              }}
            >
              {filteredPaymentMethods.map((method) => (
                <Box
                  key={`paymentMethod-${method}`}
                  display='grid'
                >
                  <FormControlLabel
                    value={method}
                    control={<Radio />}
                    title={t(`checkout.${method}`)}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    label={
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                        }}
                      >
                        {t(`checkout.${method}`)}
                        {paymentMethodIcons?.[method] && paymentMethodIcons[method]}
                      </Box>
                    }
                  />
                  {method === 'cashOnDelivery' && paymentMethod === 'cashOnDelivery' && (
                    <TextField
                      defaultValue={paymentTime}
                      InputProps={{
                        disabled: true,
                      }}
                      sx={{
                        marginTop: 1,
                      }}
                    />
                  )}
                </Box>
              ))}
            </RadioGroup>
          </Box>
        )}

        <TextField
          id='outlined-multiline-static'
          label={t('checkout.observations')}
          multiline
          rows={3}
          defaultValue=''
          onChange={onObservationsChange}
        />

        <Divider sx={{ marginY: { xs: 3, md: 4 } }} />

        <Box>
          <Box
            sx={{
              marginBottom: 1.5,
            }}
          >
            <Typography
              variant='h3'
              sx={{
                fontWeight: FONT_STYLE.medium,
              }}
            >
              {t('checkout.shipping')}
            </Typography>
            <Typography
              variant='small'
              sx={{
                marginTop: 1,
              }}
            >
              {t('checkout.shippingText')}
            </Typography>
          </Box>

          <RadioGroup
            value={shipping}
            onChange={onShippingChange}
            name='shipping-options'
          >
            {DELIVERY_METHODS.BILLING_ADDRESS && !!!isLimitedCustomer && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <FormControlLabel
                  value={DELIVERY_METHODS.BILLING_ADDRESS}
                  control={<Radio />}
                  title={t('checkout.billingAddress')}
                  label={t('checkout.billingAddress')}
                />
              </Box>
            )}

            {DELIVERY_METHODS.ADDRESS && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <FormControlLabel
                  value={DELIVERY_METHODS.ADDRESS}
                  control={<Radio />}
                  title={t('checkout.unloadingAddress')}
                  label={t('checkout.unloadingAddress')}
                />
                {shipping === DELIVERY_METHODS.ADDRESS && (
                  <DeliveryAtAddress
                    address={address}
                    onAddressChange={onAddressChange}
                    role={role}
                    clientAddresses={clientAddresses}
                  />
                )}
              </Box>
            )}

            {DELIVERY_METHODS.STORE && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <FormControlLabel
                  value={DELIVERY_METHODS.STORE}
                  control={<Radio />}
                  title={t('checkout.warehousePickup')}
                  label={t('checkout.warehousePickup')}
                />
                {shipping === DELIVERY_METHODS.STORE && (
                  <>
                    {storeDeliveryOptions && (
                      <DeliveryAtStore
                        store={store}
                        onStoreChange={onStoreChange}
                      />
                    )}
                    <Box marginTop={1}>
                      <Typography fontWeight={FONT_STYLE.medium}>
                        {t('checkout.warehouseAddress')}
                      </Typography>
                      <Typography
                        variant='small'
                        sx={{
                          lineHeight: 1.5,
                          marginTop: 0.75,
                        }}
                      >
                        Sermail, Lda
                        <br />
                        {store.street} <br /> {store.zipCode}
                        <br />
                        {store.city}
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            )}
          </RadioGroup>
        </Box>
      </FormControl>
    </Box>
  );
};

export default DetailsForm;
