import { Box, Typography, styled, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../constants/utils';
import { CUSTOM_THEME_COLORS, FONT_STYLE } from '../../../variables';

const StyledOrderSummaryBox = styled(Box)(({ theme, isdiscount, istotal }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  marginTop: istotal === 'true' ? theme.spacing(2) : theme.spacing(1),
  color: isdiscount && CUSTOM_THEME_COLORS.red,
  '&:last-of-type': {
    p: {
      fontSize: istotal === 'true' && '1.2rem',
      fontWeight: istotal === 'true' && FONT_STYLE.bold,
    },
  },
}));

const StyledOrderSummaryText = styled(Typography)(() => ({
  fontSize: '0.85rem',
  fontWeight: FONT_STYLE.bold,
}));

const OrderSummaryCard = ({
  cart: { cart, discount, discounts, subtotal },
  taxes,
  total,
  deliveryCost,
  isLimitedCustomer,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const headerHeight = document.getElementsByTagName('header')[0]?.offsetHeight;

  const totalUnits = cart.reduce(
    (previousValue, item) => previousValue + (item.quantity.unit + item.quantity.box),
    0
  );
  const pvpPriceItemsSum = cart.reduce(
    (previousValue, item) =>
      Math.fround(
        previousValue +
          //  remove IVA percentage from PVP PRICES
          (item.pvpPrice - item.pvpPrice * (item.tax / 100)) *
            (item.quantity.unit + item.quantity.box)
      ),

    0
  );
  const customerGains = Math.abs(pvpPriceItemsSum - subtotal);

  const orderSummary = [
    {
      id: 0,
      text: t('checkout.summaryCard.units'),
      value: totalUnits,
      items: [],
    },
    {
      id: 1,
      text: t('checkout.summaryCard.references'),
      value: cart.length,
      items: [],
    },
    {
      id: 2,
      text: t('checkout.summaryCard.subtotal'),
      value: formatCurrency(subtotal),
      items: [],
    },
    ...taxes.map((tax) => ({
      id: 3,
      text: t('checkout.summaryCard.iva', { amount: tax.iva }),
      value: formatCurrency(tax.total),
      items: [],
    })),
    {
      id: 4,
      text: t('checkout.summaryCard.shipping'),
      value: formatCurrency(deliveryCost),
      items: [],
    },
    {
      id: 5,
      text: t('checkout.summaryCard.discounts'),
      value: !!discounts.length ? '' : formatCurrency(discount),
      items: discounts.map((discount) => ({
        ...discount,
        title: t(discount.title),
        percentage: Number(discount.percentage),
        value: Number(discount.value),
      })),
    },
    {
      id: 6,
      text: t('checkout.summaryCard.total'),
      value: formatCurrency(total),
      items: [],
    },
  ].filter(
    (summaryItem) =>
      //  IF there is no 'discounts' hide it
      !(summaryItem.id === 5 && discount === 0) &&
      //  IF 'isLimitedCustomer' hide some items
      !(isLimitedCustomer && !(summaryItem.id === 0 || summaryItem.id === 1))
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: { sm: '60%', md: '30%' },
        position: { md: 'sticky' },
        top: { md: `${headerHeight + 20}px` },
        marginBottom: 2,
      }}
    >
      <Box
        sx={{
          height: '100%',
          padding: 3,
          border: `1px solid ${CUSTOM_THEME_COLORS.darkGray}`,
          borderRadius: theme.shape.borderRadius / 10,
        }}
      >
        <Typography
          variant='h3'
          sx={{
            textAlign: 'center',
            marginBottom: 3,
          }}
        >
          {t('checkout.summaryCard.title')}
        </Typography>

        {orderSummary.map((summaryItem) => (
          <StyledOrderSummaryBox
            key={`orderSummaryItem-${summaryItem.text}`}
            isdiscount={summaryItem.id === 5 ? 'true' : undefined}
            istotal={summaryItem.id === 6 ? 'true' : undefined}
          >
            <StyledOrderSummaryText>{summaryItem?.text}</StyledOrderSummaryText>
            <StyledOrderSummaryText>{summaryItem?.value}</StyledOrderSummaryText>
            {/* all discounts available */}
            {!!summaryItem?.items.length &&
              summaryItem?.items.map((item, index) => (
                <Box
                  key={`orderSummaryItem-discount-${index}`}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: 1,
                    marginLeft: 2,
                  }}
                >
                  <StyledOrderSummaryText>{`${item.title} ${
                    !!item?.percentage ? `(${item?.percentage}%)` : ''
                  }`}</StyledOrderSummaryText>
                  <StyledOrderSummaryText>{formatCurrency(item.value)}</StyledOrderSummaryText>
                </Box>
              ))}
          </StyledOrderSummaryBox>
        ))}
      </Box>
      {!!customerGains && !isLimitedCustomer && (
        <Typography
          variant='small'
          sx={{
            fontWeight: FONT_STYLE.medium,
            color: CUSTOM_THEME_COLORS.red,
            textAlign: 'center',
          }}
        >
          <Trans
            i18nKey='checkout.customerGains'
            values={{
              amount: `<strong>${formatCurrency(customerGains)}</strong>`,
            }}
          />
        </Typography>
      )}
    </Box>
  );
};

export default OrderSummaryCard;
