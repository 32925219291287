import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getBrands, getPage } from '../api';
import { mapProduct } from '../api/mapApiInterface';
import Brands from '../components/HomePage/Brands';
import MainBanner from '../components/HomePage/MainBanner';
import NewProducts from '../components/HomePage/NewProducts';
import SecondaryBanner from '../components/HomePage/SecondaryBanner';
import { MainContainer } from '../styles';

const HomePage = () => {
  const navigate = useNavigate();
  const { cart } = useSelector((state) => state.cart);

  const [mainBanner, setMainBanner] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [highlights, setHighlights] = useState([]);
  const [brands, setBrands] = useState([]);
  const pageName = 'homepage';

  useEffect(() => {
    getPage(pageName)
      .then((page) => {
        const { mainBanner, campaigns, products } = page;
        const mappedProducts = products.map((product) => mapProduct(product));
        setMainBanner(mainBanner);
        setCampaigns(campaigns);
        setHighlights(mappedProducts);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });

    getBrands()
      .then((brands) => setBrands(brands))
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  }, [navigate, cart]);

  return (
    <MainContainer paddingY='0 !important'>
      {!!mainBanner.length && <MainBanner mainBanner={mainBanner} />}
      {!!brands.length && <Brands brands={brands} />}
      {!!highlights.length && <NewProducts products={highlights} />}
      {!!campaigns.length && <SecondaryBanner data={campaigns} />}
    </MainContainer>
  );
};

export default HomePage;
