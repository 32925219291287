import { Container, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import UserAccountNavbar from '../components/UserAccount/UserAccountNavBar';
import { MainContainer } from '../styles';
import { MEDIUM_DEVICES_BREAKPOINT } from '../variables';

const AccountPage = () => {
  const userRole = useSelector((state) => state.user.role);
  const SMALL_DEVICES = useMediaQuery(`(max-width:${MEDIUM_DEVICES_BREAKPOINT})`);

  return (
    <MainContainer>
      <Container>
        <Grid
          container
        >
          <Grid
            md={3}
            lg={2}
          >
            <UserAccountNavbar
              SMALL_DEVICES={SMALL_DEVICES}
              userRole={userRole}
            />
          </Grid>
          <Grid
            xs={12}
            md={9}
            lg={9.5}
            lgOffset={0.5}
          >
            <Outlet context={[SMALL_DEVICES]} />
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default AccountPage;
