import { Box, Grid, Typography, styled } from '@mui/material';
import React from 'react';
import { COMPANY_INFO } from '../../variables';
import { FooterTitles } from './Footer';
import { useTranslation } from 'react-i18next';

export const ContactUsContent = styled(Typography)(() => ({
  display: 'block',
}));

const ContactUs = () => {
  const { t } = useTranslation();
  const {
    address: { street, zipCode, city },
    mobile,
    phone,
    fax,
    email,
  } = COMPANY_INFO;

  return (
    <Grid
      item
      xs={3}
    >
      <FooterTitles variant='h4'>{t('app.contactUs')}</FooterTitles>

      <ContactUsContent>
        {street} <br /> {zipCode}
        <br />
        {city}
      </ContactUsContent>

      {mobile.value && (
        <Box sx={{ marginTop: 1.5 }}>
          <ContactUsContent
            component={'a'}
            href={`tel:${mobile.value}`}
            title={mobile.label}
          >
            <strong>Tel. </strong>
            {mobile.label}
          </ContactUsContent>
        </Box>
      )}

      {phone.value && (
        <Box sx={{ marginTop: 1.5 }}>
          <ContactUsContent
            component={'a'}
            href={`tel:${phone.value}`}
            title={t('app.general')}
          >
            <strong>{t('app.general')}: </strong>
            {phone.label}
          </ContactUsContent>
          <Typography variant='small'>{t('app.nationalMobileNetwork')}</Typography>
        </Box>
      )}

      <Box sx={{ marginTop: 1.5 }}>
        <ContactUsContent
          component={'a'}
          href={`tel:+34686048186`}
          title={t('app.spain')}
        >
          <strong>{t('app.spain')}: </strong>
          (+34) 686 04 81 86
        </ContactUsContent>
        <Typography variant='small'>{t('app.nationalMobileNetwork')}</Typography>
      </Box>

      {fax.value && (
        <ContactUsContent sx={{ marginTop: 1.5 }}>
          <strong>Fax: </strong>
          {fax.label}
        </ContactUsContent>
      )}

      {email && (
        <ContactUsContent
          component={'a'}
          href={`mailto:${email}`}
          title={email}
          sx={{
            marginTop: 1.5,
          }}
        >
          <strong>E-mail: </strong>
          {email}
        </ContactUsContent>
      )}
    </Grid>
  );
};

export default ContactUs;
