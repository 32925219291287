import { Box, Button, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FONT_STYLE } from '../../variables';
import LoginForm from './LoginForm';

const Login = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: { xs: '80vw', md: '30vw' },
        marginTop: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Typography sx={{ textAlign: 'center', fontWeight: FONT_STYLE.bold }}>
        {t('login.title')}
      </Typography>

      <LoginForm />

      <Typography
        component={Link}
        variant='link'
        to='recuperar-palavra-passe'
        title={t('app.forgotPassword')}
        sx={{ fontSize: '0.9rem', textAlign: 'center' }}
      >
        {t('login.forgotPassword')}
      </Typography>

      <Divider sx={{ width: '100%', marginY: 2 }} />

      <Typography sx={{ textAlign: 'center' }}>
        {t('login.newRegister')}
      </Typography>

      <Button
        component={Link}
        to='registo-utilizador'
        variant='contained'
        color='secondary'
        title={t('login.newRegisterButton')}
      >
        {t('login.newRegisterButton')}
      </Button>
    </Box>
  );
};

export default Login;
