import { useEffect, useState } from 'react';
import ProductVariantsTable from './ProductVariantsTable';

const ProductVariants = ({
  variants,
  userIsLogged,
  productsForCart,
  setProductsForCart,
}) => {
  const [updatedProductQuantity, setUpdatedProductQuantity] =
    useState(undefined);

  useEffect(() => {
    if (!updatedProductQuantity) return;

    //  check if product already exists
    const productAlreadyExists = productsForCart.products.find(
      (product) => product.reference === updatedProductQuantity.reference
    );

    //  if product exists
    if (productAlreadyExists) {
      setProductsForCart((prevState) => ({
        ...prevState,
        products: [
          ...prevState.products.reduce((acc, product) => {
            //  product with same reference
            product.reference === updatedProductQuantity.reference
              ? //  IF product has quantities !== 0
                (updatedProductQuantity.quantity.unit !== 0 ||
                  updatedProductQuantity.quantity.box !== 0) &&
                acc.push({
                  ...product,
                  quantity: updatedProductQuantity.quantity,
                })
              : acc.push(product);
            return acc;
          }, []),
        ],
      }));
    } else {
      //  add new product to array
      setProductsForCart((prevState) => ({
        ...prevState,
        products: [
          ...prevState.products,
          { ...updatedProductQuantity, isVariant: true },
        ],
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedProductQuantity]);

  return (
    <ProductVariantsTable
      variants={variants}
      userIsLogged={userIsLogged}
      setUpdatedProductQuantity={setUpdatedProductQuantity}
    />
  );
};

export default ProductVariants;
