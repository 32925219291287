import { Box, Button, OutlinedInput, styled, useTheme } from '@mui/material';
import { CUSTOM_THEME_COLORS, FONT_STYLE } from '../../variables';
import { useTranslation } from 'react-i18next';

const QuantitySelectorInput = styled(OutlinedInput)(({ hasproducttypes }) => ({
  fontSize: '0.85rem',
  width: hasproducttypes ? '68px' : '38px',
  height: '34px',
  '.MuiInputBase-input': {
    maxWidth: hasproducttypes ? 'calc(100% - 30px)' : '100%',
    textAlign: ' center',
    padding: 0,
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent !important',
  },
}));

const QuantitySelectorButton = styled(Button)(() => ({
  fontSize: '1rem',
  fontWeight: FONT_STYLE.medium,
  minWidth: '24px',
  minHeight: '24px',
  padding: 0.5,
}));

const QuantityTypeButton = styled(Button)(({ selected }) => ({
  padding: 0,
  minWidth: 0,
  height: 'inherit',
  fontSize: '9px',
  fontWeight: selected ? 700 : 500,
  borderRadius: 0,
  '&:disabled, &': {
    backgroundColor: selected
      ? CUSTOM_THEME_COLORS.gray
      : CUSTOM_THEME_COLORS.lightGray,
    color: 'black',
  },
}));

const QuantitySelector = ({
  quantity,
  increment,
  decrement,
  incrementDisabled,
  decrementDisabled,
  openFieldChange,
  openFieldHasError,
  hasProductTypes = false,
  quantityType,
  setQuantityType,
  isOutOfStock,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 0.5,
      }}
    >
      <QuantitySelectorButton
        title={t('product.decreaseQuantity')}
        disabled={decrementDisabled}
        onClick={() => decrement()}
      >
        -
      </QuantitySelectorButton>

      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          border: `1px solid ${
            !openFieldHasError ? CUSTOM_THEME_COLORS.gray : CUSTOM_THEME_COLORS.red
          }`,
          borderRadius: theme.shape.borderRadius / 10,
          borderBottom: isOutOfStock ? `3px solid ${CUSTOM_THEME_COLORS.red}` : null,
        }}
      >
        <QuantitySelectorInput
          id='quantity-selector'
          required
          disabled={isOutOfStock}
          value={quantity}
          onChange={(e) =>
            e.target.value !== quantity ? openFieldChange(e.target.value) : null
          }
          hasproducttypes={hasProductTypes ? hasProductTypes : undefined}
        />
        {hasProductTypes && (
          <Box
            sx={{
              minWidth: '30px',
              height: '100%',
              position: 'absolute',
              top: 0,
              right: 0,
              display: 'flex',
              flexDirection: 'column',
              borderLeft: `1px solid ${CUSTOM_THEME_COLORS.gray}`,
            }}
          >
            <QuantityTypeButton
              title={t('product.unit')}
              disabled={quantityType === 'unit'}
              selected={quantityType === 'unit'}
              onClick={() => setQuantityType('unit')}
              sx={{
                borderTopRightRadius: theme.shape.borderRadius,
              }}
            >
              UN
            </QuantityTypeButton>
            <QuantityTypeButton
              title={t('product.box')}
              disabled={quantityType === 'box'}
              selected={quantityType === 'box'}
              onClick={() => setQuantityType('box')}
              sx={{
                borderBottomRightRadius: theme.shape.borderRadius,
              }}
            >
              CX
            </QuantityTypeButton>
          </Box>
        )}
      </Box>

      <QuantitySelectorButton
        disabled={incrementDisabled}
        title={t('product.increaseQuantity')}
        onClick={() => increment()}
      >
        +
      </QuantitySelectorButton>
    </Box>
  );
};

export default QuantitySelector;
