import { Box, Button, Card, CardContent, CardMedia, Typography, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../constants/utils';
import { ProductCardContext } from '../contexts/ProductCardContext';
import { imageOnError } from '../helpers';
import { productDetailsInterface } from '../helpers/productDetailsInterface';
import { BadgeDiscount } from '../styles';
import {
  CUSTOM_THEME_COLORS,
  FONT_STYLE,
  PAGES_SLUGS,
  SETTINGS,
  TRANSITION_DURATION,
} from '../variables';
import ProductBadge from './ProductBadge';
import ProductStock from './ProductStock';

const ProductListCard = ({ product, userIsLogged, showRevPrice, showStockAvailability = true }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { addToCart, setProductVariants, setIsVariationsModalOpen, customerDiscount } =
    useContext(ProductCardContext);

  const { slug, title, brand, images, reference, saleAmount, badge, variants, itemsPerBox } =
    product;

  const productDetails = productDetailsInterface(product);
  const showCustomerDiscount = false;

  return (
    <Card
      raised
      sx={{
        marginY: 3,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        title={title}
        component={Link}
        to={`${PAGES_SLUGS.productDetail}/${slug}`}
        sx={{
          position: 'relative',
          maxWidth: '250px',
          flex: 1,
          overflow: 'hidden',
        }}
      >
        {badge && <ProductBadge badge={badge} />}

        <CardMedia
          component='img'
          image={images?.[0]}
          onError={imageOnError}
          alt={title}
          sx={{ maxWidth: '100%', objectFit: 'contain', aspectRatio: '1' }}
        />
      </Box>

      <CardContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flex: 2,
          padding: `${theme.spacing(2)} 0 ${theme.spacing(2)} ${theme.spacing(3)} !important`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            gap: 0.5,
          }}
        >
          {!!brand && (
            <Typography
              sx={{
                fontSize: '.75rem',
              }}
            >
              {brand}
            </Typography>
          )}

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 4,
            }}
          >
            <Typography
              sx={{
                display: 'flex',
                color: 'black',
                fontWeight: FONT_STYLE.bold,
                textDecoration: 'none',
                transition: `color ${TRANSITION_DURATION}ms ease`,
                '&:hover': {
                  color: CUSTOM_THEME_COLORS.primary,
                },
              }}
              title={title}
              component={Link}
              to={`${PAGES_SLUGS.productDetail}/${slug}`}
            >
              {title}
            </Typography>
            {showStockAvailability && <ProductStock stock={productDetails.stock} />}
          </Box>

          {!!reference && (
            <Typography
              variant='xsmall'
              sx={{ color: CUSTOM_THEME_COLORS.darkGray }}
            >{`Ref: ${reference}`}</Typography>
          )}

          {!!itemsPerBox && (
            <Typography
              variant='xsmall'
              sx={{
                color: CUSTOM_THEME_COLORS.darkGray,
              }}
            >
              {t('product.packaging')}: <strong>{itemsPerBox}</strong> uni.
            </Typography>
          )}

          {userIsLogged && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                columnGap: 3,
                rowGap: 1.25,
                marginTop: 1.5,
              }}
            >
              {productDetails.hasVariants && (
                <Typography
                  variant='xsmall'
                  sx={{
                    width: '100%',
                  }}
                >
                  {t('product.pricesFrom')}
                </Typography>
              )}

              <Box>
                <Typography
                  variant='xsmall'
                  sx={{
                    fontWeight: FONT_STYLE.bold,
                    marginBottom: 0.5,
                  }}
                >
                  {t('product.pvp')}
                </Typography>
                <Typography fontWeight={FONT_STYLE.light}>
                  {formatCurrency(productDetails.pvpPrice)}
                </Typography>
              </Box>

              {showRevPrice && (
                <>
                  {!!showCustomerDiscount && !!customerDiscount && (
                    <Box>
                      <Typography
                        variant='xsmall'
                        sx={{
                          fontWeight: FONT_STYLE.bold,
                          marginBottom: 0.5,
                        }}
                      >
                        {t('product.customerDiscount')}
                      </Typography>
                      <Typography>{`${customerDiscount}%`}</Typography>
                    </Box>
                  )}
                  <Box>
                    <Typography
                      variant='xsmall'
                      sx={{
                        fontWeight: FONT_STYLE.bold,
                        marginBottom: 0.5,
                      }}
                    >
                      {t('product.rev')}*
                    </Typography>

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          textDecoration: productDetails.onSale && 'line-through',
                          fontWeight: FONT_STYLE.bold,
                        }}
                      >
                        {formatCurrency(productDetails.price)}
                      </Typography>

                      {productDetails.onSale && (
                        <>
                          <Typography
                            sx={{
                              fontWeight: FONT_STYLE.bold,
                              color: CUSTOM_THEME_COLORS.primary,
                            }}
                          >
                            {formatCurrency(productDetails.salePrice)}
                          </Typography>
                          <BadgeDiscount>{`${saleAmount}%`}</BadgeDiscount>
                        </>
                      )}
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: '0.8rem',
                      fontWeight: FONT_STYLE.light,
                      width: '100%',
                    }}
                  >
                    {t('product.priceWithoutVat')}
                  </Typography>
                </>
              )}
            </Box>
          )}
        </Box>
        {!!SETTINGS.functionalities.variantsModal ? (
          <Button
            variant='contained'
            title={t('cart.addToCart')}
            onClick={() =>
              productDetails.hasVariants
                ? (setProductVariants(variants), setIsVariationsModalOpen(true))
                : addToCart(product)
            }
          >
            {t('cart.addToCart')}
          </Button>
        ) : (
          <>
            <Button
              variant='contained'
              component={Link}
              to={`${PAGES_SLUGS.productDetail}/${slug}`}
              title={t('product.seeProduct')}
            >
              {t('product.seeProduct')}
            </Button>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ProductListCard;
