import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import {
  cashOndelivery,
  creditCard,
  mbway,
  multibanco,
  paypal,
} from '../api/payment';
import { StyledTextButton } from '../styles';
import { Box, Typography } from '@mui/material';
import { CUSTOM_THEME_COLORS, FONT_STYLE, PAGES_SLUGS } from '../variables';
import { BsCheckCircle, BsClock } from 'react-icons/bs';
import { IconContext } from 'react-icons';
import { Link } from 'react-router-dom';
import MBWayPayment from '../components/Cart/Payments/MbWay';
import CreditCardPayment from '../components/Cart/Payments/CreditCard';
import PayPalPayment from '../components/Cart/Payments/Paypal';
import MultibancoPayment from '../components/Cart/Payments/Multibanco';
import { useTranslation } from 'react-i18next';
import CashOnDelivery from '../components/Cart/Payments/CashOnDelivery';

const PaymentContext = createContext();
export const PaymentProvider = ({ children }) => {
  const [paymentData, setPaymentData] = useState();
  const [isLimitedCustomer, setIsLimitedCustomer] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [orderResponse, setOrderResponse] = useState(null);
  const [mbwayModal, setMbWayModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const { t } = useTranslation();

  const handlePhoneNumberChange = useCallback((event) => {
    setPhoneNumber(event.target.value);
  }, []);

  const processPayment = useCallback(
    async (methodParams) => {
      try {
        let response;
        const { _id, total } = orderResponse;
        setLoading(true);
        switch (orderResponse.payment_type) {
          case 'credit_card':
            response = await creditCard(_id.toString(), total.toString());
            break;
          case 'mbway':
            response = await mbway(
              _id.toString(),
              total.toString(),
              methodParams,
              '',
              ''
            );
            break;
          case 'multibanco':
            const requestObj = {
              orderId: _id.toString(),
              amount: total.toString(),
              expiryDays: 0,
            };
            response = await multibanco(requestObj);
            break;
          case 'paypal':
            const products = orderResponse.items.map((item) => ({
              name: item.title || item.description,
              sku: item.reference,
              price: item.customer_price.toFixed(2),
              currency: 'EUR',
              quantity: item.quantity.unit,
            }));
            const shipping = {
              name: 'Shipping',
              price: orderResponse.shippingPrice.toString(),
              currency: 'EUR',
              quantity: 1,
            };
            const taxValue = (
              (orderResponse.shippingPrice + orderResponse.subtotal) *
              0.23
            ).toFixed(2);
            const taxes = {
              name: 'Taxes',
              price: taxValue.toString(),
              currency: 'EUR',
              quantity: 1,
            };
            const paymentData = {
              items: [...products, shipping, taxes],
              currency: 'EUR',
              total: total.toString(),
              description: '',
              orderId: _id.toString(),
            };
            response = await paypal(paymentData);
            break;
          case 'cashOnDelivery':
            const bodyObj = { orderId: _id, amount: total };
            response = await cashOndelivery(bodyObj);
            break;
          default:
            throw new Error(
              'Unknown payment type: ' + orderResponse.payment_type
            );
        }
        if (response) {
          setPaymentData(response);
          if (orderResponse.payment_type === 'credit_card')
            window.open(response.data.data.PaymentUrl);
          if (orderResponse.payment_type === 'paypal')
            window.open(response.data.approvalUrl);
        }
      } catch (error) {
        console.error('Payment error occurred', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [orderResponse]
  );

  const PaymentGateway = useCallback(() => {
    if (!orderResponse) return null;

    if (orderResponse.payment_status === 'pending') {
      return (
        <>
          <IconContext.Provider
            value={{
              style: {
                color: isLimitedCustomer
                  ? CUSTOM_THEME_COLORS.orange
                  : CUSTOM_THEME_COLORS.lightGreen,
              },
            }}
          >
            {orderResponse.payment_status === 'paid' ? (
              <BsCheckCircle size={80} />
            ) : (
              <BsClock size={80} />
            )}
          </IconContext.Provider>
          <Typography
            sx={{
              fontSize: '1.2rem',
              fontWeight: FONT_STYLE.bold,
              marginTop: { xs: 2, md: 3 },
            }}
          >
            {orderResponse.payment_status === 'pending'
              ? t('orderConfirmation.orderPending')
              : t('orderConfirmation.title')}
          </Typography>
          <Box marginTop={2.5}>
            {orderResponse.payment_type === 'credit_card' && (
              <CreditCardPayment paymentData={paymentData} />
            )}
            {orderResponse.payment_type === 'mbway' && (
              <MBWayPayment paymentData={paymentData} />
            )}
            {orderResponse.payment_type === 'multibanco' && (
              <MultibancoPayment paymentData={paymentData} />
            )}
            {orderResponse.payment_type === 'paypal' && (
              <PayPalPayment paymentData={paymentData} />
            )}
            {orderResponse.payment_type === 'cashOnDelivery' && (
              <CashOnDelivery />
            )}
            {![
              'credit_card',
              'mbway',
              'multibanco',
              'paypal',
              'cashOnDelivery',
            ].includes(orderResponse.payment_type) && (
              <Typography>
                {t('orderConfirmation.noPaymentSelected')}
              </Typography>
            )}
          </Box>
        </>
      );
    } else if (orderResponse.payment_status === 'paid') {
      return (
        <>
          <IconContext.Provider
            value={{
              style: {
                color: isLimitedCustomer
                  ? CUSTOM_THEME_COLORS.orange
                  : CUSTOM_THEME_COLORS.lightGreen,
              },
            }}
          >
            <BsCheckCircle size={80} />
          </IconContext.Provider>
          <Typography
            sx={{
              fontSize: '1.2rem',
              fontWeight: FONT_STYLE.bold,
              marginTop: { xs: 2, md: 3 },
            }}
          >
            {t('orderConfirmation.paymentConfirmation')}
          </Typography>
          <Box marginTop={2.5}>
            <Typography>{t('orderConfirmation.paymentProcessed')}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              marginY: { xs: 2, md: 4 },
            }}
          >
            <StyledTextButton
              title='Back to Homepage'
              variant='contained'
              color='secondary'
              component={Link}
              to='/'
            >
              {t('app.backToHomepageButton')}
            </StyledTextButton>
            <StyledTextButton
              title='Orders Page'
              variant='contained'
              component={Link}
              to={`${PAGES_SLUGS.account}/${PAGES_SLUGS.myAccount.orders}`}
            >
              {t('orderConfirmation.ordersPageButton')}
            </StyledTextButton>
          </Box>
        </>
      );
    }
  }, [orderResponse, isLimitedCustomer, paymentData, t]);

  const contextValue = useMemo(
    () => ({
      orderResponse,
      setOrderResponse,
      processPayment,
      PaymentGateway,
      setIsLimitedCustomer,
      isLimitedCustomer,
      loading,
      setLoading,
      error,
      setError,
      paymentData,
      mbwayModal,
      setMbWayModal,
      phoneNumber,
      setPhoneNumber,
      handlePhoneNumberChange,
    }),
    [
      orderResponse,
      processPayment,
      PaymentGateway,
      setIsLimitedCustomer,
      isLimitedCustomer,
      loading,
      setLoading,
      error,
      setError,
      paymentData,
      mbwayModal,
      setMbWayModal,
      phoneNumber,
      setPhoneNumber,
      handlePhoneNumberChange,
    ]
  );

  return (
    <PaymentContext.Provider value={contextValue}>
      {children}
    </PaymentContext.Provider>
  );
};

export const usePayment = () => {
  return useContext(PaymentContext);
};
