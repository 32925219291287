import axios from 'axios';
import { API_URL } from '../variables';
import { getHeaders } from './login';
import { mapProduct } from './mapApiInterface';

//  GET

function getCart(cartId) {
  return axios
    .get(`${API_URL}/cart/${cartId}`, getHeaders())
    .then(({ data: { cart } }) => {
      const { cart_items } = cart;
      const mappingProducts = cart_items?.map((product) => mapProduct(product));
      return { ...cart, cart_items: mappingProducts };
    });
}

function getCartUpdated(cartId) {
  return axios
    .get(`${API_URL}/cart/update-cart-items/${cartId}`, getHeaders())
    .then(({ data: { cartItems } }) => {
      return cartItems.map((product) => mapProduct(product));
      // return cartItems.map((product) =>
      //   mapProduct({
      //     ...product,
      //     stock:
      //       product.reference === '8720254668789'
      //         ? 5
      //         : product.reference === '8720618450685'
      //         ? 0
      //         : product.stock,
      //   }).filter((product) => product.reference !== 'less-652-bal-pack30')
      // );
    });
}

function getUser() {
  return axios
    .get(`${API_URL}/user`, getHeaders())
    .then(({ data: { user } }) => user);
}

function getPreviousOrders(customerId) {
  return axios
    .get(`${API_URL}/orders?customerId=${customerId}`, getHeaders())
    .then(({ data: { orders } }) =>
      orders.map((order) => ({
        ...order,
        items: order.items.map((product) => mapProduct(product)),
      }))
    );
}

function getReceipts(customerId) {
  return axios
    .get(`${API_URL}/orders/account?customerId=${customerId}`, getHeaders())
    .then(({ data: { account } }) => account);
}

function getProductUserHistory(customerId) {
  return axios
    .get(`${API_URL}/user/product-history/${customerId}`, getHeaders())
    .then(({ data: { productHistory } }) => {
      return productHistory.map((product) => mapProduct(product));
    });
}

function getUsers() {
  return axios
    .get(`${API_URL}/user/users`, getHeaders())
    .then(({ data: { users } }) => users);
}

// POST

function setCustomer(customerId) {
  return axios
    .post(`${API_URL}/user/customers/select-customer`, customerId, getHeaders())
    .then(({ data: { message } }) => message);
}

//  BEGIN users
function addUser(newUserData) {
  return axios
    .post(`${API_URL}/user/create/user`, newUserData, getHeaders())
    .then(({ data }) => data);
}

function updateUser(newUserData) {
  return axios
    .post(`${API_URL}/user/update/user`, newUserData, getHeaders())
    .then(({ data: user }) => user);
}

function deleteUser(email) {
  return axios
    .post(`${API_URL}/user/delete/user`, { email: email }, getHeaders())
    .then(({ data }) => data);
}
//  END users

function addAddress(newDeliveryAddress) {
  return axios
    .post(`${API_URL}/user/create/address`, newDeliveryAddress, getHeaders())
    .then(({ data: { addresses } }) => addresses);
}

function updateAddress(updatedAddress) {
  return axios
    .post(`${API_URL}/user/update/address`, updatedAddress, getHeaders())
    .then(({ data: { addresses } }) => addresses);
}

function addProductToUserHistory(reference, customerId) {
  return axios
    .post(
      `${API_URL}/user/product-history`,
      { reference, userId: customerId },
      getHeaders()
    )
    .then(({ data }) => data);
}

function approveOrder(orderId) {
  return axios
    .post(`${API_URL}/orders/update`, { orderId }, getHeaders())
    .then(({ data }) => data);
}

function duplicateOrder(customerId, orderId) {
  return axios
    .post(`${API_URL}/orders/duplicate`, { customerId, orderId }, getHeaders())
    .then(({ data: { products } }) =>
      products.map((product) => mapProduct(product))
    );
}

//  SHOPPING LIST

function getShoppingLists() {
  return axios
    .get(`${API_URL}/shopping-lists`, getHeaders())
    .then(({ data: { lists } }) =>
      lists.map((list) => ({
        ...list,
        products: list.products.map((product) => mapProduct(product)),
      }))
    );
}

function addShoppingList(name) {
  return axios
    .post(`${API_URL}/shopping-lists/create`, { name }, getHeaders())
    .then(({ data: { lists } }) =>
      lists.map((list) => ({
        ...list,
        products: list.products.map((product) => mapProduct(product)),
      }))
    );
}

function deleteShoppingList(id) {
  return axios
    .post(`${API_URL}/shopping-lists/${id}/delete`, {}, getHeaders())
    .then(({ data: { lists } }) =>
      lists.map((list) => ({
        ...list,
        products: list.products.map((product) => mapProduct(product)),
      }))
    );
}

function deleteProductFromShoppingList(listId, productReference) {
  return axios
    .post(
      `${API_URL}/shopping-lists/${listId}/remove`,
      { product_id: productReference },
      getHeaders()
    )
    .then(({ data: { lists } }) =>
      lists.map((list) => ({
        ...list,
        products: list.products.map((product) => mapProduct(product)),
      }))
    );
}

export {
  addAddress,
  addProductToUserHistory,
  addShoppingList,
  addUser,
  //  orders
  approveOrder,
  deleteProductFromShoppingList,
  deleteShoppingList,
  deleteUser,
  duplicateOrder,
  getCart,
  getCartUpdated,
  getPreviousOrders,
  getProductUserHistory,
  getReceipts,
  getShoppingLists,
  getUser,
  //  admin users
  getUsers,
  setCustomer,
  updateAddress,
  updateUser,
};
