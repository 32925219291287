import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../constants/utils';
import { CUSTOM_THEME_COLORS, FONT_STYLE } from '../../../variables';
import QuantitySelector from '../../Quantity/QuantitySelector';

const DiscountSelectorModal = ({
  isApplyDiscountOpen,
  setIsApplyDiscountOpen,
  updateCartItemDiscount,
  product,
}) => {
  const { t } = useTranslation();
  const {
    onSale,
    salePrice,
    price,
    onSaleBox,
    boxSalePrice,
    boxPrice,
    discount: productDiscount,
    quantity,
  } = product;

  const [discount, setDiscount] = useState(productDiscount ?? 0);
  const [openFieldError, setOpenFieldError] = useState(false);

  const productOnSale = onSale || onSaleBox;
  const productPrice = quantity.unit ? price : boxPrice;
  const productSalePrice = quantity.unit ? salePrice : boxSalePrice;
  const pricePerUnit = quantity.unit //  unit price
    ? onSale
      ? salePrice
      : price
    : onSaleBox //  box price
    ? boxSalePrice
    : boxPrice;
  const priceWithDiscount = openFieldError
    ? pricePerUnit
    : parseFloat(Math.fround(pricePerUnit - (pricePerUnit * discount) / 100).toFixed(2));

  const decrement = () =>
    discount >= 0 &&
    setDiscount((prevState) => {
      let incrementedDiscount = Number((prevState -= 1));
      return Math.floor(incrementedDiscount);
    });
  const increment = () =>
    discount <= 100 &&
    setDiscount((prevState) => {
      let incrementedDiscount = Number((prevState += 1));
      return Math.ceil(incrementedDiscount);
    });

  const openFieldChange = (e) => {
    const discountRules = e.match(/^(\d{1,2}(?:\.\d{1,2})?$)|(100)$/);

    if (!discountRules) {
      setOpenFieldError(true);
    } else {
      setOpenFieldError(false);
    }

    setDiscount(e);
  };

  return (
    <Dialog
      open={isApplyDiscountOpen}
      onClose={() => setIsApplyDiscountOpen(false)}
      PaperProps={{
        sx: {
          maxWidth: { xs: '100%', md: '600px' },
          margin: { xs: 2, md: 4 },
        },
      }}
    >
      <DialogTitle sx={{ fontWeight: FONT_STYLE.bold, paddingY: 3 }}>
        {t('cart.applySellerDiscount')}
      </DialogTitle>

      <DialogContent sx={{ paddingBottom: 3 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('tables.price')}</TableCell>
                <TableCell>{t('tables.priceWithDiscount')}</TableCell>
                <TableCell>{t('tables.discountPercentage')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        textDecoration: productOnSale && 'line-through',
                      }}
                    >
                      {formatCurrency(productPrice)}
                    </Typography>

                    {productOnSale && (
                      <>
                        <Typography
                          sx={{
                            fontWeight: FONT_STYLE.bold,
                            color: CUSTOM_THEME_COLORS.primary,
                          }}
                        >
                          {formatCurrency(productSalePrice)}
                        </Typography>
                      </>
                    )}
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      fontWeight: FONT_STYLE.medium,
                      color: CUSTOM_THEME_COLORS.red,
                    }}
                  >
                    {formatCurrency(priceWithDiscount)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <QuantitySelector
                    quantity={discount}
                    increment={increment}
                    decrement={decrement}
                    incrementDisabled={discount >= 100}
                    decrementDisabled={discount <= 0}
                    openFieldChange={openFieldChange}
                    openFieldHasError={openFieldError}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 2,
            marginTop: 3,
          }}
        >
          <Button
            title={t('app.cancelButton')}
            variant='contained'
            color='secondary'
            onClick={() => setIsApplyDiscountOpen(false)}
          >
            {t('app.cancelButton')}
          </Button>

          <Button
            title={t('cart.applySellerDiscount')}
            variant='contained'
            disabled={openFieldError}
            onClick={() => {
              if (openFieldError) return;
              setIsApplyDiscountOpen(false);
              updateCartItemDiscount(product, discount, priceWithDiscount);
            }}
          >
            {t('cart.applySellerDiscount')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DiscountSelectorModal;
