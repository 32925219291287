import {
  Badge,
  Box,
  FormControlLabel,
  FormGroup,
  Menu,
  MenuItem,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import capitalize from 'lodash/capitalize';
import { useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { FiBookmark, FiSettings, FiShoppingCart, FiUser } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../../../api/login';
import { resetCartState } from '../../../state/cartSlice';
import { setCategories } from '../../../state/categoriesSlice';
import { resetShoppingListsState } from '../../../state/shoppingListsSlice';
import { resetUserState, setPriceVisibility } from '../../../state/userSlice';
import { IconButton, LocaleSelector } from '../../../styles';
import { ACCOUNT_LINKS, FONT_STYLE, PAGES_SLUGS, SETTINGS } from '../../../variables';
import MenuMobile from '../MenuMobile';
import UserMenu from './UserMenu';
import { setLanguage } from '../../../api/configs';

const AccountLinks = () => {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { cart } = useSelector((state) => state.cart);
  const {
    role,
    personalInfo: { name: userName, email },
    priceIsVisible,
  } = useSelector((state) => state.user);

  const theme = useTheme();
  const MOBILE_DEVICES = useMediaQuery(theme.breakpoints.down('md'));

  const userRef = useRef(null);
  const settingsButtonRef = useRef(null);
  const [cookie, setCookie, removeCookie] = useCookies();
  const hasLogin = cookie.userToken;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const updateLanguageCookie = (language) => {
    //  expires in 60 days
    const expiresInSeconds = parseInt(60) * 24 * 60 * 60;

    //  set user token
    setCookie('language', language, {
      path: '/',
      maxAge: expiresInSeconds,
      secure: true,
      sameSite: true,
    });
  };

  const handleLanguage = (newLanguage) => {
    if (!!hasLogin) {
      setLanguage(newLanguage)
        .then(() => {
          setCurrentLanguage(newLanguage);
          changeLanguage(newLanguage);
          updateLanguageCookie(newLanguage);
        })
        .catch(({ response: { status } }) => {
          if (status === 401) navigate('/login');
        });
    } else {
      setCurrentLanguage(newLanguage);
      changeLanguage(newLanguage);
      updateLanguageCookie(newLanguage);
    }
  };

  function handleLogout() {
    setIsMenuOpen(false);
    setIsLoading(true);

    logout().then(() => {
      //  remove user token
      removeCookie('userToken', { path: '/' });

      //  reset states
      dispatch(resetUserState());
      dispatch(resetCartState());
      dispatch(resetShoppingListsState());
      dispatch(setCategories([]));

      setIsLoading(false);
      navigate('/');
    });
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: { xs: 0, md: 1 },
        marginRight: { xs: -1, md: 0 },
      }}
    >
      {SETTINGS.functionalities.hidePrices && !!hasLogin && role !== 'limitedcustomer' && (
        <>
          <IconButton
            title={t('app.definitions')}
            ref={settingsButtonRef}
            startIcon={<FiSettings size={24} />}
            onClick={() => setIsSettingsOpen(!isSettingsOpen)}
          />
          <Menu
            id='settings-menu'
            anchorEl={settingsButtonRef.current}
            open={isSettingsOpen}
            onClose={() => setIsSettingsOpen(false)}
          >
            <MenuItem>
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={!priceIsVisible} />}
                  title={t('productList.hidePrices')}
                  label={t('productList.hidePrices')}
                  slotProps={{ typography: { variant: 'small' } }}
                  onChange={() => dispatch(setPriceVisibility(!priceIsVisible))}
                  sx={{ marginRight: 0 }}
                />
              </FormGroup>
            </MenuItem>
          </Menu>
        </>
      )}

      {hasLogin && SETTINGS.functionalities.shoppingList && (
        <IconButton
          startIcon={<FiBookmark size={24} />}
          component={Link}
          to={`${PAGES_SLUGS.account}/${PAGES_SLUGS.myAccount.shoppingList}`}
          title={t('myAccount.shoppingLists.title')}
        />
      )}

      <IconButton
        startIcon={<FiShoppingCart size={24} />}
        component={Link}
        to={cookie.userToken ? PAGES_SLUGS.cart : PAGES_SLUGS.login}
        title={t('app.cart')}
        sx={{
          gap: { xs: 0.5, md: 1 },
        }}
      >
        {!!cart.length && (
          <Badge
            badgeContent={cart.reduce(
              (prev, item) => prev + item.quantity.unit + item.quantity.box,
              0
            )}
            sx={{ top: '-10px' }}
          />
        )}
      </IconButton>

      {SETTINGS.functionalities.myAccount &&
        (!hasLogin || isLoading ? (
          <IconButton
            startIcon={<FiUser size={24} />}
            component={Link}
            to='/login'
            title='Login'
          >
            <Typography
              sx={{
                display: { xs: 'none', md: 'block' },
                marginLeft: 1,
              }}
            >
              {t('app.login')}
            </Typography>
          </IconButton>
        ) : (
          <>
            <IconButton
              startIcon={<FiUser size={24} />}
              ref={userRef}
              onClick={() => setIsMenuOpen(true)}
              title={t('app.myAccount')}
            >
              <Typography
                sx={{
                  display: { xs: 'none', md: 'block' },
                  fontWeight: FONT_STYLE.medium,
                  marginLeft: 1,
                }}
              >
                {!!userName ? capitalize(userName.split(' ')[0]) : email}
              </Typography>
            </IconButton>
            <UserMenu
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
              userRef={userRef}
              accountLinks={ACCOUNT_LINKS?.[role]}
              handleLogout={handleLogout}
            />
          </>
        ))}

      <LocaleSelector
        select
        name='locale'
        value={currentLanguage}
        onChange={({ target: { value } }) => handleLanguage(value)}
      >
        {['pt', 'en', 'es'].map((language) => (
          <MenuItem
            key={`locale-option-${language}`}
            value={language}
            disabled={language === currentLanguage}
            sx={{
              textTransform: 'uppercase',
            }}
          >
            {language}
          </MenuItem>
        ))}
      </LocaleSelector>

      {MOBILE_DEVICES && <MenuMobile />}
    </Box>
  );
};

export default AccountLinks;
