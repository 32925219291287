import axios from 'axios';
import { API_URL } from '../variables';
import { getHeaders } from './login';

//  GET

function getPage(name) {
  return axios
    .get(`${API_URL}/pages/${name}`, getHeaders())
    .then(({ data: { page } }) => page);
}

function getCategories() {
  return axios
    .get(`${API_URL}/categories`, getHeaders())
    .then(({ data: { collections } }) => collections);
}

function getBrands(id) {
  return axios
    .get(id ? `${API_URL}/brands/${id}` : `${API_URL}/brands`, getHeaders())
    .then(({ data: { brands } }) => brands.filter((brand) => brand.status));
}

function getTranslations() {
  return axios
    .get(`${API_URL}/translations`, getHeaders())
    .then(({ data: { translations } }) => translations);
}

// POST

function sendContactForm(requestData) {
  return axios
    .post(`${API_URL}/forms/contact`, requestData, getHeaders())
    .then(({ data }) => data);
}

export { getBrands, getCategories, getPage, getTranslations, sendContactForm };
