import {
  Box,
  Button,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  styled,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { HiOutlineMenu } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { IconButton } from '../../styles';
import { CUSTOM_THEME_COLORS, FONT_STYLE, NAVBAR_LINKS } from '../../variables';
import CompanyLogo from '../CompanyLogo';
import { useTranslation } from 'react-i18next';

const SubmenuButton = styled(Button)(() => ({
  width: '100%',
  justifyContent: 'space-between !important',
}));

const SubmenuArrowButton = styled(Button)(({ theme }) => ({
  minWidth: 'auto',
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
}));

const MenuMobile = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const theme = useTheme();
  const collections = useSelector((state) => state.categories.categories);

  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
  const [isCollectionMenuOpened, setIsCollectionMenuOpened] = useState(false);
  const [isCategoryMenuOpened, setIsCategoryMenuOpened] = useState(false);
  const [isSubcategoryMenuOpened, setIsSubcategoryMenuOpened] = useState(false);

  useEffect(() => {
    if (isMobileMenuOpened === false) {
      setIsCollectionMenuOpened(false);
      setIsCategoryMenuOpened(false);
    }
    if (isCategoryMenuOpened) {
      setIsSubcategoryMenuOpened(false);
    }
  }, [isCategoryMenuOpened, isMobileMenuOpened]);

  const SubcategoriesMenu = ({ subcategories, index }) => {
    return (
      <Collapse
        in={isSubcategoryMenuOpened[index]}
        timeout='auto'
        unmountOnExit
        sx={{
          width: '100%',
        }}
      >
        <List>
          {subcategories.map(({ title, url }, index) => (
            <ListItem
              key={`menu-subcategories-${index}`}
              sx={{
                paddingY: 0,
              }}
            >
              <ListItemButton
                component={Link}
                to={url}
                onClick={() => setIsMobileMenuOpened(false)}
                sz={{
                  fontSize: '1rem',
                }}
              >
                {title?.[language] || title}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    );
  };

  const CategoriesMenu = ({ categories, index }) => {
    return (
      <Collapse
        in={isCategoryMenuOpened[index]}
        timeout='auto'
        unmountOnExit
        sx={{
          width: '100%',
        }}
      >
        <List>
          {categories.map(({ title, url, subcategories }, index) => (
            <ListItem
              key={`menu-categories-${index}`}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingY: 0,
                paddingRight: 0,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                }}
              >
                <ListItemButton
                  component={Link}
                  to={url}
                  onClick={() => setIsMobileMenuOpened(false)}
                  sx={{
                    fontSize: '1rem',
                    fontWeight: isSubcategoryMenuOpened[index] ? 600 : null,
                  }}
                >
                  {title?.[language] || title}
                </ListItemButton>
                {!!subcategories.length && (
                  <SubmenuArrowButton
                    onClick={() =>
                      setIsSubcategoryMenuOpened({
                        [index]: !isSubcategoryMenuOpened[index],
                      })
                    }
                  >
                    {isSubcategoryMenuOpened[index] ? (
                      <FiChevronUp size={16} />
                    ) : (
                      <FiChevronDown size={16} />
                    )}
                  </SubmenuArrowButton>
                )}
              </Box>
              {!!subcategories.length && (
                <SubcategoriesMenu
                  subcategories={subcategories}
                  index={index}
                />
              )}
            </ListItem>
          ))}
        </List>
      </Collapse>
    );
  };

  const CollectionsMenu = ({ collections }) => {
    return (
      <Collapse
        in={isCollectionMenuOpened}
        timeout='auto'
        unmountOnExit
        sx={{
          width: '100%',
        }}
      >
        <List>
          {collections.map(({ title, url, categories }, CategoriesIndex) => (
            <ListItem
              key={`menu-collections-${title}`}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingY: 0,
                backgroundColor:
                  isCategoryMenuOpened[CategoriesIndex] && CUSTOM_THEME_COLORS.lightGray,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                }}
              >
                <ListItemButton
                  component={Link}
                  to={url}
                  onClick={() => setIsMobileMenuOpened(false)}
                  sx={{
                    fontSize: '1rem',
                    fontWeight: isCategoryMenuOpened[CategoriesIndex] && 600,
                  }}
                >
                  {title}
                </ListItemButton>
                {!!categories.length && (
                  <SubmenuArrowButton
                    onClick={() =>
                      setIsCategoryMenuOpened({
                        [CategoriesIndex]: !isCategoryMenuOpened[CategoriesIndex],
                      })
                    }
                  >
                    {isCategoryMenuOpened[CategoriesIndex] ? (
                      <FiChevronUp size={16} />
                    ) : (
                      <FiChevronDown size={16} />
                    )}
                  </SubmenuArrowButton>
                )}
              </Box>
              {!!categories.length && (
                <CategoriesMenu
                  categories={categories}
                  index={CategoriesIndex}
                />
              )}
            </ListItem>
          ))}
        </List>
      </Collapse>
    );
  };

  return (
    <>
      <IconButton
        startIcon={<HiOutlineMenu size={28} />}
        onClick={() => setIsMobileMenuOpened(true)}
        title={t('app.menu')}
      />

      <Drawer
        open={isMobileMenuOpened}
        onClose={() => {
          setIsCategoryMenuOpened(false);
          setIsSubcategoryMenuOpened(false);
          setIsMobileMenuOpened(false);
        }}
        PaperProps={{
          style: {
            width: '100%',
            maxWidth: '85%',
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
          },
        }}
        sx={{
          '& > .MuiListItem-root': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        }}
      >
        <>
          <Box
            sx={{
              paddingX: 2,
            }}
          >
            <CompanyLogo link={false} />
          </Box>
          <List
            sx={{
              paddingY: 3,
              fontSize: '1.1rem',
            }}
          >
            {/* CATEGORIES MENU */}
            {!!collections.length && (
              <ListItem
                disablePadding
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <ListItemButton
                  component={SubmenuButton}
                  onClick={() => setIsCollectionMenuOpened((prevState) => !prevState)}
                  sx={{
                    fontSize: 'inherit',
                  }}
                >
                  {t('pages.allBrands')}
                  {isCollectionMenuOpened ? <FiChevronUp size={16} /> : <FiChevronDown size={16} />}
                </ListItemButton>
                <CollectionsMenu collections={collections} />
              </ListItem>
            )}
            {NAVBAR_LINKS.filter((menu) => menu.menuMobile).map(({ title, url }) => (
              <ListItem
                disablePadding
                key={`menu-mobile-${title}`}
              >
                <ListItemButton
                  component={NavLink}
                  to={url}
                  onClick={() => setIsMobileMenuOpened(false)}
                  sx={{
                    fontSize: 'inherit',
                    '&.active': {
                      fontWeight: FONT_STYLE.medium,
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {t(title)}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </>
      </Drawer>
    </>
  );
};

export default MenuMobile;
