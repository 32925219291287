import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getReceipts } from '../../../api/user';
import { formatCurrency } from '../../../constants/utils';
import { setReceipts } from '../../../state/userSlice';
import { MyAccountTitle } from '../../../styles';
import Loading from '../../Loading';
import AccountFilters from '../AccountFilters';
import ReceiptsTable from './ReceiptsTable';
// import ExportToExcelButton from './ExportToExcelButton';

const AccountReceipts = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const receipts = useSelector((state) => state.user.receipts);
  const user = useSelector((state) => state.user);

  const [entities, setEntities] = useState({ receipts: [] });
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [selectedYear, setSelectedYear] = useState('all');
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const customerId = user?.selectedClient?.id || user?.id;

  useEffect(() => {
    setIsLoading(true);

    getReceipts(customerId)
      .then((receipts) => {
        //  customize orders array
        receipts = receipts
          .map((receipt) => ({
            ...receipt,
            status: t(`app.${receipt.status}`),
          }))
          .reverse();
        dispatch(setReceipts(receipts));
        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, customerId, language]);

  useEffect(() => {
    const entities = { receipts };
    setEntities({ ...entities });
  }, [receipts]);

  const detailsMode = entities.receipts;

  const shownEntities = [...detailsMode];

  const filteredByStatus = shownEntities.filter(({ status }) => {
    if (selectedStatus === 'all') return true;

    return selectedStatus === status;
  });

  const filteredByYearAndStatus = filteredByStatus.filter(({ date }) => {
    if (selectedYear === 'all') return true;

    return selectedYear === new Date(date).getFullYear();
  });

  const filteredByEverything = filteredByYearAndStatus.filter(({ reference }) =>
    reference?.includes(search)
  );

  //  receipts balances
  let invoicedAmounts = 0;
  let openAmounts = 0;
  if (!!entities.receipts.length) {
    invoicedAmounts = entities.receipts?.reduce(
      (acc, invoice) =>
        parseFloat(Math.fround(acc + invoice?.value).toFixed(2)),
      0
    );
    openAmounts = entities.receipts?.reduce(
      (acc, invoice) =>
        parseFloat(Math.fround(acc + invoice?.balance).toFixed(2)),
      0
    );
  }

  return (
    <Box
      sx={{
        flex: 3,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box marginBottom={4}>
        <MyAccountTitle>{t('myAccount.receipts.title')}</MyAccountTitle>
        <Typography variant='small'>{t('myAccount.receipts.text')}</Typography>
      </Box>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          {!!entities.receipts.length && (
            <Box
              sx={{
                display: 'grid',
                gap: 0.5,
                marginBottom: 4,
              }}
            >
              <Typography variant='small'>
                {t('myAccount.receipts.invoicedAmounts')}
                <Typography
                  component='span'
                  sx={{
                    marginLeft: 1.5,
                    fontWeight: 600,
                  }}
                >
                  {formatCurrency(invoicedAmounts)}
                </Typography>
              </Typography>
              <Typography variant='small'>
                {t('myAccount.receipts.openValues')}
                <Typography
                  component='span'
                  sx={{
                    marginLeft: 1.5,
                    fontWeight: 600,
                  }}
                >
                  {formatCurrency(openAmounts)}
                </Typography>
              </Typography>
            </Box>
          )}

          {filteredByEverything.length !== 0 && (
            <>
              <AccountFilters
                type={detailsMode}
                selectedStatus={selectedStatus}
                selectedYear={selectedYear}
                setSelectedStatus={setSelectedStatus}
                setSelectedYear={setSelectedYear}
                search={search}
                setSearch={setSearch}
              />
              <ReceiptsTable receipts={filteredByEverything} />
              {/* <ExportToExcelButton isOrders={isOrders} /> */}
            </>
          )}

          {filteredByEverything.length === 0 && (
            <Typography>{t('myAccount.receipts.noDataAvailable')}</Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default AccountReceipts;
