import { LoadingButton } from '@mui/lab';
import { Box, TextField, Typography } from '@mui/material';
import React, { createRef, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons';
import { BsCheckCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { sendContactForm } from '../../api';
import { CUSTOM_THEME_COLORS, RECAPTCHA_SITEKEY } from '../../variables';
import PopupNotification from '../PopupNotification/PopupNotification';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    successMessage: t('contacts.requestSubmittedSuccessfullyMessage'),
    errorMessage: t('contacts.requestSubmittedErrorMessage'),
  });
  const [token, setToken] = useState();

  const recaptchaRef = createRef();

  const [formData, setFormData] = useState({
    name: undefined,
    email: undefined,
    phone: undefined,
    message: undefined,
    token: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((values) => ({ ...values, [name]: value }));
  };

  const verifyCaptcha = () => {
    if (token === '' || token === null || token === undefined) {
      setNotificationProps((prevState) => ({
        ...prevState,
        isOpened: true,
        type: 'error',
        errorMessage: t('beMessages.captchaValidation'),
      }));
      return false;
    }
    return true;
  };

  const handleCaptchaChange = useCallback(() => {
    const recaptchaValue = recaptchaRef.current.getValue();
    setToken(recaptchaValue);
  }, [recaptchaRef]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isCaptchaValid = verifyCaptcha();
    if (!isCaptchaValid) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    sendContactForm(formData)
      .then(() => {
        setIsLoading(false);
        setShowConfirmation(true);
        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
        }));
      })
      .catch(({ response: { status, data } }) => {
        const errorMessage =
          data?.msg || data?.errors?.[0]?.nestedErrors?.[0]?.msg;
        if (status === 401) {
          navigate('/login');
        } else if (status === 400 || status > 401) {
          setIsLoading(false);
          setNotificationProps((prevState) => ({
            ...prevState,
            isOpened: true,
            type: 'error',
            errorMessage: t(errorMessage),
          }));
        }
      });
  };

  const ConfirmationMessage = () => {
    return (
      <Box
        sx={{
          marginTop: { xs: 3, md: 4 },
        }}
      >
        <IconContext.Provider
          value={{
            style: { color: CUSTOM_THEME_COLORS.lightGreen },
          }}
        >
          <BsCheckCircle size={50} />
        </IconContext.Provider>
        <Typography
          sx={{
            marginTop: 1,
            fontWeight: 500,
          }}
        >
          {t('contacts.requestSubmittedSuccessfullyMessage')}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
      }}
    >
      <Typography variant='h2'>{t('contacts.contactRequest')}</Typography>
      <Typography marginTop={2}>
        <Trans i18nKey='contacts.contactRequestText' />
      </Typography>

      {showConfirmation ? (
        <ConfirmationMessage />
      ) : (
        <Box
          component='form'
          onSubmit={handleSubmit}
          autoComplete='off'
          sx={{
            width: { md: '75%' },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginX: 'auto',
            marginTop: 1,
          }}
        >
          <TextField
            fullWidth
            type='text'
            name='name'
            required
            label={t('forms.name')}
            value={formData.name}
            onChange={handleChange}
            error={formData.name === ''}
            helperText={formData.name === '' && t('forms.mandatoryField')}
          />
          <TextField
            fullWidth
            type='email'
            name='email'
            required
            label={t('forms.email')}
            value={formData.email}
            onChange={handleChange}
            error={formData.email === ''}
            helperText={formData.email === '' && t('forms.mandatoryField')}
          />
          <TextField
            fullWidth
            type='phone'
            name='phone'
            required
            label={t('forms.contact')}
            value={formData.phone}
            onChange={handleChange}
            error={formData.phone === ''}
            helperText={formData.phone === '' && t('forms.mandatoryField')}
          />
          <TextField
            name='message'
            label={t('forms.message')}
            required
            multiline
            rows={3}
            defaultValue=''
            fullWidth
            value={formData.message}
            onChange={handleChange}
            error={formData.message === ''}
            helperText={formData.message === '' && t('forms.mandatoryField')}
            sx={{ marginBottom: 5 }}
          />
          <ReCAPTCHA
            ref={recaptchaRef}
            onChange={handleCaptchaChange}
            sitekey={RECAPTCHA_SITEKEY}
            onSubmit={verifyCaptcha}
          />
          <LoadingButton
            variant='contained'
            type='submit'
            title={t('contacts.submitRequest')}
            loading={isLoading}
            sx={{
              marginTop: 3,
            }}
          >
            {t('contacts.submitRequest')}
          </LoadingButton>
        </Box>
      )}

      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </Box>
  );
};

export default ContactForm;
