import { Box, Button, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { ProductCardContext } from '../contexts/ProductCardContext';
import Modal, { ModalActions, ModalContent, ModalTitle } from './Modal';
import ProductVariants from './ProductPage/ProductVariants/ProductVariants';
import ProductVariantsDetails from './ProductPage/ProductVariants/ProductVariantsDetails';
import { useTranslation } from 'react-i18next';

const ProductVariantsModal = ({
  isVariationsModalOpen,
  setIsVariationsModalOpen,
  productsForCart,
  setProductsForCart,
}) => {
  const { t } = useTranslation();
  const { productVariants, addToCart } = useContext(ProductCardContext);
  const addToCartDisabled = !!productsForCart.totalUnits;

  return (
    <Modal
      isOpened={isVariationsModalOpen}
      setIsOpened={setIsVariationsModalOpen}
      muiComponentNativeProps={{
        onClose: () => {
          setIsVariationsModalOpen(false);
          //  reset products
          setProductsForCart((prevState) => ({ ...prevState, products: [] }));
        },
        sx: {
          minWidth: '600px',
        },
      }}
    >
      <ModalTitle>{t('productList.quickOrder')}</ModalTitle>
      <ModalContent>
        <ProductVariants
          variants={productVariants}
          productsForCart={productsForCart}
          setProductsForCart={setProductsForCart}
        />
      </ModalContent>
      <ModalActions
        sx={{
          justifyContent: 'space-between',
          marginTop: 0,
        }}
      >
        <ProductVariantsDetails
          productsForCart={productsForCart}
          setProductsForCart={setProductsForCart}
        />
        <Box>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'flex-end',
            }}
          >
            <Button
              title={t('app.cancelButton')}
              variant='contained'
              color='secondary'
              onClick={() => setIsVariationsModalOpen(false)}
            >
              {t('app.cancelButton')}
            </Button>

            <Button
              title={t('cart.addToCart')}
              variant='contained'
              disabled={!addToCartDisabled}
              onClick={() => addToCart()}
            >
              {t('cart.addToCart')}
            </Button>
          </Box>
          <Typography
            variant='xsmall'
            sx={{
              textAlign: 'right',
              marginTop: 1.5,
            }}
          >
            {t('product.priceWithoutVat')}
          </Typography>
        </Box>
      </ModalActions>
    </Modal>
  );
};

export default React.memo(ProductVariantsModal);
