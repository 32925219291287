import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCountries } from '../../../api/configs';
import { MyAccountTitle } from '../../../styles';
import AddressesForm from './AddressesForm';
import NewAddressModal from './NewAddressModal';

const AccountAddresses = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const addresses = useSelector((state) => state.user.addresses);

  const [isCreateNewAddressOpen, setIsCreateNewAddressOpen] = useState(false);
  const [availableCountries, setAvailableCountries] = useState([]);

  useEffect(() => {
    getCountries()
      .then((data) => setAvailableCountries(data))
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!addresses) return null;

  return (
    <Box
      sx={{
        flex: 3,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          marginBottom: 4,
        }}
      >
        <MyAccountTitle>{t('myAccount.addresses.title')}</MyAccountTitle>
        <Typography variant='small'>{t('myAccount.addresses.text')}</Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'initial' },
        }}
      >
        <AddressesForm
          openNewAddressModal={setIsCreateNewAddressOpen}
          addresses={addresses}
          availableCountries={availableCountries}
        />
      </Box>

      <NewAddressModal
        isOpen={isCreateNewAddressOpen}
        setIsOpen={setIsCreateNewAddressOpen}
      />
    </Box>
  );
};

export default AccountAddresses;
