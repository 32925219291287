import { createSlice } from '@reduxjs/toolkit';

const initialState = {    
    _id: '',
    order_number: null,
    erp_customer_id: null,
    erp_estab: null,
    customer: '',
    seller_id: null,
    seller_name: '',
    user_id: '',
    payment_status: '',
    payment_type: '',
    customer_id: '',
    admin_id: '',
    customer_discount: null,
    billingAddress: {},
shippingAddress: {},
shippingPrice: null,
expeditionType: '',
created_date: '',
deliveryDate: '',
subtotal: null,
total: null,
totalDiscount: null,
globalDiscountPercentage: null,
taxes: [],
items: [],
paymentId: null,
token: null,
payerId: null,
amount : '',
requestId: '',
paymentDatetime: '',
mbReference: '',
mbEntity: '',
sk: '',
brand: '',
pan: ''
};


const orderSlice = createSlice({
    name: 'orderReducer',
    initialState,
reducers: {
    setOrderData: (state, action) => {
      Object.assign(state, action.payload);
    },
    setPendingOrder: (state, action) => {
      Object.assign(state, action.payload);
    },
    setQueryParams: (state, action) => {
        const { paymentId, token, payerId, amount, requestId, paymentDatetime, mbReference, mbEntity, sk, brand, pan } = action.payload;
        state.paymentId = paymentId ?? '';
        state.token = token ?? '';
        state.payerId = payerId ?? '';
        state.amount = amount ?? ''
        state.requestId = requestId ?? '';
        state.paymentDatetime = paymentDatetime ?? '';
        state.mbReference = mbReference ?? '';
        state.mbEntity = mbEntity ?? '';
        state.sk = sk ?? '';
        state.brand = brand;
        state.pan = pan ?? ''
      },
      clearOrderData: (state) => {
        Object.assign(state, initialState);
    }
  }
});

export const { setOrderData, setPendingOrder, setQueryParams, clearOrderData } = orderSlice.actions;

export default orderSlice;