import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../../constants/utils';
import { BadgeDiscount, BadgeDiscountOutlined } from '../../../styles';
import { CUSTOM_THEME_COLORS, FONT_STYLE } from '../../../variables';

const ProductPrices = ({ product }) => {
  const { t } = useTranslation();
  const { price, onSale, saleAmount, salePrice, pvpPrice } = product;

  const userstate = useSelector((state) => state.user);
  const customerDiscount =
    userstate.selectedClient.customerDiscount || userstate.customerDiscount;
  const cumulativeDiscounts =
    userstate.selectedClient.cumulativeDiscounts ||
    userstate.cumulativeDiscounts;

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: { xs: 4, sm: 8 },
        rowGap: 2,
        marginTop: 3,
      }}
    >
      <Box>
        <Typography
          sx={{
            marginBottom: 0.5,
            fontWeight: FONT_STYLE.medium,
          }}
        >
          {t('product.pvp')}
        </Typography>

        <Typography>{formatCurrency(pvpPrice)}</Typography>
      </Box>

      {!!customerDiscount && !!cumulativeDiscounts && (
        <Box>
          <Typography
            sx={{
              marginBottom: 0.5,
              fontWeight: FONT_STYLE.medium,
            }}
          >
            {t('product.customerDiscount')}
          </Typography>
          <BadgeDiscountOutlined>{`${customerDiscount}%`}</BadgeDiscountOutlined>
        </Box>
      )}

      <Box>
        <Typography
          sx={{
            marginBottom: 0.5,
            fontWeight: FONT_STYLE.medium,
          }}
        >
          {t('product.rev')}*
        </Typography>

        <Box
          sx={{
            display: 'flex',
            gap: 1.5,
          }}
        >
          <Typography
            sx={{
              textDecoration: onSale && 'line-through',
              fontWeight: FONT_STYLE.bold,
            }}
          >
            {formatCurrency(price)}
          </Typography>

          {onSale && (
            <>
              <Typography
                sx={{
                  fontWeight: FONT_STYLE.bold,
                  color: CUSTOM_THEME_COLORS.primary,
                }}
              >
                {formatCurrency(salePrice)}
              </Typography>
              <BadgeDiscount>{`${saleAmount}%`}</BadgeDiscount>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProductPrices;
