import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addProductsToCart } from '../api/products';
import PopupNotification from '../components/PopupNotification/PopupNotification';
import CreateShoppingListModal from '../components/ShoppingLists/CreateShoppingListModal';
import DeleteShoppingListModal from '../components/ShoppingLists/DeleteShoppingListModal';
import ShoppingListProducts from '../components/ShoppingLists/ShoppingListProducts';
import { checkStockAvailableInCart } from '../helpers/checkStockAvailableInCart';
import {
  setCartState,
  setCartSubtotal,
  setCartTaxes,
  setCartTotal,
  setDiscount,
  updateCart,
} from '../state/cartSlice';
import { MyAccountTitle } from '../styles';

const ShoppingListsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { cart } = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user);
  const { id, selectedClient, role } = user;
  const customerId = selectedClient?.id || id;
  const isLimitedCustomer = role === 'limitedcustomer';
  const shoppingLists = useSelector((state) => state.shoppingLists.shoppingLists);

  const [index, setIndex] = useState(0);
  const [productsAvailability, setProductsAvailability] = useState({
    available: [],
    unavailable: [],
  });
  const [isAddShoppingListOpen, setIsAddShoppingListOpen] = useState(false);
  const [isDeleteShoppingListOpen, setIsDeleteShoppingListOpen] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    type: '',
    successMessage: '',
    errorMessage: '',
  });
  const activeShoppingList = shoppingLists?.[index];

  useEffect(() => {
    if (shoppingLists.length === 0) return;
    setProductsAvailability(checkStockAvailableInCart(activeShoppingList?.products, cart));
  }, [activeShoppingList?.products, cart, shoppingLists]);

  const addShoppingListProductsToCart = () => {
    if (productsAvailability.available.length === 0) return;

    addProductsToCart(productsAvailability.available, customerId)
      .then(({ cart_items, discounts, total_discount, taxes, subtotal, total }) => {
        dispatch(
          setCartState({
            discounts: discounts,
          })
        );
        dispatch(updateCart(cart_items));
        dispatch(setDiscount(total_discount));
        dispatch(setCartTaxes(taxes));
        dispatch(setCartSubtotal(subtotal));
        dispatch(setCartTotal(total));

        if (productsAvailability.unavailable.length > 0) {
          setNotificationProps({
            isOpened: true,
            type: 'warning',
            warningMessage: t('cart.notAllProductsHaveBeenAddedToCart'),
          });
        } else {
          setNotificationProps((prevState) => ({
            ...prevState,
            isOpened: true,
            type: 'success',
            successMessage: t('cart.productAddedToCartMessage'),
          }));
        }
      })
      .catch(
        ({
          response: {
            status,
            data: { msg },
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: t(msg),
            }));
          }
        }
      );

    return;
  };

  console.log(30, shoppingLists);

  return (
    <>
      <Box
        sx={{
          flex: 3,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box marginBottom={4}>
          <MyAccountTitle>{t('myAccount.shoppingLists.title')}</MyAccountTitle>
          <Typography variant='small'>{t('myAccount.shoppingLists.text')}</Typography>
        </Box>

        {shoppingLists.length !== 0 ? (
          <Box>
            <FormControl
              sx={{
                width: { xs: '100%', md: '30%' },
              }}
            >
              <InputLabel>{t('myAccount.shoppingLists.selectedList')}</InputLabel>
              <Select
                label={t('myAccount.shoppingLists.selectedList')}
                value={index}
                onChange={(e) => setIndex(e.target.value)}
              >
                {shoppingLists.map(({ name, _id }, index) => (
                  <MenuItem
                    key={`shopping-list-${_id}`}
                    value={index}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <ShoppingListProducts
              index={index}
              shoppingLists={shoppingLists}
            />
          </Box>
        ) : (
          <Typography>{t('myAccount.shoppingLists.noListsCreated')}</Typography>
        )}

        <Box
          sx={{
            display: 'flex',
            gap: { xs: 1.5, sm: 2 },
            marginTop: 2.5,
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'center', sm: 'flex-start' },
          }}
        >
          {shoppingLists.length !== 0 && (
            <Button
              variant='contained'
              title={t('myAccount.shoppingLists.removeShoppingList')}
              color='secondary'
              onClick={() => setIsDeleteShoppingListOpen(true)}
            >
              {t('myAccount.shoppingLists.removeShoppingList')}
            </Button>
          )}

          <Button
            variant='contained'
            color='secondary'
            title={t('myAccount.shoppingLists.addShoppingList')}
            endIcon={<FiPlus size={14} />}
            onClick={() => setIsAddShoppingListOpen(true)}
          >
            {t('myAccount.shoppingLists.addShoppingList')}
          </Button>

          {shoppingLists.length !== 0 && activeShoppingList?.products.length !== 0 && (
            <Button
              variant='contained'
              title={t('myAccount.shoppingLists.addProductsToCart')}
              disabled={productsAvailability.available.length === 0}
              onClick={() => addShoppingListProductsToCart()}
            >
              {t('myAccount.shoppingLists.addProductsToCart')}
            </Button>
          )}

          {!isLimitedCustomer &&
            activeShoppingList?.products.length !== 0 &&
            shoppingLists.length !== 0 && (
              <Box
                sx={{
                  marginLeft: { sm: 'auto' },
                }}
              >
                <Typography
                  variant='xsmall'
                  sx={{
                    textAlign: { sm: 'right' },
                  }}
                >
                  {t('product.priceWithoutVat')}
                </Typography>
              </Box>
            )}
        </Box>

        <CreateShoppingListModal
          isOpen={isAddShoppingListOpen}
          setIsOpen={setIsAddShoppingListOpen}
        />

        <DeleteShoppingListModal
          isOpen={isDeleteShoppingListOpen}
          setIsOpen={setIsDeleteShoppingListOpen}
          setIndex={setIndex}
          shoppingLists={shoppingLists}
        />
      </Box>

      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </>
  );
};

export default ShoppingListsPage;
