import { Box, Typography } from '@mui/material';
import { CUSTOM_THEME_COLORS, FONT_STYLE, PAGES_SLUGS } from '../../../variables';
// import ProductStock from '../../ProductStock';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { addProductsToCart } from '../../../api/products';
import {
  setCartState,
  setCartSubtotal,
  setCartTaxes,
  setCartTotal,
  setDiscount,
  updateCart,
} from '../../../state/cartSlice.js';
import { BadgeDiscountOutlined } from '../../../styles.js';
import PopupNotification from '../../PopupNotification/PopupNotification';
import ProductStock from '../../ProductStock';
import ProductVariants from '../ProductVariants/ProductVariants';
import ProductCheckout from './ProductCheckout';
import ProductPrices from './ProductPrices';

const ProductDetails = ({ product, userIsLogged, customerId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { cart } = useSelector((state) => state.cart);
  const userstate = useSelector((state) => state.user);
  const customerDiscount = userstate.selectedClient.customerDiscount || userstate.customerDiscount;
  const cumulativeDiscounts =
    userstate.selectedClient.cumulativeDiscounts || userstate.cumulativeDiscounts;

  const { title, description, reference, stock, itemsPerBox, variants, brand } = product;
  const hasVariants = variants.length;
  const variantsWithoutStock = variants.filter((variant) => variant.stock === 0);
  const stockProduct = !hasVariants
    ? stock
    : variantsWithoutStock.length === variants.length
    ? 0
    : 1;

  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    type: '',
    successMessage: '',
    errorMessage: '',
  });

  const [productsForCart, setProductsForCart] = useState({
    products: [],
    totalUnits: 0,
    total: 0,
  });

  const [variantsCombinedWithCartQuantities, setVariantsCombinedWithCartQuantities] =
    useState(variants);

  useEffect(() => {
    cart.length !== 0 &&
      setVariantsCombinedWithCartQuantities(
        variants.map((variant) => {
          const productCart = cart.find(
            (cartProduct) => cartProduct.reference === variant.reference
          );
          return productCart
            ? {
                ...variant,
                quantity: { ...variant.quantity, unit: 0 },
                stock:
                  variant.stock - productCart.quantity.unit > 0
                    ? variant.stock - productCart.quantity.unit
                    : 0,
              }
            : variant;
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  const addToCart = () => {
    addProductsToCart(productsForCart.products, customerId)
      .then(({ cart_items, discounts, total_discount, taxes, subtotal, total }) => {
        dispatch(
          setCartState({
            discounts: discounts,
          })
        );
        dispatch(updateCart(cart_items));
        dispatch(setDiscount(total_discount));
        dispatch(setCartTaxes(taxes));
        dispatch(setCartSubtotal(subtotal));
        dispatch(setCartTotal(total));

        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
          successMessage: t('cart.productAddedToCartMessage'),
        }));
      })
      .catch(
        ({
          response: {
            status,
            data: { msg },
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: t(msg),
            }));
          }
        }
      );
  };

  return (
    <>
      <Box
        sx={{
          flex: 1,
        }}
      >
        {brand && (
          <Typography
            marginBottom={1}
            sx={{ fontSize: '.8em' }}
          >
            {brand}
          </Typography>
        )}

        <Typography variant='h1'>{title}</Typography>

        {reference && (
          <Typography
            marginTop={1}
            sx={{ fontSize: '.85em' }}
          >{`Ref: ${reference}`}</Typography>
        )}

        {!!!stockProduct && (
          <Box sx={{ marginTop: 1 }}>
            <ProductStock stock={stockProduct} />
          </Box>
        )}

        {!!description && (
          <Typography
            marginTop={2}
            sx={{ whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}

        {!!itemsPerBox && (
          <Typography marginTop={2}>
            <Trans
              i18nKey='product.packagingCount'
              count={itemsPerBox}
            />
          </Typography>
        )}

        {hasVariants ? (
          <Box
            sx={{
              display: 'grid',
              gap: 3,
              marginTop: 3,
            }}
          >
            {userIsLogged && !!customerDiscount && !!cumulativeDiscounts && (
              <Box>
                <Typography
                  sx={{
                    marginBottom: 0.5,
                    fontWeight: FONT_STYLE.medium,
                  }}
                >
                  {t('product.customerDiscount')}
                </Typography>
                <BadgeDiscountOutlined>{`${customerDiscount}%`}</BadgeDiscountOutlined>
              </Box>
            )}
            <ProductVariants
              variants={variantsCombinedWithCartQuantities}
              userIsLogged={userIsLogged}
              productsForCart={productsForCart}
              setProductsForCart={setProductsForCart}
            />
          </Box>
        ) : (
          userIsLogged &&
          userstate.role !== 'limitedcustomer' && <ProductPrices product={product} />
        )}

        {userIsLogged ? (
          <ProductCheckout
            product={product}
            hasVariants={hasVariants}
            addToCart={addToCart}
            productsForCart={productsForCart}
            setProductsForCart={setProductsForCart}
          />
        ) : (
          <Box sx={{ marginTop: 3 }}>
            <Trans
              i18nKey='product.productPricesLogin'
              components={[
                <Link
                  to={PAGES_SLUGS.login}
                  style={{ color: CUSTOM_THEME_COLORS.primary, fontWeight: 600 }}
                />,
              ]}
            />
          </Box>
        )}
      </Box>
      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </>
  );
};

export default ProductDetails;
