import { useSelector } from 'react-redux';
import ShoppingListSelectorModal from './ShoppingListSelectorModal';

const AddShoppingListButton = ({ productReference, modalIsOpen, setModalIsOpen, children }) => {
  const shoppingLists = useSelector((state) => state.shoppingLists.shoppingLists);

  return (
    <>
      {children}
      <ShoppingListSelectorModal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        shoppingLists={shoppingLists}
        productReference={productReference}
      />
    </>
  );
};

export default AddShoppingListButton;
