import { Box, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { updateUser } from '../../../api/user';
import { compareTwoObjects, objectIsEmpty } from '../../../helpers';
import { setPersonalInfo } from '../../../state/userSlice';
import { MyAccountTitle } from '../../../styles';
import PopupNotification from '../../PopupNotification/PopupNotification';
import CompanyInfo from './CompanyInfo';
import PersonalInfo from './PersonalInfo';
import SellerInfo from './SellerInfo';

const AccountInfo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [_SMALL_DEVICES] = useOutletContext();

  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    successMessage: t('myAccount.personalData.successMessage'),
  });

  const userState = useSelector((state) => state.user);
  const { personalInfo, companyInfo, sellerInfo } =
    userState.role === 'seller' && !objectIsEmpty(userState.selectedClient)
      ? userState.selectedClient
      : userState;

  const [userData, setUserData] = useState({ ...personalInfo });
  const [userDataChanged, setUserDataChanged] = useState(false);

  useEffect(() => {
    setUserData({ ...personalInfo });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.selectedClient?.id]);

  useEffect(() => {
    if (!personalInfo && !userData) return;

    const userDataDifferences = compareTwoObjects(personalInfo, userData);
    const userDataHasDifferences = !objectIsEmpty(userDataDifferences);

    if (userDataHasDifferences) {
      if (!userDataChanged) setUserDataChanged(true);
    } else {
      if (userDataChanged) setUserDataChanged(false);
    }
  }, [userData, personalInfo, userDataChanged]);

  function handleChange(e) {
    const { name, value } = e.target;
    setUserData((values) => ({ ...values, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (!userData) return;

    setIsLoading(true);

    updateUser(userData)
      .then(({ user: { personalInfo } }) => {
        dispatch(setPersonalInfo(personalInfo));
        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
        }));
        setIsLoading(false);
      })
      .catch(
        ({
          response: {
            status,
            data: { errors },
          },
        }) => {
          if (status === 422) {
            setUserData((values) => ({ ...values, [errors?.[0]?.param]: '' }));
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: t(errors?.[0]?.msg),
            }));
          }
          setIsLoading(false);
        }
      );
  }

  return (
    <>
      <Box
        component='form'
        onSubmit={handleSubmit}
        sx={{
          flex: 3,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box>
          <MyAccountTitle>{t('myAccount.personalData.companyInformations')}</MyAccountTitle>
          <Typography variant='small'>
            {t('myAccount.personalData.companyInformationsInfo')}
          </Typography>
          <Box marginTop={1}>
            <CompanyInfo data={companyInfo} />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
            gridColumnGap: theme.spacing(2),
            gridRowGap: theme.spacing(4),
            marginTop: 4,
          }}
        >
          <Box>
            <MyAccountTitle>{t('myAccount.personalData.contactInformations')}</MyAccountTitle>
            <PersonalInfo
              handleChange={handleChange}
              data={userData}
              userRole={userState.role}
              isLoading={isLoading}
              userDataChanged={userDataChanged}
            />
          </Box>
          {userState.role !== 'limitedcustomer' && (
            <Box>
              <MyAccountTitle>{t('myAccount.personalData.sellerInformations')}</MyAccountTitle>
              <SellerInfo data={sellerInfo} />
            </Box>
          )}
        </Box>
      </Box>

      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </>
  );
};

export default AccountInfo;
