import { Box, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { StyledTableSelect } from '../../styles';
import { SETTINGS } from '../../variables';

const ProductsSortBy = ({ options }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const sortByParam = searchParams.get('sortBy');

  const handleSortBy = (e) => {
    searchParams.set('sortBy', e.target.value);
    setSearchParams(searchParams);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography
        variant='small'
        sx={{
          fontWeight: '700',
        }}
      >
        {t('productList.orderBy')}
      </Typography>
      <StyledTableSelect
        value={sortByParam || SETTINGS.defaults.productList.sortBy}
        onChange={handleSortBy}
        size='small'
        autoWidth
      >
        {options.map((type) => (
          <MenuItem
            key={`orderBy-option-${type.id}`}
            value={type.id}
            variant='small'
          >
            {type.text}
          </MenuItem>
        ))}
      </StyledTableSelect>
    </Box>
  );
};

export default ProductsSortBy;
