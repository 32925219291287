import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import { StyledTextButton } from '../../../../styles';
import NewAddressModal from '../../../UserAccount/AccountAddresses/NewAddressModal';

const DeliveryAtAddress = ({
  address,
  onAddressChange,
  role,
  clientAddresses,
}) => {
  const { t } = useTranslation();
  const [isAddAddressOpen, setIsAddAddressOpen] = useState(false);

  return (
    <>
      <FormControl
        margin='normal'
        sx={{ marginY: 2 }}
      >
        <InputLabel>{t('checkout.selectAddress')}</InputLabel>

        <Select
          label={t('checkout.selectAddress')}
          value={address.street || ''}
          onChange={onAddressChange}
        >
          {clientAddresses.map(({ street }, index) => (
            <MenuItem
              key={`${street}-${index}`}
              index={index}
              value={street}
            >
              {street}
            </MenuItem>
          ))}
        </Select>

        {role !== 'seller' && (
          <StyledTextButton
            variant='contained'
            color='secondary'
            value='addNewAddress'
            title={t('checkout.addAddress')}
            onClick={() => setIsAddAddressOpen(true)}
            endIcon={<FiPlus size={14} />}
            sx={{
              alignSelf: ' flex-start',
              marginTop: 2,
            }}
          >
            {t('checkout.addAddress')}
          </StyledTextButton>
        )}
      </FormControl>

      <NewAddressModal
        isOpen={isAddAddressOpen}
        setIsOpen={setIsAddAddressOpen}
      />
    </>
  );
};

export default DeliveryAtAddress;
