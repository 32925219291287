import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import ContactForm from '../components/ContactForm/ContactForm';
import StoreLocations from '../components/StoreLocations';
import { MainContainer } from '../styles';
import { useTranslation } from 'react-i18next';

const ContactsPage = () => {
  const { t } = useTranslation();
  return (
    <MainContainer>
      <Container>
        <Grid container>
          <Grid xs={12}>
            <Box
              sx={{
                textAlign: 'center',
              }}
            >
              <Typography variant='h1'>{t('contacts.title')}</Typography>
              <Typography marginTop={2}>{t('contacts.text')}</Typography>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box marginTop={{ xs: 3, md: 6 }}>
              <StoreLocations />
            </Box>
          </Grid>
          <Grid xs={12} md={6} mdOffset={3}>
            <Box marginTop={{ xs: 5, md: 6 }}>
              <ContactForm />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default ContactsPage;
