export const checkStockAvailableInCart = (newProducts, cartProducts) => {
  return newProducts
    .map((product) => ({ ...product, quantity: { ...product.quantity, unit: 1 } }))
    .reduce(
      (acc, product) => {
        const productCart = cartProducts.find((a) => a.reference === product.reference);
        productCart === undefined
          ? product.stock !== 0
            ? acc.available.push(product)
            : acc.unavailable.push(product)
          : productCart.quantity.unit + product.quantity.unit <= productCart.stock
          ? acc.available.push(product)
          : acc.unavailable.push(product);
        return acc;
      },
      { available: [], unavailable: [] }
    );
};
