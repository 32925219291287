import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import cartReducer from './cartSlice';
import categoriesReducer from './categoriesSlice';
import productSlice from './productSlice';
import productsReducer from './productsSlice';
import shoppingListsSlice from './shoppingListsSlice';
import userReducer from './userSlice';
import translationsSlice from './translationsSlice';
import orderSlice from './orderSlice';

const reducers = combineReducers({
  user: userReducer,
  categories: categoriesReducer,
  products: productsReducer,
  product: productSlice,
  cart: cartReducer,
  shoppingLists: shoppingListsSlice,
  translations: translationsSlice,
  order: orderSlice.reducer
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export default store;
