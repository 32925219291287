import { Box, Dialog, MenuItem, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCountries } from '../../../api/configs';
import { addAddress } from '../../../api/user';
import { setAddresses } from '../../../state/userSlice';
import { StyledTextButton } from '../../../styles';
import { FONT_STYLE } from '../../../variables';
import PopupNotification from '../../PopupNotification/PopupNotification';
import { useTranslation } from 'react-i18next';

const NewAddressModal = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    type: '',
    successMessage: t('myAccount.addresses.successMessage2'),
    errorMessage: '',
  });
  const [newDeliveryAddress, setNewDeliveryAddress] = useState({
    street: '',
    zipCode: '',
    city: '',
    country: '',
  });
  const [availableCountries, setAvailableCountries] = useState([]);

  useEffect(() => {
    getCountries()
      .then((data) => setAvailableCountries(data))
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    setNewDeliveryAddress((values) => ({ ...values, [name]: value }));
  }

  const handleCloseDialog = () => {
    setNewDeliveryAddress({
      street: '',
      zipCode: '',
      city: '',
      country: '',
    });
    setIsOpen(false);
  };

  function handleSubmit(e) {
    e.preventDefault();

    if (!newDeliveryAddress) return;

    addAddress(newDeliveryAddress)
      .then((addresses) => {
        dispatch(setAddresses(addresses));

        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
        }));

        setNewDeliveryAddress({
          street: '',
          zipCode: '',
          city: '',
          country: '',
        });

        setIsOpen(false);
      })
      .catch(
        ({
          response: {
            data: { errors },
            status,
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status > 401) {
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: t(errors?.[0]?.msg),
            }));
          }
        }
      );
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          handleCloseDialog();
        }}
        PaperProps={{
          sx: {
            width: { xs: '80%', md: '40%' },
            padding: 4,
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: FONT_STYLE.bold,
            textTransform: 'uppercase',
            marginBottom: 1,
          }}
        >
          {t('myAccount.addresses.newAddress')}
        </Typography>

        <Box
          component='form'
          onSubmit={handleSubmit}
        >
          <Box>
            <TextField
              fullWidth
              multiline
              required
              type='text'
              name='street'
              label={t('forms.address')}
              value={newDeliveryAddress.street}
              onChange={handleChange}
              error={!newDeliveryAddress.street}
              helperText={
                !newDeliveryAddress.street && t('forms.mandatoryField')
              }
            />

            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: { xs: 0, md: 2 },
              }}
            >
              <TextField
                type='text'
                name='zipCode'
                label={t('forms.postalCode')}
                required
                value={newDeliveryAddress.zipCode}
                onChange={handleChange}
                error={!newDeliveryAddress.zipCode}
                helperText={
                  !newDeliveryAddress.zipCode && t('forms.mandatoryField')
                }
                sx={{ flex: 1 }}
              />

              <TextField
                type='text'
                name='city'
                label={t('forms.city')}
                required
                value={newDeliveryAddress.city}
                onChange={handleChange}
                error={!newDeliveryAddress.city}
                helperText={
                  !newDeliveryAddress.city && t('forms.mandatoryField')
                }
                sx={{ flex: 1 }}
              />
            </Box>

            <Box
              sx={{
                width: { xs: '100%', md: `calc(50% - ${theme.spacing(1)})` },
              }}
            >
              <TextField
                select
                name='country'
                label={t('forms.country')}
                required
                fullWidth
                value={newDeliveryAddress.country || ''}
                onChange={handleChange}
                error={!newDeliveryAddress.country}
                helperText={
                  !newDeliveryAddress.country && t('forms.mandatoryField')
                }
                sx={{ flex: 1 }}
              >
                {availableCountries.map((country) => (
                  <MenuItem
                    key={`country-${country}`}
                    value={country}
                    title={country}
                  >
                    {country}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
              marginTop: 3,
            }}
          >
            <StyledTextButton
              variant='contained'
              title={t('app.cancelButton')}
              color='secondary'
              onClick={() => handleCloseDialog()}
            >
              {t('app.cancelButton')}
            </StyledTextButton>

            <StyledTextButton
              variant='contained'
              title={t('myAccount.addresses.addAddress')}
              type='submit'
              disabled={
                !newDeliveryAddress.street ||
                !newDeliveryAddress.zipCode ||
                !newDeliveryAddress.city ||
                !newDeliveryAddress.country
                  ? true
                  : false
              }
            >
              {t('myAccount.addresses.addAddress')}
            </StyledTextButton>
          </Box>
        </Box>
      </Dialog>

      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </>
  );
};

export default NewAddressModal;
